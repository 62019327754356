import React, { useState } from "react";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SharedHeader from './SharedHeader';
import SharedFooter from './SharedFooter';
import RegisterModal from "./RegisterModal";

function About() {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const statistics = [
    { number: "100+", label: "Consultoras activas" },
    { number: "3000+", label: "Pedidos procesados" },
    { number: "98%", label: "Satisfacción" }
  ];

  const missions = [
    {
      title: "Innovación",
      description: "Desarrollar soluciones tecnológicas innovadoras que simplifiquen la gestión de negocios de belleza.",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      )
    },
    {
      title: "Crecimiento",
      description: "Impulsar el crecimiento y éxito de nuestras consultoras a través de herramientas efectivas y soporte continuo.",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
        </svg>
      )
    },
    {
      title: "Comunidad",
      description: "Crear una comunidad solidaria donde las consultoras puedan compartir experiencias y crecer juntas.",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    }
  ];



  const handleSwitchToLogin = () => {
    setIsRegisterModalOpen(true);

  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SharedHeader 
        title="Sobre Nosotros" 
        subtitle="Conoce más sobre Mi aliada y nuestra misión" 
      />

      <main className="container mx-auto px-6 py-16">
        {/* Historia Section */}
               <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
          className="p-8 md:p-12 rounded-xl shadow-lg bg-gradient-to-r from-white-50 to-white"
        >
          <div>
            <h2 className="text-4xl font-bold mb-6 text-gray-800 text-center">Nuestra Historia</h2>
            <p className="text-gray-600 mb-6 text-lg leading-relaxed">
              Mi aliada nació de la necesidad de empoderar a las consultoras de belleza con herramientas
              digitales modernas y eficientes. Desde nuestro inicio, nos hemos comprometido a transformar
              la manera en que las consultoras gestionan sus negocios.
            </p>
            <p className="text-gray-600 mb-8 text-lg leading-relaxed">
              Fundada en 2023, nuestra plataforma ha crecido para servir a más de 100 consultoras
              activas, procesando más de 3000 pedidos y manteniendo un índice de satisfacción del 98%.
            </p>
          </div>
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >

          </motion.div>
        </motion.div>

        {/* Statistics Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-8 my-20"
        >
          {statistics.map((stat, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="bg-white p-8 rounded-xl shadow-lg text-center"
            >
              <h3 className="text-4xl font-bold text-orange-600 mb-2">{stat.number}</h3>
              <p className="text-gray-600">{stat.label}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Mission Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-16"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Nuestra misión</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {missions.map((mission, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.03 }}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-orange-600 mb-4">{mission.icon}</div>
                <h3 className="text-xl font-bold mb-4 text-orange-600">{mission.title}</h3>
                <p className="text-gray-600 leading-relaxed">{mission.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="mt-20 text-center"
        >
<Link
                onClick={(e) => {
                  e.preventDefault();
                  setIsRegisterModalOpen(true);
                }}
  className="inline-block px-8 py-3 bg-orange-600 text-white text-base rounded-full font-semibold hover:bg-orange-700 transition-colors duration-300 transform hover:scale-105"
>
  Únete a nuestra comunidad
</Link>
<RegisterModal
                isOpen={isRegisterModalOpen}
                onClose={() => setIsRegisterModalOpen(false)}
                onSwitchToLogin={handleSwitchToLogin}
              />
                        <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            className="fixed bottom-8 right-8 p-4 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition-colors"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </motion.button>
        </motion.div>
      </main>
      <SharedFooter />
    </div>
  );
}

export default About;