import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "./../logo.svg"; // Ajusta la ruta si es necesario
import LoginModal from "../components/LoginModal"; // Ajusta la ruta si es necesario
import RegisterModal from "../components/RegisterModal"; // Ajusta la ruta si es necesario

function SharedHeader({ title, subtitle }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const heroRef = useRef(null);

  const handleSwitchToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  const handleSwitchToLogin = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(true);
  };

  return (
    <header ref={heroRef} className="relative overflow-hidden bg-gray-900">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <Link to="/">
            <img src={Logo} alt="Mi aliada" className="h-40" />
          </Link>

          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <Link
              onClick={(e) => {
                e.preventDefault();
                setIsLoginModalOpen(true);
              }}
              className="w-full sm:w-auto px-6 py-3 text-orange-600 bg-white rounded-full hover:bg-orange-50 transition-all duration-300 text-center"
            >
              Iniciar sesión
            </Link>
            <LoginModal
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
              onSwitchToRegister={handleSwitchToRegister}
            />
            <Link
              onClick={(e) => {
                e.preventDefault();
                setIsRegisterModalOpen(true);
              }}
              className="w-full sm:w-auto px-6 py-3 text-white border-2 border-white rounded-full hover:bg-orange-500 transition-all duration-300 text-center"
            >
              Registrarse
            </Link>
            <RegisterModal
              isOpen={isRegisterModalOpen}
              onClose={() => setIsRegisterModalOpen(false)}
              onSwitchToLogin={handleSwitchToLogin}
            />
          </div>
        </div>
      </nav>
      <div className="container mx-auto px-6 py-16">
        <h1 className="text-4xl font-bold mb-4 text-white">{title}</h1>
        <p className="text-xl text-white">{subtitle}</p>
      </div>
    </header>
  );
}

export default SharedHeader;
