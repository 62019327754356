import React from 'react';
import { motion } from 'framer-motion';
import SharedHeader from './SharedHeader';
import SharedFooter from './SharedFooter';

function Terms() {
  const terms = [
    {
      id: 1,
      title: "Aceptación de los Términos",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      content: "Al acceder y utilizar Mi aliada, aceptas estar sujeto a estos Términos de Servicio. Si no estás de acuerdo con alguna parte de los términos, no podrás acceder al servicio."
    },
    {
      id: 2,
      title: "Cambios en los Términos",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
      ),
      content: "Nos reservamos el derecho de modificar o reemplazar estos Términos en cualquier momento. Te notificaremos cualquier cambio actualizando la fecha de 'última actualización'."
    },
    {
      id: 3,
      title: "Cuentas",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      ),
      content: (
        <div>
          <p className="mb-4">Al crear una cuenta con nosotros, garantizas que:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Eres mayor de 18 años</li>
            <li>Proporcionarás información verdadera y precisa</li>
            <li>Mantendrás tu información actualizada</li>
            <li>Eres responsable de mantener la confidencialidad de tu cuenta</li>
          </ul>
        </div>
      )
    },
    {
      id: 4,
      title: "Planes y Pagos",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
      ),
      content: "Los precios de nuestros servicios están sujetos a cambios. Nos reservamos el derecho de modificar o descontinuar el servicio sin previo aviso."
    },
    {
      id: 5,
      title: "Propiedad Intelectual",
      icon: (
        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      ),
      content: "El servicio y su contenido original, características y funcionalidad son propiedad de Mi aliada y están protegidos por derechos de autor internacionales."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SharedHeader 
        title="Términos de Servicio" 
        subtitle={`Última actualización: ${new Date().toLocaleDateString()}`} 
      />

      <main className="container mx-auto px-6 py-16">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="max-w-4xl mx-auto"
        >
          {terms.map((term, index) => (
            <motion.div
              key={term.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-xl shadow-lg p-6 mb-8 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-orange-100 rounded-lg text-orange-600">
                  {term.icon}
                </div>
                <h2 className="text-2xl font-bold text-gray-800">{term.title}</h2>
              </div>
              <div className="prose prose-lg text-gray-600">
                {term.content}
              </div>
            </motion.div>
          ))}

          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            className="fixed bottom-8 right-8 p-4 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition-colors"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </motion.button>
        </motion.div>
      </main>
      <SharedFooter />
    </div>
  );
}

export default Terms;