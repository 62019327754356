import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fetchOrders, fetchProducts, fetchClients } from '../../services/supabaseService';
import { supabase } from '../../supabaseConfig';
import { OrderHistoryModal } from './OrderHistoryModal';
import { ProductDetailsModal } from './ProductDetailsModal';
import { TrophyIcon, ChartBarIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

const RankingMedal = ({ position }) => {
  const medals = {
    1: { color: 'bg-gradient-to-r from-yellow-300 to-amber-500', icon: '👑', text: 'text-amber-900' },
    2: { color: 'bg-gradient-to-r from-gray-300 to-gray-400', icon: '🥈', text: 'text-gray-700' },
    3: { color: 'bg-gradient-to-r from-orange-300 to-orange-400', icon: '🥉', text: 'text-orange-700' },
  };

  const medal = medals[position];
  if (!medal) return null;

  return (
    <div className={`absolute -top-3 -left-0 w-8 h-8 ${medal.color} rounded-full flex items-center justify-center shadow-lg transform -rotate-12`}>
      <span className="text-2xl" role="img" aria-label={`Position ${position}`}>
        {medal.icon}
      </span>
    </div>
  );
};


export default function RankingTable({ title, type, darkMode }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientOrders, setClientOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);

  const handleItemClick = async (item) => {
    if (type === 'clients') {
      try {
        const client = data.find(client => client.id === item.id);
        if (!client) {
          console.error("Client not found");
          return;
        }

        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const orders = await fetchOrders(session.user.id);
        const filteredOrders = orders.filter(order => order.clienteId === item.id);

        setClientOrders(filteredOrders);
        setSelectedClient(client);
        setIsModalOpen(true);
      } catch (error) {
        console.error("Error fetching client orders:", error);
      }
    } else if (type === 'products') {
      setSelectedProduct(item);
      setIsProductModalOpen(true);
    }
  };





  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          throw new Error("User is not authenticated");
        }
        const userId = session.user.id;

        // Cargar orders primero
        const ordersData = await fetchOrders(userId);
        setOrders(ordersData); // Guardar orders en el estado

        if (type === 'products') {
          const products = await fetchProducts();
          const productSales = {};
          let totalSales = 0;

          ordersData.forEach(order => {
            order.productos.forEach(product => {
              if (!productSales[product.id]) {
                productSales[product.id] = {
                  id: product.id,
                  nombre: product.nombre,
                  imagen: product.imagen,
                  sales: 0,
                  growthPercentage: 0
                };
              }
              productSales[product.id].sales += product.cantidad;
              totalSales += product.cantidad;
            });
          });



          Object.values(productSales).forEach(product => {
            product.growthPercentage = totalSales > 0
              ? Math.round((product.sales / totalSales) * 100)
              : 0;
          });


          let rankedProducts = Object.values(productSales)
            .sort((a, b) => b.sales - a.sales)
            .map(product => ({
              ...product,
              growthPercentage: product.growthPercentage
            }));


          if (rankedProducts.length < 5) {
            const remainingProducts = products.filter(product => !productSales[product.id]);
            while (rankedProducts.length < 5 && remainingProducts.length > 0) {
              const randomIndex = Math.floor(Math.random() * remainingProducts.length);
              const randomProduct = remainingProducts.splice(randomIndex, 1)[0];
              rankedProducts.push({
                id: randomProduct.id,
                nombre: randomProduct.name,
                imagen: randomProduct.imageUrl,
                sales: 0,
                growthPercentage: 0
              });
            }
          }

          setData(rankedProducts.slice(0, 5));
        }
        else if (type === 'clients') {
          const orders = await fetchOrders(userId);
          const clients = await fetchClients(userId);

          // Calculate order count per client
          const clientOrders = {};
          orders.forEach(order => {
            if (!clientOrders[order.clienteId]) {
              const client = clients.find(c => c.id === order.clienteId);
              if (client) {
                clientOrders[order.clienteId] = {
                  id: order.clienteId,
                  nombre: client.nombre,
                  orderCount: 0,
                  totalAmount: 0
                };
              }
            }
            if (clientOrders[order.clienteId]) {
              clientOrders[order.clienteId].orderCount += 1;
              // Calculate total amount if needed
              const orderTotal = order.productos.reduce((sum, prod) =>
                sum + (prod.precio * prod.cantidad), 0);
              clientOrders[order.clienteId].totalAmount += orderTotal;
            }
          });

          // Convert to array and sort by order count
          let rankedClients = Object.values(clientOrders)
            .sort((a, b) => b.orderCount - a.orderCount);

          // Complete top 5 if needed
          if (rankedClients.length < 5) {
            const remainingClients = clients.filter(
              client => !clientOrders[client.id]
            );
            while (rankedClients.length < 5 && remainingClients.length > 0) {
              const randomIndex = Math.floor(Math.random() * remainingClients.length);
              const randomClient = remainingClients.splice(randomIndex, 1)[0];
              rankedClients.push({
                id: randomClient.id,
                nombre: randomClient.nombre,
                orderCount: 0,
                totalAmount: 0
              });
            }
          }

          setData(rankedClients.slice(0, 5));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
      }
    };

    loadData();
  }, [type]);

  if (loading) {
    return (
      <div className={`rounded-lg shadow-lg p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="animate-pulse space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={`loading-skeleton-${i}`} className="flex items-center gap-4 p-4">
              <div className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-700" />
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4" />
                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`rounded-xl shadow-lg overflow-hidden ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
      {/* Header */}
      <div className={`p-4 sm:p-6 border-b ${darkMode ? 'border-gray-700' : 'border-gray-100'}`}>
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-lg ${darkMode ? 'bg-orange-500/10' : 'bg-orange-50'}`}>
            <TrophyIcon className={`h-6 w-6 ${darkMode ? 'text-orange-400' : 'text-orange-500'}`} />
          </div>
          <h2 className={`text-lg sm:text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
            {title === 'Top 5 clientas' ? 'Top 5 clientes frecuentes' : title}
          </h2>
        </div>
      </div>
  
      {/* Lista de Rankings */}
      <div className="divide-y divide-gray-100 dark:divide-gray-700">
        {data.map((item, index) => (
          <motion.div
            key={`${type}-${item.id || index}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => handleItemClick(item)}
            className={`relative p-4 sm:p-6 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all cursor-pointer group`}
          >
            {/* Medalla de posición */}
            <RankingMedal position={index + 1} />
  
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              {/* Imagen o Avatar */}
              <div className="flex-shrink-0">
                {type === 'products' ? (
                  <img
                    src={item.imagen || '/catalogo.jpg'}
                    alt={item.nombre}
                    className="w-20 h-20 sm:w-16 sm:h-16 rounded-lg object-cover shadow-md group-hover:shadow-lg transition-all"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-orange-400 to-orange-600 flex items-center justify-center text-white text-xl font-bold shadow-md group-hover:shadow-lg transition-all">
                    {item.nombre.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
  
              {/* Información */}
              <div className="flex-1 min-w-0 space-y-1">
                <h3 className={`font-medium text-base sm:text-lg truncate ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  {item.nombre}
                </h3>
  
                <div className="flex flex-wrap items-center gap-3">
                  <div className={`flex items-center gap-1.5 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    <ChartBarIcon className="w-4 h-4" />
                    <span>{type === 'products' ? `${item.sales} ventas` : `${item.orderCount} pedidos`}</span>
                  </div>
  
                  {type === 'products' && (
                    <div className={`flex items-center gap-1.5 text-sm ${
                      item.growthPercentage > 0 ? 'text-green-500' : 
                      item.growthPercentage < 0 ? 'text-red-500' : 'text-gray-400'
                    }`}>
                      <ArrowTrendingUpIcon 
                        className={`w-4 h-4 ${item.growthPercentage < 0 ? 'transform rotate-180' : ''}`}
                      />
                      <span>{item.growthPercentage > 0 ? '+' : ''}{item.growthPercentage}%</span>
                    </div>
                  )}
                </div>
              </div>
  
              {/* Botón de acción */}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`px-4 py-2 rounded-full text-sm font-medium w-full sm:w-auto text-center
                  ${darkMode
                    ? 'bg-orange-500/10 text-orange-400 hover:bg-orange-500/20'
                    : 'bg-orange-50 text-orange-600 hover:bg-orange-100'
                  } transition-all`}
              >
                {type === 'products' ? 'Detalles' : 'Historial'}
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>
  
      {/* Modales */}
      {type === 'clients' && (
        <OrderHistoryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          client={selectedClient}
          orders={clientOrders}
          darkMode={darkMode}
        />
      )}
      {type === 'products' && (
        <ProductDetailsModal
          isOpen={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          product={selectedProduct}
          orders={orders}
          darkMode={darkMode}
        />
      )}
    </div>
  );
}