import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { calculateUpgradeDetails } from '../services/subscriptionService';
import { createPreference } from '../services/mercadoPagoService';
import { Wallet } from "@mercadopago/sdk-react";
import { Toaster, toast } from 'react-hot-toast';

export default function UpgradeModal({ isOpen, onClose, userId }) {
  const [upgradeDetails, setUpgradeDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [preferenceId, setPreferenceId] = useState(null);
  const [selectedCycle, setSelectedCycle] = useState('monthly');

  useEffect(() => {
    const loadUpgradeDetails = async () => {
      try {
        const details = await calculateUpgradeDetails(userId, selectedCycle);
        setUpgradeDetails(details);
      } catch (error) {
        toast.error('Error al calcular los detalles del upgrade');
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      loadUpgradeDetails();
      setPreferenceId(null);
    }
  }, [isOpen, userId, selectedCycle]);

  const handleCycleChange = async (cycle) => {
    setSelectedCycle(cycle);
    setIsLoading(true);
    try {
      const details = await calculateUpgradeDetails(userId, cycle);
      setUpgradeDetails(details);
    } catch (error) {
      toast.error(error.message || 'Error al calcular los detalles del upgrade');
      setUpgradeDetails(null); 
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgrade = async () => {
    if (upgradeDetails.totalDue < 0) {
      toast.error('El total a pagar no puede ser negativo');
      return;
    }

    try {
      setIsProcessing(true);
      
      const planDetails = {
        name: "Standard",
        price: upgradeDetails.totalDue,
        billingCycle: selectedCycle,
        id: "standard"
      };
      
      const preference = await createPreference(planDetails);
      setPreferenceId(preference.id);
      
    } catch (error) {
      toast.error('Error al procesar el upgrade');
      console.error('Error:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="bg-white rounded-xl p-6 max-w-md w-full m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Actualizar a plan standard
        </h2>

        {isLoading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
          </div>
        ) : (
          <>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Ciclo de facturación
              </label>
              <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
                {[
                  { id: 'monthly', label: 'Mensual' },
                  { id: 'sixMonth', label: 'Semestral' },
                  { id: 'annual', label: 'Anual' }
                ].map((cycle) => (
                  <button
                    key={cycle.id}
                    onClick={() => handleCycleChange(cycle.id)}
                    className={`flex-1 px-4 py-2 text-sm font-medium rounded-md transition-colors
                      ${selectedCycle === cycle.id
                        ? 'bg-white text-orange-600 shadow'
                        : 'text-gray-500 hover:text-gray-700'
                      }`}
                  >
                    {cycle.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-4 mb-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500 mb-2">
                  Detalles del cambio de plan
                </h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Reembolso del plan actual:</span>
                    <span className="font-medium text-green-600">
                      ${upgradeDetails?.refundAmount?.toLocaleString('es-CO')}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Costo del nuevo plan:</span>
                    <span className="font-medium text-gray-900">
                      ${upgradeDetails?.upgradeCost?.toLocaleString('es-CO')}
                    </span>
                  </div>
                  <div className="border-t pt-2 mt-2">
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-900">Total a pagar:</span>
                      <span className="font-bold text-orange-600">
                        ${upgradeDetails?.totalDue?.toLocaleString('es-CO')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!preferenceId ? (
              <div className="flex space-x-4">
                <button
                  onClick={onClose}
                  className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleUpgrade}
                  disabled={isProcessing}
                  className="flex-1 px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-colors disabled:opacity-50"
                >
                  {isProcessing ? 'Procesando...' : 'Confirmar'}
                </button>
              </div>
            ) : (
              <div className="mt-6">
                <Wallet
                  initialization={{ preferenceId }}
                  onReady={() => console.log("Wallet ready")}
                  onSubmit={() => console.log("Payment submitted")}
                  onError={(error) => {
                    console.error("Wallet error:", error);
                    toast.error("Error al procesar el pago");
                  }}
                />
              </div>
            )}
          </>
        )}
      </motion.div>
      <Toaster position="top-right" />
    </motion.div>
    
  );
}