import React from 'react';
import { motion } from 'framer-motion';

export default function StatCard({ title, value, icon: Icon, color, darkMode }) {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className={`${color} overflow-hidden rounded-xl shadow-lg transition-all duration-300`}
    >
    <div className={`
      p-6 rounded-lg shadow-sm transition-colors duration-300
      ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}
    `}>
      <div className="flex items-center justify-between">
        <div>
          <p className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {title}
          </p>
          <p className="mt-2 text-3xl font-semibold">{value}</p>
        </div>
        <div className={`
          p-3 rounded-full
          ${darkMode ? 'text-orange-400 bg-orange-900/20' : 'text-orange-600 bg-orange-100'}
        `}>
          {React.isValidElement(Icon) ? Icon : <Icon className="h-6 w-6" />}
        </div>
      </div>
    </div>
    </motion.div>
  );
}