// src/components/orders/OrderForm.js

import React, { useState, useEffect } from "react";
import { supabase } from "../../services/supabaseClient";
import {
  addOrder,
  fetchClients,
  fetchProducts,
  fetchOrders,
} from "../../services/supabaseService";
import { motion } from "framer-motion";
import {
  UserIcon,
  MagnifyingGlassIcon as SearchIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { useTheme } from "../../context/ThemeContext";
import OrdersTable from "./OrdersTable";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function OrderForm() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [clienteNombre, setClienteNombre] = useState("");
  const [productoId, setProductoId] = useState("");
  const [precio, setPrecio] = useState("");
  const [profit, setProfit] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clienteSuggestions, setClienteSuggestions] = useState([]);
  const [productoSuggestions, setProductoSuggestions] = useState([]);
  const [showClienteSuggestions, setShowClienteSuggestions] = useState(false);
  const [showProductoSuggestions, setShowProductoSuggestions] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [precioFormatted, setPrecioFormatted] = useState("");
  const [profitFormatted, setProfitFormatted] = useState("");
  const { darkMode } = useTheme();
  const [orders, setOrders] = useState([]);

  // Definir fetchOrdersData
  const fetchOrdersData = async () => {
    try {
      const ordersData = await fetchOrders(user.id);
      setOrders(ordersData || []);
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Error al obtener los pedidos");
    }
  };

  // Definir handleOrderClick
  const handleOrderClick = (order) => {
    navigate(`/pedidos/${order.id}`);
  };

  // Obtener y establecer el usuario actual
  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  // Cargar datos de clientes y productos
  useEffect(() => {
    const loadData = async () => {
      if (user) {
        try {
          const clientsData = await fetchClients(user.id);
          const productsData = await fetchProducts();
          setClientes(clientsData || []);
          setProductos(productsData || []);
          setClienteSuggestions(clientsData || []);
          setProductoSuggestions(productsData || []);
        } catch (error) {
          console.error("Error cargando datos:", error);
          toast.error("Error al cargar los datos");
        }
      }
    };
    loadData();
  }, [user]);

  // Obtener y establecer los pedidos
  useEffect(() => {
    if (user) {
      fetchOrdersData();
    }
  }, [user]);

  // Manejar cambios en el nombre del cliente
  const handleClienteNombreChange = (e) => {
    const value = e.target.value;
    setClienteNombre(value);

    if (value.trim() === "") {
      setClienteSuggestions([]);
    } else {
      const filteredSuggestions = clientes
        .filter((cliente) =>
          cliente.nombre.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 5);
      setClienteSuggestions(filteredSuggestions);
    }
    setShowClienteSuggestions(true);
  };

  // Manejar cambios en el ID del producto
  const handleProductoIdChange = (e) => {
    const value = e.target.value;
    setProductoId(value);

    if (value.trim() === "") {
      setProductoSuggestions([]);
    } else {
      const filteredSuggestions = productos
        .filter(
          (producto) =>
            producto.id.toLowerCase().includes(value.toLowerCase()) ||
            producto.name.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 5);
      setProductoSuggestions(filteredSuggestions);
    }
    setShowProductoSuggestions(true);
  };

  // Formatear números a COP
  const formatToCOP = (number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(number)
      .replace("COP", "")
      .trim();
  };

  // Manejar cambios en el precio
  const handlePrecioChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    if (value) {
      const numberValue = parseInt(value, 10);
      setPrecio(numberValue.toString());
      setPrecioFormatted(formatToCOP(numberValue));
    } else {
      setPrecio("");
      setPrecioFormatted("");
    }
  };

  // Manejar cambios en la ganancia
  const handleProfitChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    if (value) {
      const numberValue = parseInt(value, 10);
      setProfit(numberValue.toString());
      setProfitFormatted(formatToCOP(numberValue));
    } else {
      setProfit("");
      setProfitFormatted("");
    }
  };

  // Manejar la tecla Enter en el campo de producto
  const handleProductoKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (productoId && precio && profit) {
        const producto = productos.find(
          (p) => p.id.toLowerCase() === productoId.toLowerCase()
        );
        if (producto) {
          handleAddProduct();
        } else {
          toast.error("El producto no está disponible");
        }
      } else {
        toast.error(
          "Por favor, ingrese el código del producto, precio y ganancia"
        );
      }
    }
  };

  // Agregar producto a la lista seleccionada
  const handleAddProduct = () => {
    const producto = productos.find(
      (p) => p.id.toLowerCase() === productoId.toLowerCase()
    );
    if (producto) {
      setProductosSeleccionados([
        ...productosSeleccionados,
        {
          ...producto,
          precio: parseFloat(precio),
          profit: parseFloat(profit),
          cantidad: parseInt(cantidad),
        },
      ]);
      setProductoId("");
      setPrecio("");
      setPrecioFormatted("");
      setProfit("");
      setProfitFormatted("");
      setCantidad(1);
      setSelectedProduct(null);
      toast.success("Producto agregado");
    } else {
      toast.error("El producto no está disponible");
    }
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!clienteNombre || productosSeleccionados.length === 0) {
      toast.error("Selecciona un cliente y al menos un producto");
      setIsSubmitting(false);
      return;
    }

    const cliente = clientes.find((c) => c.nombre === clienteNombre);
    if (!cliente) {
      toast.error("Cliente no encontrado");
      setIsSubmitting(false);
      return;
    }

    const newOrder = {
      clienteId: cliente.id,
      clienteNombre: cliente.nombre,
      productos: productosSeleccionados.map((p) => ({
        id: p.id,
        nombre: p.name,
        precio: p.precio,
        profit: p.profit,
        cantidad: p.cantidad,
        imagen: p.imageUrl,
      })),
      entregado: false,
    };

    try {
      await addOrder(user.id, newOrder);
      toast.success("Pedido registrado con éxito");
      setClienteNombre("");
      setProductosSeleccionados([]);
      fetchOrdersData(); // Actualizar la lista de pedidos
    } catch (error) {
      console.error("Error registrando el pedido:", error);
      toast.error("Error al registrar el pedido");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <Toaster position="top-right" />

      {/* Sección de Cliente */}
      <div className="space-y-6">
        <h2
          className={`text-2xl font-semibold ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          Información del cliente
        </h2>
        <div className="relative">
          <label
            htmlFor="clienteNombre"
            className={`block text-sm font-medium mb-1 ${
              darkMode ? "text-gray-100" : "text-gray-900"
            }`}
          >
            Nombre del cliente
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              id="clienteNombre"
              value={clienteNombre}
              onChange={handleClienteNombreChange}
              onFocus={() => setShowClienteSuggestions(true)}
              onBlur={() =>
                setTimeout(() => setShowClienteSuggestions(false), 200)
              }
              className={`block w-full pl-10 pr-3 py-2 rounded-md
                    ${
                      darkMode
                        ? "bg-gray-700 border-gray-600 text-gray-100"
                        : "bg-white border-gray-300 text-gray-900"
                    }
                    placeholder-gray-400
                    focus:ring-2 focus:ring-orange-500
                  `}
              placeholder="Buscar cliente..."
              required
            />
          </div>
          {showClienteSuggestions && clienteSuggestions.length > 0 && (
            <ul
              className={`absolute z-10 mt-1 w-full rounded-md shadow-lg py-1
                  ${
                    darkMode
                      ? "bg-gray-700 ring-gray-700"
                      : "bg-white ring-black ring-opacity-5"
                  }`}
            >
              {clienteSuggestions.map((cliente) => (
                <li
                  key={cliente.id}
                  onClick={() => {
                    setClienteNombre(cliente.nombre);
                    setClienteSuggestions([]);
                    setShowClienteSuggestions(false);
                  }}
                  className={`py-2 pl-3 pr-9 cursor-pointer
                    ${
                      darkMode
                        ? "hover:bg-gray-600 text-gray-200"
                        : "hover:bg-orange-600 hover:text-white text-gray-900"
                    }`}
                >
                  {cliente.nombre}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Sección de Producto */}
      <div className="space-y-6">
        <h2
          className={`text-2xl font-semibold ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          Agregar producto
        </h2>
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/* Campo de Búsqueda de Producto */}
          <div className="sm:col-span-2 relative">
            <label
              htmlFor="productoId"
              className={`block text-sm font-medium mb-1 ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Buscar Producto
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className={`h-5 w-5 ${
                    darkMode ? "text-gray-500" : "text-gray-400"
                  }`}
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                id="productoId"
                value={productoId}
                onChange={handleProductoIdChange}
                onKeyDown={handleProductoKeyDown}
                className={`block w-full pl-10 pr-3 py-2 rounded-md
                ${
                  darkMode
                    ? "bg-gray-700 border-gray-600 text-gray-100"
                    : "bg-white border-gray-300 text-gray-900"
                }
                placeholder-gray-400
                focus:ring-2 focus:ring-orange-500
                relative z-10
              `}
                placeholder="Buscar producto..."
              />
            </div>
            {showProductoSuggestions && productoSuggestions.length > 0 && (
              <ul
                className={`absolute z-50 mt-1 w-full shadow-lg max-h-60 rounded-md py-1 text-sm
          ${
            darkMode
              ? "bg-gray-700 ring-1 ring-gray-600"
              : "bg-white ring-1 ring-black ring-opacity-5"
          }
          overflow-auto focus:outline-none
          transition-colors duration-200
        `}
                style={{ top: "100%" }} // Asegura que las sugerencias aparezcan justo debajo del input
              >
                {productoSuggestions.map((producto) => (
                  <li
                    key={producto.id}
                    onClick={() => {
                      setProductoId(producto.id);
                      setSelectedProduct(producto);
                      setProductoSuggestions([]);
                      setShowProductoSuggestions(false);
                    }}
                    className={`py-2 pl-3 pr-9 cursor-pointer
            transition-colors duration-200
            ${
              darkMode
                ? "text-gray-200 hover:bg-gray-600"
                : "text-gray-900 hover:bg-orange-600 hover:text-white"
            }`}
                  >
                    {producto.name} - {producto.id}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Campo de Precio */}
          <div className="sm:col-span-1">
            <label
              htmlFor="precio"
              className={`block text-sm font-medium mb-1 ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Precio
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                id="precio"
                value={precioFormatted}
                onChange={handlePrecioChange}
                className={`block w-full px-3 py-2 rounded-md
                          ${
                            darkMode
                              ? "bg-gray-700 border-gray-600 text-gray-100"
                              : "bg-white border-gray-300 text-gray-900"
                          }
                          placeholder-gray-400
                          focus:ring-2 focus:ring-orange-500
                        `}
                placeholder="0"
              />
            </div>
          </div>

          {/* Campo de Ganancia */}
          <div className="sm:col-span-1">
            <label
              htmlFor="profit"
              className={`block text-sm font-medium mb-1 ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Ganancia
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                id="profit"
                value={profitFormatted}
                onChange={handleProfitChange}
                className={`block w-full px-3 py-2 rounded-md
                          ${
                            darkMode
                              ? "bg-gray-700 border-gray-600 text-gray-100"
                              : "bg-white border-gray-300 text-gray-900"
                          }
                          placeholder-gray-400
                          focus:ring-2 focus:ring-orange-500
                        `}
                placeholder="0"
              />
            </div>
          </div>

          {/* Campo de Cantidad */}
          <div className="sm:col-span-1">
            <label
              htmlFor="cantidad"
              className={`block text-sm font-medium mb-1 ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Cantidad
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="button"
                  onClick={() => setCantidad((prev) => Math.max(1, prev - 1))}
                  className={`p-1 rounded-md transition-colors
                            ${
                              darkMode
                                ? "text-gray-400 hover:text-gray-300 hover:bg-gray-600"
                                : "text-gray-500 hover:text-gray-600 hover:bg-gray-100"
                            }
                            focus:outline-none focus:ring-2 focus:ring-orange-500`}
                >
                  <MinusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <input
                type="number"
                id="cantidad"
                value={cantidad}
                onChange={(e) =>
                  setCantidad(Math.max(1, parseInt(e.target.value) || 1))
                }
                min="1"
                required
                className={`block w-full px-12 py-2 rounded-md text-center
                          ${
                            darkMode
                              ? "bg-gray-700 border-gray-600 text-gray-100"
                              : "bg-white border-gray-300 text-gray-900"
                          }
                          placeholder-gray-400
                          focus:ring-2 focus:ring-orange-500
                          [appearance:textfield]
                          [&::-webkit-outer-spin-button]:appearance-none
                          [&::-webkit-inner-spin-button]:appearance-none
                        `}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                  type="button"
                  onClick={() => setCantidad((prev) => prev + 1)}
                  className={`p-1 rounded-md transition-colors
                            ${
                              darkMode
                                ? "text-gray-400 hover:text-gray-300 hover:bg-gray-600"
                                : "text-gray-500 hover:text-gray-600 hover:bg-gray-100"
                            }
                            focus:outline-none focus:ring-2 focus:ring-orange-500`}
                >
                  <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          {/* Botón de Agregar Producto */}
          <div className="sm:col-span-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              &nbsp;
            </label>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button"
              onClick={handleAddProduct}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              <PlusCircleIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Agregar
            </motion.button>
          </div>
        </div>

        {/* Producto Seleccionado */}
        {selectedProduct && (
          <div
            className={`mt-4 flex items-center space-x-4 p-4 rounded-md
                ${darkMode ? "bg-gray-700" : "bg-gray-50"}`}
          >
            <img
              src={selectedProduct.imageUrl}
              alt={selectedProduct.name}
              className="h-16 w-16 object-cover rounded-md"
            />
            <div>
              <p
                className={`font-medium text-sm ${
                  darkMode ? "text-gray-200" : "text-gray-900"
                }`}
              >
                {selectedProduct.name}
              </p>
              <p
                className={`text-xs ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                ID: {selectedProduct.id}
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Lista de Productos Seleccionados */}
      {productosSeleccionados.length > 0 && (
        <div className="mt-8">
          <h3
            className={`text-lg font-medium mb-4 ${
              darkMode ? "text-gray-100" : "text-gray-900"
            }`}
          >
            Productos seleccionados
          </h3>
          <ul
            className={`divide-y ${
              darkMode ? "divide-gray-700" : "divide-gray-200"
            }`}
          >
            {productosSeleccionados.map((producto, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={`py-4 flex items-center ${
                  darkMode ? "text-gray-200" : "text-gray-900"
                }`}
              >
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={producto.imageUrl}
                  alt={producto.name}
                />
                <div className="ml-3 flex-grow">
                  <div
                    className={`text-sm font-medium ${
                      darkMode ? "text-gray-200" : "text-gray-900"
                    }`}
                  >
                    {producto.name}
                  </div>
                  <div
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    {producto.cantidad} x {formatToCOP(producto.precio)}{" "}
                    (Ganancia: {formatToCOP(producto.profit)})
                  </div>
                </div>
                <div
                  className={`text-sm font-medium ${
                    darkMode ? "text-gray-200" : "text-gray-900"
                  }`}
                >
                  {formatToCOP(producto.precio * producto.cantidad)}
                </div>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  type="button"
                  onClick={() => {
                    const newProductos = [...productosSeleccionados];
                    newProductos.splice(index, 1);
                    setProductosSeleccionados(newProductos);
                  }}
                  className={`ml-4 text-sm font-medium ${
                    darkMode
                      ? "text-red-400 hover:text-red-300"
                      : "text-red-600 hover:text-red-500"
                  } transition-colors duration-200`}
                >
                  Eliminar
                </motion.button>
              </motion.li>
            ))}
          </ul>
          <div className="mt-4 flex justify-between">
            <p
              className={`text-sm font-medium ${
                darkMode ? "text-gray-200" : "text-gray-900"
              }`}
            >
              Ganancia Total:{" "}
              {formatToCOP(
                productosSeleccionados.reduce(
                  (total, producto) =>
                    total + producto.profit * producto.cantidad,
                  0
                )
              )}
            </p>
            <p
              className={`text-sm font-medium ${
                darkMode ? "text-gray-200" : "text-gray-900"
              }`}
            >
              Total:{" "}
              {formatToCOP(
                productosSeleccionados.reduce(
                  (total, producto) =>
                    total + producto.precio * producto.cantidad,
                  0
                )
              )}
            </p>
          </div>
        </div>
      )}

      {/* Botón de Envío */}
      <div className="mt-8">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          disabled={isSubmitting}
          className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          {isSubmitting ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <ShoppingCartIcon
              className="-ml-1 mr-2 h-5 w-5"
              aria-hidden="true"
            />
          )}
          {isSubmitting ? "Registrando..." : "Registrar pedido"}
        </motion.button>
      </div>
    </form>
  );
}

export default OrderForm;
