import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  testimonials,
  faqs,
  features as featuresData,
} from "../constants/landingData";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import Logo from "./../logo.svg";

function Landing() {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const NEQUI_NUMBER = "3112900929";

  const handleSwitchToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  const handleSwitchToLogin = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(true);
  };

  const [heroRef, heroInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [featuresRef, featuresInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [pricingRef, pricingInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const y = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  const features = featuresData;

  const plans = {
    basic: {
      name: "Basic",
      monthly: 5000,
      sixMonth: 27000,
      annual: 48000,
      features: [
        "Límite de 10 registros de clientes",
        "Acceso básico al dashboard",
        "Soporte por correo electrónico",
        "Actualizaciones básicas",
      ],
      limitations: [
        "Sin exportación a Excel",
        "Sin edición de clientes",
        "Sin eliminación de clientes",
        "Módulos del dashboard limitados",
      ],
    },
    standard: {
      name: "Standard",
      monthly: 10000,
      sixMonth: 54000,
      annual: 96000,
      features: [
        "Registros ilimitados de clientes",
        "Exportación a Excel",
        "Gestión completa de clientes",
        "Acceso completo al dashboard",
        "Soporte prioritario 24/7",
        "Actualizaciones premium",
        "Análisis avanzado de datos",
        "Reportes personalizados",
      ],
      recommended: true,
    },
  };

  const formatCOP = (amount) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace("COP", "$");
  };

  const getPriceWithCycle = (plan) => {
    switch (billingCycle) {
      case "sixMonth":
        return plan.sixMonth;
      case "annual":
        return plan.annual;
      default:
        return plan.monthly;
    }
  };

  const getSavingsPercentage = () => {
    switch (billingCycle) {
      case "sixMonth":
        return "10%";
      case "annual":
        return "20%";
      default:
        return "0%";
    }
  };
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <header ref={heroRef} className="relative overflow-hidden bg-gray-900">
        <nav className="container mx-auto px-6 py-4">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <img src={Logo} alt="Mi aliada" className="h-56" />

            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setIsLoginModalOpen(true);
                }}
                className="w-full sm:w-auto px-6 py-3 text-orange-600 bg-white rounded-full hover:bg-orange-50 transition-all duration-300 text-center"
              >
                Iniciar sesión
              </Link>
              <LoginModal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
                onSwitchToRegister={handleSwitchToRegister}
              />
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setIsRegisterModalOpen(true);
                }}
                className="w-full sm:w-auto px-6 py-3 text-white border-2 border-white rounded-full hover:bg-orange-500 transition-all duration-300 text-center"
              >
                Registrarse
              </Link>
              <RegisterModal
                isOpen={isRegisterModalOpen}
                onClose={() => setIsRegisterModalOpen(false)}
                onSwitchToLogin={handleSwitchToLogin}
              />
            </div>
          </div>
        </nav>

        {/* Contenido principal */}
        <div className="relative container mx-auto px-6 py-10 md:py-20">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="text-white">
            <h1 className="text-4xl md:text-7xl font-bold mb-8">
                Potencia tu negocio con Mi aliada
              </h1>
              <p className="text-xl text-orange-50 mb-8">
                La solución integral para consultoras de belleza que buscan
                llevar su negocio al siguiente nivel
              </p>

              {/* Badges de confianza */}
              <div className="flex gap-4 mb-8">
                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <h4 className="font-bold text-2xl">100+</h4>
                  <p className="text-sm">Usuarios activos</p>
                </div>
                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <h4 className="font-bold text-2xl">98%</h4>
                  <p className="text-sm">Satisfacción</p>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setIsRegisterModalOpen(true);
                  }}
                  className="w-full sm:w-auto px-8 py-4 bg-white text-orange-600 rounded-full font-semibold text-lg hover:bg-orange-50 transition-all duration-300 text-center"
                >
                  Registrarse
                </Link>
              </div>
            </div>

{/* Diseño con Líneas */}
<div className="relative flex justify-center items-center">
  {/* Fondo de líneas diagonales */}
  <div className="absolute w-72 h-72 bg-transparent border-t-4 border-r-4 border-orange-300 transform rotate-45 hidden sm:block"></div>
  <div className="absolute w-56 h-56 bg-transparent border-b-4 border-l-4 border-orange-500 transform -rotate-45 hidden sm:block"></div>
</div>
          </div>
        </div>
      </header>

      {/* Nueva sección de Estadísticas */}
      <section className="py-20 bg-orange-50">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { number: "100+", label: "Usuarios activos" },
              { number: "3000+", label: "Pedidos procesados" },
              { number: "98%", label: "Satisfacción" },
              { number: "24/7", label: "Soporte" },
            ].map((stat) => (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                className="text-center"
              >
                <h3 className="text-4xl font-bold text-orange-600 mb-2">
                  {stat.number}
                </h3>
                <p className="text-gray-600">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <motion.section
        ref={featuresRef}
        initial={{ opacity: 0 }}
        animate={featuresInView ? { opacity: 1 } : {}}
        className="py-20 bg-white"
      >
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-16">
            Características que transforman tu negocio
          </h2>
          <div className="space-y-24">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={featuresInView ? { opacity: 1, y: 0 } : {}}
                transition={{ delay: index * 0.3 }}
                className={`flex flex-col ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center gap-12`}
              >
                <div className="w-full md:w-1/2">
                  <div className="relative">
                    <motion.div
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={featuresInView ? { scale: 1, opacity: 1 } : {}}
                      transition={{ delay: index * 0.3 + 0.2 }}
                      className="absolute -top-8 -left-8 w-16 h-16 bg-orange-500 rounded-full flex items-center justify-center text-white text-2xl font-bold shadow-lg"
                    >
                      {feature.step}
                    </motion.div>
                    <div className="bg-gradient-to-r from-orange-100 to-orange-200 rounded-lg p-6 shadow-md">
                      <h3 className="text-3xl font-bold text-gray-900 mb-4">
                        {feature.title}
                      </h3>
                      <p className="text-xl text-gray-600 mb-6">
                        {feature.description}
                      </p>
                      <ul className="space-y-3">
                        {feature.details.map((detail, idx) => (
                          <motion.li
                            key={idx}
                            initial={{ opacity: 0, x: -20 }}
                            animate={featuresInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ delay: index * 0.3 + idx * 0.1 }}
                            className="flex items-center text-gray-700"
                          >
                            <svg
                              className="w-5 h-5 text-orange-500 mr-3"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                            {detail}
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Nueva sección de Testimonios */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-16">
            Lo que dicen nuestros clientes
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-gray-50 p-8 rounded-2xl"
              >
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-gradient-to-r from-orange-500 to-orange-300 rounded-full flex items-center justify-center text-white text-xl font-bold">
                    {testimonial.name.charAt(0)}
                  </div>
                  <div className="ml-4">
                    <h4 className="font-bold">{testimonial.name}</h4>
                    <p className="text-gray-600 text-sm">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-700">{testimonial.content}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <motion.section
        ref={pricingRef}
        initial={{ opacity: 0, y: 50 }}
        animate={pricingInView ? { opacity: 1, y: 0 } : {}}
        className="py-20 bg-gray-50"
      >
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
            Planes diseñados para tu crecimiento
          </h2>

          <div className="flex justify-center mb-12">
            <div className="bg-white p-1.5 sm:p-2 rounded-full shadow-md w-full max-w-md sm:max-w-xl">
              <div className="grid grid-cols-3 gap-2 sm:gap-3">
                {["monthly", "sixMonth", "annual"].map((cycle) => (
                  <button
                    key={cycle}
                    onClick={() => setBillingCycle(cycle)}
                    className={`
              px-3 sm:px-6 
              py-2 sm:py-3 
              rounded-full 
              text-sm sm:text-base 
              font-medium 
              transition-all 
              duration-300
              hover:shadow-md
              ${
                billingCycle === cycle
                  ? "bg-orange-600 text-white hover:bg-orange-700"
                  : "text-gray-600 hover:text-orange-600 hover:bg-orange-50"
              }
            `}
                  >
                    {cycle === "monthly"
                      ? "Mensual"
                      : cycle === "sixMonth"
                      ? "6 Meses"
                      : "Anual"}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {Object.entries(plans).map(([key, plan]) => (
              <motion.div
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={pricingInView ? { opacity: 1, y: 0 } : {}}
                whileHover={{ scale: 1.02 }}
                className={`bg-white rounded-2xl shadow-xl overflow-hidden ${
                  plan.recommended ? "border-2 border-orange-500" : ""
                }`}
              >
                {plan.recommended && (
                  <div className="bg-orange-500 text-white text-center py-2 text-sm font-medium">
                    Recomendado
                  </div>
                )}
                <div className="p-8">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">
                    {plan.name}
                  </h3>
                  <div className="flex items-baseline mb-8">
                    <span className="text-4xl font-bold text-orange-600">
                      {formatCOP(getPriceWithCycle(plan))}
                    </span>
                    <span className="text-gray-500 ml-2">
                      {billingCycle === "monthly"
                        ? "/mes"
                        : billingCycle === "sixMonth"
                        ? "/6 meses"
                        : "/año"}
                    </span>
                  </div>
                  {billingCycle !== "monthly" && (
                    <div className="mb-6 text-sm text-green-600 font-medium">
                      Ahorra {getSavingsPercentage()} con este plan
                    </div>
                  )}
                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-center text-gray-600"
                      >
                        <svg
                          className="w-5 h-5 text-green-500 mr-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        {feature}
                      </li>
                    ))}
                    {plan.limitations?.map((limitation, index) => (
                      <li
                        key={index}
                        className="flex items-center text-gray-400"
                      >
                        <svg
                          className="w-5 h-5 text-red-500 mr-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        {limitation}
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/register"
                    className={`block w-full text-center py-4 px-6 rounded-lg font-medium transition-colors duration-300 ${
                      plan.recommended
                        ? "bg-orange-600 text-white hover:bg-orange-700"
                        : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                    }`}
                  >
                    Comenzar ahora
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Nueva sección FAQ */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-16">
            Preguntas frecuentes
          </h2>
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq) => (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-6 text-left">
                      <span className="font-medium">{faq.question}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-6 text-gray-600">
                      {faq.answer}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-6">
          {/* Grid Layout */}
          <div className="grid md:grid-cols-4 gap-8 items-start">
            {/* Logo Section */}
            <div className="flex flex-col items-center">
              <img src={Logo} alt="Mi aliada" className="h-56 mb-4" />
              <p className="text-gray-400 text-center max-w-xs">
                Transformando la gestión de consultoras de belleza
              </p>
            </div>

            {/* Contact Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Contacto</h4>
              <p className="text-gray-400">
                WhatsApp: {NEQUI_NUMBER}
                <br />
                Email: soporte@mialiada.com
              </p>
            </div>

            {/* Links Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Enlaces</h4>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <Link
                    to="/about"
                    className="hover:text-orange-500 transition-colors duration-200"
                  >
                    Sobre Nosotros
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="hover:text-orange-500 transition-colors duration-200"
                  >
                    Política de Privacidad
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="hover:text-orange-500 transition-colors duration-200"
                  >
                    Términos de Servicio
                  </Link>
                </li>
              </ul>
            </div>

            {/* Social Media Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Síguenos</h4>
              <div className="flex space-x-4">
                {/* Redes sociales con estilo circular */}
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
                  aria-label="Facebook"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22 12c0-5.522-4.478-10-10-10S2 6.478 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987H7.898v-2.89h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562v1.875h2.773l-.443 2.89h-2.33v6.987C18.343 21.128 22 16.991 22 12z" />
                  </svg>
                </a>

                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
                  aria-label="Twitter"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.46 6c-.77.35-1.6.58-2.46.69a4.3 4.3 0 001.88-2.39 8.59 8.59 0 01-2.72 1.04 4.28 4.28 0 00-7.3 3.9A12.15 12.15 0 013 4.89a4.28 4.28 0 001.33 5.72 4.26 4.26 0 01-1.94-.54v.05a4.28 4.28 0 003.44 4.19 4.3 4.3 0 01-1.93.07 4.28 4.28 0 004 2.98A8.6 8.6 0 012 19.54a12.14 12.14 0 006.56 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.19 0-.39-.01-.58A8.72 8.72 0 0024 5.59a8.47 8.47 0 01-2.54.7z" />
                  </svg>
                </a>

                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
                  aria-label="Instagram"
                >
                  <svg
                    className="fill-current w-20 h-20"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.445 5h-8.891A6.559 6.559 0 0 0 5 11.554v8.891A6.559 6.559 0 0 0 11.554 27h8.891a6.56 6.56 0 0 0 6.554-6.555v-8.891A6.557 6.557 0 0 0 20.445 5zm4.342 15.445a4.343 4.343 0 0 1-4.342 4.342h-8.891a4.341 4.341 0 0 1-4.341-4.342v-8.891a4.34 4.34 0 0 1 4.341-4.341h8.891a4.342 4.342 0 0 1 4.341 4.341l.001 8.891z" />
                    <path d="M16 10.312c-3.138 0-5.688 2.551-5.688 5.688s2.551 5.688 5.688 5.688 5.688-2.551 5.688-5.688-2.55-5.688-5.688-5.688zm0 9.163a3.475 3.475 0 1 1-.001-6.95 3.475 3.475 0 0 1 .001 6.95zM21.7 8.991a1.363 1.363 0 1 1-1.364 1.364c0-.752.51-1.364 1.364-1.364z" />
                  </svg>
                </a>
                {/* LinkedIn */}
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
                  aria-label="LinkedIn"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4.98 3.5C4.98 4.88 3.88 6 2.5 6S0.02 4.88 0.02 3.5 1.12 1 2.5 1 4.98 2.12 4.98 3.5zM0 24V7h5.01v17H0zM7.5 7h4.8v2.418h.07c.67-1.27 2.32-2.6 4.78-2.6 5.13 0 6.08 3.38 6.08 7.77V24h-5.01v-7.883c0-2.23-.04-5.09-3.1-5.09-3.1 0-3.58 2.42-3.58 4.91V24H7.5z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* Bottom Section */}
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400 flex flex-col items-center">
            <p>&copy; 2024 Mi aliada. Todos los derechos reservados.</p>
            <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            className="fixed bottom-8 right-8 p-4 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition-colors"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </motion.button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
