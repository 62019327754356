import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import {
  fetchClients,
  fetchOrders,
  fetchProducts,
} from "../services/supabaseService";
import UpgradeModal from "./UpgradeModal";
import { usePlanFeatures } from "../hooks/usePlanFeatures";
import { Bar, Doughnut } from "react-chartjs-2";
import { format, startOfMonth, endOfMonth } from "date-fns";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import {
  UserIcon,
  ShoppingCartIcon,
  ClipboardDocumentListIcon,
  CubeIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useTheme } from '../context/ThemeContext';
import { toast } from 'react-hot-toast';

// Import modular components
import Layout from "./dashboard/Layout";
import StatCard from "./dashboard/StatCard";
import RankingTable from "./dashboard/RankingTable";
import ChartCard from "./dashboard/ChartCard";
import HistoricalDataModal from "./dashboard/HistoricalDataModal";


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [monthlyAbonos, setMonthlyAbonos] = useState([]);
  const [currentMonthAbono, setCurrentMonthAbono] = useState(0);
  const [currentMonthProfit, setCurrentMonthProfit] = useState(0); // Añadido aquí
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isHistoricalModalOpen, setIsHistoricalModalOpen] = useState(false);
  const { hasFullDashboard, hasAdvancedAnalytics } = usePlanFeatures();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { darkMode } = useTheme();

  useEffect(() => {
    const getSession = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        if (session) {
          const userData = {
            id: session.user.id,
            name: session.user.user_metadata.name || "Administrador",
            email: session.user.email,
            imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
          };
          setUser(userData);
          setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
          loadData(session.user.id);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error getting session:", error);
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          const userData = {
            id: session.user.id,
            name: session.user.user_metadata.name || "Administrador",
            email: session.user.email,
            imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
          };
          setUser(userData);
          setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
          loadData(session.user.id);
        } else {
          setUser(null);
          navigate("/login");
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const calculateMonthlyStats = (ordersData) => {
    const statsByMonth = {};
    let currentMonthTotal = 0;
    let currentMonthProfit = 0;
    const currentDate = new Date();
    const currentMonthStart = startOfMonth(currentDate);
    const currentMonthEnd = endOfMonth(currentDate);

    ordersData.forEach((order) => {
      // Calculate total sales and profit for each product
      const orderDate = new Date(order.fecha);
      const monthKey = format(orderDate, "yyyy-MM");

      if (!statsByMonth[monthKey]) {
        statsByMonth[monthKey] = {
          sales: 0,
          profit: 0,
          abonos: 0,
        };
      }

      // Add sales and profit
      order.productos.forEach((producto) => {
        statsByMonth[monthKey].sales += producto.precio * producto.cantidad;
        statsByMonth[monthKey].profit +=
          (producto.profit || 0) * producto.cantidad;
      });

      // Add abonos
      (order.abonos || []).forEach((abono) => {
        const abonoDate = new Date(abono.fecha);
        const abonoMonthKey = format(abonoDate, "yyyy-MM");
        statsByMonth[abonoMonthKey].abonos += parseFloat(abono.monto);

        if (abonoDate >= currentMonthStart && abonoDate <= currentMonthEnd) {
          currentMonthTotal += parseFloat(abono.monto);
        }
      });

      // Calculate current month stats
      if (orderDate >= currentMonthStart && orderDate <= currentMonthEnd) {
        order.productos.forEach((producto) => {
          currentMonthProfit += (producto.profit || 0) * producto.cantidad;
        });
      }
    });

    const sortedMonths = Object.keys(statsByMonth).sort();
    const monthlyStats = sortedMonths.map((month) => ({
      month: format(new Date(month), "MMM yyyy"),
      sales: statsByMonth[month].sales,
      profit: statsByMonth[month].profit,
      abonos: statsByMonth[month].abonos,
    }));

    setMonthlyAbonos(monthlyStats);
    setCurrentMonthAbono(currentMonthTotal);
    setCurrentMonthProfit(currentMonthProfit);
  };

  const loadData = async (userId) => {
    setIsLoading(true);
    try {
      const [clientsData, ordersData, productsData] = await Promise.all([
        fetchClients(userId),
        fetchOrders(userId),
        fetchProducts(),
      ]);
      setClients(clientsData);
      setOrders(ordersData);
      setProducts(productsData);
      calculateMonthlyStats(ordersData);

      const { data: userData, error } = await supabase
        .from("users")
        .select("subscription")
        .eq("id", userId)
        .maybeSingle();

      if (error) throw error;
      setSubscription(userData ? userData.subscription : null);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); 
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: true },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
        { name: "Productos", href: "/productos", current: false },
        {
          name: "Gestionar Suscripciones",
          href: "/admin/subscriptions",
          current: false,
        },
      ]
      : []),
  ];

  const userNavigation = [
    { name: "Tu perfil", to: "/perfil" },
    { name: "Configuraciones", href: "#" },
    { name: "Cerrar sesión", onClick: handleLogout },
  ];

  const calculateMonthlyAbonos = (ordersData) => {
    const abonosByMonth = {};
    let currentMonthTotal = 0;
    const currentDate = new Date();
    const currentMonthStart = startOfMonth(currentDate);
    const currentMonthEnd = endOfMonth(currentDate);

    ordersData.forEach((order) => {
      (order.abonos || []).forEach((abono) => {
        const abonoDate = new Date(abono.fecha);
        const monthKey = format(abonoDate, "yyyy-MM");
        abonosByMonth[monthKey] =
          (abonosByMonth[monthKey] || 0) + parseFloat(abono.monto);

        if (abonoDate >= currentMonthStart && abonoDate <= currentMonthEnd) {
          currentMonthTotal += parseFloat(abono.monto);
        }
      });
    });

    const sortedMonths = Object.keys(abonosByMonth).sort();
    const monthlyAbonosData = sortedMonths.map((month) => ({
      month: format(new Date(month), "MMM yyyy"),
      total: abonosByMonth[month],
    }));

    setMonthlyAbonos(monthlyAbonosData);
    setCurrentMonthAbono(currentMonthTotal);
  };

  const orderStatusData = {
    labels: ["", ""],
    datasets: [
      {
        data: [
          orders.filter((order) => !order.entregado).length,
          orders.filter((order) => order.entregado).length,
        ],
        backgroundColor: ["#FF6B6B", "#4CBB17"],
        borderWidth: 0,
      },
    ],
  };

  const monthlyAbonosData = {
    labels: monthlyAbonos.map((item) => item.month),
    datasets: [
      {
        label: "Abonos mensuales",
        data: monthlyAbonos.map((item) => item.total),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgb(255, 159, 64)",
        borderWidth: 1,
      },
    ],
  };

  const topProducts = useMemo(() => {
    const productSales = products.map((product) => {
      const sales = orders.reduce((total, order) => {
        const productInOrder = order.productos.find((p) => p.id === product.id);
        return total + (productInOrder ? productInOrder.cantidad : 0);
      }, 0);
      return { ...product, sales };
    });

    return productSales.sort((a, b) => b.sales - a.sales).slice(0, 5);
  }, [products, orders]);

  const topClients = useMemo(() => {
    const clientOrders = clients.map((client) => {
      const orderCount = orders.filter(
        (order) => order.clienteId === client.id
      ).length;
      return { ...client, orderCount };
    });

    return clientOrders.sort((a, b) => b.orderCount - a.orderCount).slice(0, 5);
  }, [clients, orders]);



  return (
    <div className="min-h-full bg-gray-50">
      <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
        <main className="py-10">
          <div className="">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center mb-8"
            >
              <h1 className="text-4xl font-bold text-orange-600 sm:text-5xl">
                Dashboard de ventas
              </h1>
              <p className="mt-2 text-gray-600 sm:text-lg">Bienvenido/a, {user?.name}</p>
            </motion.div>
  
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
              <StatCard
                title="Total clientes"
                value={clients.length}
                icon={<UserIcon className="h-10 w-10 text-orange-600" />}
                darkMode={darkMode}
              />
              <StatCard
                title="Total pedidos"
                value={orders.length}
                icon={<ShoppingCartIcon className="h-10 w-10 text-orange-600" />}
                darkMode={darkMode}
              />
              <StatCard
                title="Pedidos pendientes"
                value={orders.filter((order) => !order.entregado).length}
                icon={<ClipboardDocumentListIcon className="h-10 w-10 text-orange-600" />}
                darkMode={darkMode}
              />
              <StatCard
                title="Total productos"
                value={products.length}
                icon={<CubeIcon className="h-10 w-10 text-orange-600" />}
                darkMode={darkMode}
              />
            </div>
  
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
              <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-2xl p-6 sm:p-8 transition-colors duration-300 lg:col-span-2`}>
                <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                  <h2 className={`text-2xl font-extrabold ${darkMode ? 'text-gray-100' : 'text-gray-800'} sm:text-3xl text-center sm:text-left`}>
                    Resumen mensual
                  </h2>
                  <button
                    onClick={() => setIsHistoricalModalOpen(true)}
                    className={`inline-flex items-center px-3 py-2 mt-4 sm:mt-0 border border-transparent text-sm font-semibold rounded-lg 
                      ${darkMode
                        ? 'text-orange-400 bg-orange-900/20 hover:bg-orange-900/30'
                        : 'text-orange-600 bg-orange-100 hover:bg-orange-200'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 transition`}
                  >
                    <ChartBarIcon className="h-5 w-5 mr-2" />
                    Historial
                  </button>
                </div>
            
                <div className="space-y-6">
                  {monthlyAbonos.slice(-3).reverse().map((stats, index) => (
                    <div
                      key={stats.month}
                      className={`${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg shadow-md p-4 sm:p-6 transition-colors duration-300`}
                    >
                      <h3 className={`text-lg font-semibold ${darkMode ? 'text-gray-100' : 'text-gray-700'} mb-4 text-center`}>
                        {stats.month}
                      </h3>
                      <div className="flex flex-col sm:flex-row justify-around items-center space-y-4 sm:space-y-0 sm:space-x-8">
                        <div className="flex flex-col items-center text-center">
                          <span className="text-green-400 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h4l3 9L17 10h4" />
                            </svg>
                          </span>
                          <p className={`text-lg font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(stats.sales)}
                          </p>
                          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Ventas Totales
                          </p>
                        </div>
            
                        <div className="flex flex-col items-center text-center">
                          <span className="text-green-500 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                            </svg>
                          </span>
                          <p className={`text-lg font-bold ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(stats.profit)}
                          </p>
                          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Ganancia
                          </p>
                        </div>
            
                        <div className="flex flex-col items-center text-center">
                          <span className="text-blue-400 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                          </span>
                          <p className={`text-lg font-bold ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(stats.abonos)}
                          </p>
                          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Abonos Recibidos
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {hasFullDashboard ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
                <RankingTable
                  title="Top 5 productos"
                  data={topProducts}
                  type="products"
                  darkMode={darkMode}
                />
                <RankingTable
                  title="Top 5 clientes frecuentes"
                  data={topClients}
                  type="clients"
                  darkMode={darkMode}
                />
              </div>
            ) : (
              <div className={`mt-8 rounded-xl p-6 text-center transition-colors duration-300
                ${darkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <h3 className={`text-xl font-medium mb-2 transition-colors duration-300
                  ${darkMode ? 'text-gray-100' : 'text-gray-900'} sm:text-2xl`}>
                  Funcionalidades Premium
                </h3>
                <p className={`transition-colors duration-300
                  ${darkMode ? 'text-gray-400' : 'text-gray-600'} sm:text-lg`}>
                  Actualiza a un plan premium para acceder a análisis avanzados
                  y más funcionalidades.
                </p>
                <button
                  onClick={() => setShowUpgradeModal(true)}
                  className={`mt-4 inline-flex items-center px-4 py-2 border border-transparent 
                    rounded-md shadow-sm text-sm font-medium text-white
                    transition-colors duration-300
                    ${darkMode
                      ? 'bg-orange-500 hover:bg-orange-600'
                      : 'bg-orange-600 hover:bg-orange-700'
                    } sm:px-6 sm:py-3 sm:text-base`}
                >
                  Actualizar Plan
                </button>
              </div>
            )}
          </div>
        </main>
        <HistoricalDataModal
          isOpen={isHistoricalModalOpen}
          onClose={() => setIsHistoricalModalOpen(false)}
          monthlyData={monthlyAbonos}
          darkMode={darkMode}
        />
  
        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
          userId={user?.id}
          darkMode={darkMode}
        />
      </Layout>
    </div>
  );
}