import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import LoginForm from "./components/LoginForm";
import ClientForm from "./components/ClientForm";
import ProductSearch from "./components/ProductSearch";
import OrderForm from "./components/OrderForm";
import Pedidos from "./components/Pedidos";
import Perfil from "./components/Perfil";
import Home from "./components/Home";
import Landing from "./components/Landing";
import AdminSubscriptions from "./components/AdminSubscriptions";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import withPremiumProtection from "./components/HOC/withPremiumProtection";
import { Toaster, toast } from "react-hot-toast";
import { supabase } from "./supabaseConfig";
import SubscriptionStatus from "./components/SubscriptionStatus";
import SubscriptionPlans from "./components/SubscriptionPlans";
import SubscriptionCallback from "./components/SubscriptionCallback";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
import About from "./components/About";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";


const ProtectedHome = withPremiumProtection(Home);
const ProtectedClientForm = withPremiumProtection(ClientForm);
const ProtectedProductSearch = withPremiumProtection(ProductSearch);
const ProtectedOrderForm = withPremiumProtection(OrderForm);
const ProtectedPedidos = withPremiumProtection(Pedidos);
const ProtectedPerfil = withPremiumProtection(Perfil);


const PublicLanding = withPremiumProtection(Landing, {
  excludeFromProtection: true,
});

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <AuthWrapper>
                  {(user) =>
                    user ? <Navigate to="/inicio" replace /> : <PublicLanding />
                  }
                </AuthWrapper>
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />

            <Route path="/register" element={<RegisterForm />} />
            <Route path="/login" element={<LoginForm />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/inicio" element={<ProtectedHome />} />
              <Route path="/perfil" element={<ProtectedPerfil />} />
              <Route path="/clientes" element={<ProtectedClientForm />} />
              <Route path="/registrar" element={<ProtectedOrderForm />} />
              <Route path="/pedidos" element={<ProtectedPedidos />} />
              <Route path="/productos" element={<ProtectedProductSearch />} />
              <Route
                path="/subscription-status"
                element={<SubscriptionStatus />}
              />
              <Route
                path="/subscription-plans"
                element={<SubscriptionPlans />}
              />
              <Route
                path="/subscription/success"
                element={<SubscriptionCallback />}
              />
              <Route
                path="/subscription/failure"
                element={<SubscriptionCallback />}
              />
              <Route
                path="/subscription/pending"
                element={<SubscriptionCallback />}
              />
            </Route>

            <Route element={<AdminRoute />}>
              <Route
                path="/admin/subscriptions"
                element={<AdminSubscriptions />}
              />
            </Route>
          </Routes>
        </Router>

        <Toaster position="top-right" />
      </AuthProvider>
    </ThemeProvider>
  );
}

function AuthWrapper({ children }) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

 
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);


  if (loading) {
    return 
  }

  return children(user);
}

export default App;
