import { supabase } from '../supabaseConfig';
import { 
  calculateRemainingDays, 
  calculateUpgradeCost 
} from '../utils/subscriptionCalculator';
import { plans } from '../components/SubscriptionPlans';

export const calculateUpgradeDetails = async (userId, newCycle = 'monthly') => {
  try {
    const { data: userData, error } = await supabase
      .from('users')
      .select('subscription')
      .eq('id', userId)
      .single();

    if (error) throw error;

    const subscription = userData.subscription;
    if (!subscription || !subscription.startDate || !subscription.endDate) {
      throw new Error('No active subscription found');
    }

    const remainingDays = calculateRemainingDays(
      subscription.startDate,
      subscription.endDate
    );

    if (remainingDays <= 0) {
      throw new Error('Subscription has expired');
    }

    const currentPlan = plans[subscription.planId];
    const upgradePlan = plans.standard;
    const currentCycle = subscription.billingCycle || 'monthly';

    return calculateUpgradeCost(
      currentPlan, 
      upgradePlan, 
      remainingDays,
      currentCycle,
      newCycle
    );
  } catch (error) {
    console.error('Error calculating upgrade details:', error);
    throw error;
  }
};

export const processUpgrade = async (userId, newCycle = 'monthly') => {
  try {
    const upgradeDetails = await calculateUpgradeDetails(userId, newCycle);
    
    const { data: userData, error: fetchError } = await supabase
      .from('users')
      .select('subscription')
      .eq('id', userId)
      .single();

    if (fetchError) throw fetchError;

    const currentSubscription = userData.subscription;
    
    const { error: updateError } = await supabase
      .from('users')
      .update({
        subscription: {
          ...currentSubscription,
          planId: 'standard',
          planName: 'Standard',
          billingCycle: newCycle,
          price: plans.standard[newCycle],
          upgradeDate: new Date().toISOString(),
          previousPlan: {
            planId: currentSubscription.planId,
            refundAmount: upgradeDetails.refundAmount
          }
        }
      })
      .eq('id', userId);

    if (updateError) throw updateError;

    return {
      success: true,
      upgradeDetails
    };
  } catch (error) {
    console.error('Error processing upgrade:', error);
    throw error;
  }
};