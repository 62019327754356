// src/components/orders/OrderModalPedidos.js

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useTheme } from "../../context/ThemeContext";
import ModalPortal from "../ModalPortal";
import OrderForm from "./OrderForm";

export default function OrderModalPedidos({
  isOpen,
  onClose,
  onSubmit,
  initialData = null,
}) {
  const { darkMode } = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formData) => {
    try {
      setIsSubmitting(true);
      await onSubmit(formData);
      toast.success("Pedido creado exitosamente");
      onClose();
    } catch (error) {
      console.error("Error al crear el pedido:", error);
      toast.error("Error al crear el pedido");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalPortal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
              onClick={onClose}
            />
            <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
              <motion.div
                initial={{ y: "100vh" }}
                animate={{ y: 0 }}
                exit={{ y: "100vh" }}
                transition={{ duration: 0.3 }}
                onClick={(e) => e.stopPropagation()}
                className={`w-full max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto ${
                  darkMode ? "bg-gray-800" : "bg-white"
                } rounded-2xl shadow-xl overflow-y-auto max-h-full`}
              >
                <div className="flex justify-between items-center p-4 md:p-6 border-b border-gray-600">
                  <h2
                    className={`text-lg md:text-2xl font-bold ${
                      darkMode ? "text-gray-100" : "text-gray-900"
                    }`}
                  >
                    {initialData ? "Editar Pedido" : "Nuevo pedido"}
                  </h2>
                  <button
                    onClick={onClose}
                    className={`${
                      darkMode
                        ? "text-gray-400 hover:text-gray-300"
                        : "text-gray-400 hover:text-gray-500"
                    }`}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                <div className="p-4 md:p-6">
                  <OrderForm
                    onSubmit={handleSubmit}
                    initialData={initialData}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
}