import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { motion } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import toast from 'react-hot-toast';

export default function PaymentSection({ order, onAddAbono, formatToCOP }) {
    const [abonoMonto, setAbonoMonto] = useState('');
    const [currentOrder, setCurrentOrder] = useState(order);
    const { darkMode } = useTheme();

    useEffect(() => {
        setCurrentOrder(order);
    }, [order]);

    const calculateTotal = (productos) => {
        return productos.reduce(
            (acc, p) => acc + parseFloat(p.precio) * parseFloat(p.cantidad),
            0
        );
    };

    const handleAbonoChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, '');
        if (value === '') {
            setAbonoMonto('');
        } else {
            const numberValue = parseInt(value, 10);
            setAbonoMonto(formatToCOP(numberValue));
        }
    };

    const handleSubmitAbono = async () => {
        if (!abonoMonto) {
            toast.error('El monto del abono no puede estar vacío');
            return;
        }

        const montoNumerico = parseInt(abonoMonto.replace(/\D/g, ''), 10);

        if (montoNumerico <= 0) {
            toast.error('El monto del abono debe ser mayor a 0');
            return;
        }

        if (montoNumerico > saldoPendiente) {
            toast.error('El monto del abono no puede ser mayor al saldo pendiente');
            return;
        }

        try {
            await onAddAbono(currentOrder.id, abonoMonto);
            setAbonoMonto('');
            
            // Update local state immediately
            const newAbono = {
                fecha: new Date().toISOString(),
                monto: montoNumerico
            };
            
            setCurrentOrder(prev => ({
                ...prev,
                abonos: [...(prev.abonos || []), newAbono],
                totalAbonado: (prev.totalAbonado || 0) + montoNumerico
            }));

            if (montoNumerico === saldoPendiente) {
                toast.success('¡Pedido pagado completamente!');
            } else {
                toast.success('Abono agregado exitosamente');
            }
        } catch (error) {
            toast.error('Error al agregar el abono');
        }
    };

    const handlePayTotal = async () => {
        try {
            await onAddAbono(currentOrder.id, formatToCOP(saldoPendiente));
            setAbonoMonto('');
            
            // Update local state immediately
            const newAbono = {
                fecha: new Date().toISOString(),
                monto: saldoPendiente
            };
            
            setCurrentOrder(prev => ({
                ...prev,
                abonos: [...(prev.abonos || []), newAbono],
                totalAbonado: totalPedido
            }));

            toast.success('¡Pedido pagado completamente!');
        } catch (error) {
            toast.error('Error al procesar el pago total');
        }
    };

    const totalPedido = calculateTotal(currentOrder.productos);
    const totalAbonado = currentOrder.totalAbonado || 0;
    const saldoPendiente = totalPedido - totalAbonado;

    return (
        <div className="space-y-6">
            <div className={`border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'} pt-6`}>
                <h3 className={`text-lg font-medium mb-4 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Estado de Pagos
                </h3>

                {/* Payment Status Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                    <div className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total del Pedido</p>
                        <p className={`text-xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                            {formatToCOP(totalPedido)}
                        </p>
                    </div>
                    <div className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total Abonado</p>
                        <p className="text-xl font-bold text-green-600">
                            {formatToCOP(totalAbonado)}
                        </p>
                    </div>
                    <div className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Saldo Pendiente</p>
                        <p className="text-xl font-bold text-orange-600">
                            {formatToCOP(saldoPendiente)}
                        </p>
                    </div>
                </div>

                {/* Add Payment Section */}
                {saldoPendiente > 0 && (
                    <div className={`rounded-lg border p-4 mb-6 ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'
                        }`}>
                        <h4 className={`text-sm font-medium mb-4 ${darkMode ? 'text-gray-100' : 'text-gray-900'
                            }`}>Agregar Abono</h4>
                        <div className="flex flex-col sm:flex-row gap-4">
                            <div className="flex-grow">
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className={darkMode ? 'text-gray-400' : 'text-gray-500'}>$</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={abonoMonto}
                                        onChange={handleAbonoChange}
                                        className={`block w-full pl-7 pr-12 sm:text-sm rounded-md ${darkMode
                                                ? 'bg-gray-800 border-gray-600 text-gray-100 focus:ring-orange-500 focus:border-orange-500'
                                                : 'border-gray-300 focus:ring-orange-500 focus:border-orange-500'
                                            }`}
                                        placeholder="0"
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={handleSubmitAbono}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                >
                                    + Agregar abono
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={handlePayTotal}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    $ Pago total
                                </motion.button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Payment History */}
                {order.abonos && order.abonos.length > 0 && (
                    <div>
                        <h4 className={`text-sm font-medium mb-4 ${darkMode ? 'text-gray-100' : 'text-gray-900'
                            }`}>Historial de Abonos</h4>

                        {/* Desktop Table */}
                        <div className="hidden sm:block">
                            <div className={`rounded-lg overflow-hidden ${darkMode ? 'bg-gray-700' : 'bg-gray-50'
                                }`}>
                                <table className={`min-w-full divide-y ${darkMode ? 'divide-gray-600' : 'divide-gray-200'
                                    }`}>
                                    <thead className={darkMode ? 'bg-gray-800' : 'bg-gray-100'}>
                                        <tr>
                                            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'
                                                }`}>Fecha</th>
                                            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'
                                                }`}>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody className={`divide-y ${darkMode ? 'divide-gray-600' : 'divide-gray-200'
                                        }`}>
                                        {order.abonos.map((abono, index) => (
                                            <tr key={index}>
                                                <td className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'
                                                    }`}>
                                                    {format(new Date(abono.fecha), "d 'de' MMMM 'de' yyyy", { locale: es })}
                                                </td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'
                                                    }`}>
                                                    {formatToCOP(abono.monto)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Mobile Cards */}
                        <div className="sm:hidden space-y-4">
                            {order.abonos.map((abono, index) => (
                                <div key={index} className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-50'
                                    }`}>
                                    <div className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'
                                        }`}>
                                        {format(new Date(abono.fecha), "d 'de' MMMM 'de' yyyy", { locale: es })}
                                    </div>
                                    <div className={`text-lg font-medium mt-1 ${darkMode ? 'text-gray-200' : 'text-gray-900'
                                        }`}>
                                        {formatToCOP(abono.monto)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}