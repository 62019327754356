import { initMercadoPago } from '@mercadopago/sdk-react';

// Initialize Mercado Pago with production public key
initMercadoPago('APP_USR-07825152-3b0e-4bef-990f-69056ff89f39', {
  locale: 'es-CO'
});

export const createPreference = async (selectedPlanDetails) => {
  try {
    // Calculate total price based on billing cycle
    const price = selectedPlanDetails.price;
    const cycle = selectedPlanDetails.billingCycle;
    
    // Create a more descriptive title based on plan and cycle
    const cycleText = cycle === 'sixMonth' ? '6 meses' : cycle === 'annual' ? 'anual' : 'mensual';
    const title = `Plan ${selectedPlanDetails.name} - Suscripción ${cycleText}`;
    
    // Create a detailed description
    const description = `Suscripción ${cycleText} al plan ${selectedPlanDetails.name}`;

    const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer APP_USR-5690292916769267-120512-489090204aacb43812ffa4c859293bc7-426652786`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items: [
          {
            title: title,
            description: description,
            quantity: 1,
            currency_id: 'COP',
            unit_price: price
          }
        ],
        back_urls: {
          success: `${window.location.origin}/subscription/success`,
          failure: `${window.location.origin}/subscription/failure`,
          pending: `${window.location.origin}/subscription/pending`
        },
        auto_return: 'approved',
        // Add metadata to track plan details
        metadata: {
          plan_id: selectedPlanDetails.id,
          plan_name: selectedPlanDetails.name,
          billing_cycle: selectedPlanDetails.billingCycle,
          price: price
        }
      })
    });

    const preference = await response.json();
    return preference;
  } catch (error) {
    console.error('Error creating preference:', error);
    throw error;
  }
};