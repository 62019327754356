// Utility functions for subscription calculations
export const calculateRemainingDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();
    
    if (today >= end) return 0;
    
    const remainingTime = end.getTime() - today.getTime();
    return Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  };
  
  export const getCycleDuration = (billingCycle) => {
    switch (billingCycle) {
      case 'sixMonth':
        return 180; // 6 months
      case 'annual':
        return 365; // 1 year
      case 'monthly':
      default:
        return 30; // 1 month
    }
  };
  
  export const calculateUpgradeCost = (currentPlan, newPlan, remainingDays, currentCycle, newCycle) => {
    // Get cycle durations
    const currentCycleDuration = getCycleDuration(currentCycle);
    const newCycleDuration = getCycleDuration(newCycle);
  
    // Get plan prices based on billing cycle
    const currentPrice = currentPlan[currentCycle];
    const newPrice = newPlan[newCycle]; // This is correct - will get sixMonth price (27000)
  
    // Calculate daily rates
    const currentDailyRate = currentPrice / currentCycleDuration;
    
    // For the new plan, we should charge the full period price
    // instead of prorating it for remaining days
    const refundAmount = Math.round(currentDailyRate * remainingDays);
    const upgradeCost = newPrice; // Charge full new plan price
    const totalDue = upgradeCost - refundAmount;
  
    return {
      refundAmount,
      upgradeCost,
      totalDue
    };
  };