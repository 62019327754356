import React, { useState, useEffect } from 'react';
import { usePremiumCheck } from '../hooks/usePremiumCheck';
import SubscriptionPlans from './SubscriptionPlans';
import { supabase } from '../supabaseConfig';
import { useNavigate } from 'react-router-dom';

export default function ProtectedFeature({ children }) {
  const { hasPremium, loading, isAdmin } = usePremiumCheck();
  const [showPlans, setShowPlans] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const user = session?.user;
      
      if (!user) {
        navigate('/login');
        return;
      }

      if (user.email === "tamayodaza9@gmail.com") {
        return; // Allow admin access
      }

      const { data: userDoc, error } = await supabase
        .from('users')
        .select('subscription')
        .eq('id', user.id)
        .single();
      
      if (error) {
        console.error('Error checking subscription:', error);
        return;
      }

      const subscription = userDoc?.subscription;
      const isActive = subscription?.verified && 
                      subscription?.endDate && 
                      new Date(subscription.endDate) > new Date();

      if (!isActive) {
        setShowPlans(true);
      }
    };

    checkSubscriptionStatus();
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (isAdmin || hasPremium) {
    return children;
  }

  if (showPlans) {
    return <SubscriptionPlans />;
  }

  return null;
}