import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

export default function HistoricalDataModal({ isOpen, onClose, monthlyData, darkMode }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`w-full max-w-4xl transform overflow-hidden rounded-2xl p-6 shadow-xl transition-all ${
                darkMode ? 'bg-gray-800' : 'bg-white'
              }`}>
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title className={`text-2xl font-bold ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Historial                  </Dialog.Title>
                  <button
                    onClick={onClose}
                    className={`${
                      darkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-400 hover:text-gray-500'
                    } focus:outline-none focus:ring-2 focus:ring-orange-500 rounded-full p-1`}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                <div className="overflow-x-auto">
                  <table className={`min-w-full table-auto ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    <thead>
                      <tr>
                        <th className="px-4 py-2 border-b">Mes</th>
                        <th className="px-4 py-2 border-b">Ventas totales</th>
                        <th className="px-4 py-2 border-b">Ganancia</th>
                        <th className="px-4 py-2 border-b">Abonos recibidos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {monthlyData.map((stats, index) => (
                        <tr key={stats.month} className={`${index % 2 === 0 ? (darkMode ? 'bg-gray-700' : 'bg-gray-100') : ''}`}>
                          <td className="px-4 py-2 border-b">{stats.month}</td>
                          <td className="px-4 py-2 border-b">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.sales)}
                          </td>
                          <td className="px-4 py-2 border-b text-green-500">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.profit)}
                          </td>
                          <td className="px-4 py-2 border-b text-orange-500">
                            {new Intl.NumberFormat('es-CO', {
                              style: 'currency',
                              currency: 'COP',
                              minimumFractionDigits: 0,
                            }).format(stats.abonos)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-6 flex justify-end">
                  <button
                    onClick={onClose}
                    className={`px-4 py-2 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${
                      darkMode 
                        ? 'bg-gray-700 text-gray-100 hover:bg-gray-600' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    Cerrar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}