import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';
import OrderDetails from './OrderDetails';
import PaymentSection from './PaymentSection';
import { Fragment } from 'react';
import { useTheme } from './../../context/ThemeContext';

export default function OrderModal({ isOpen, onClose, order, onAddAbono, formatToCOP }) {
    const { darkMode } = useTheme();
    if (!order) return null;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-full max-w-4xl transform overflow-hidden rounded-2xl p-6 shadow-xl transition-all ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                                <div className="flex justify-between items-center mb-6">
                                    <Dialog.Title className={`text-2xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                        Detalles del pedido
                                    </Dialog.Title>
                                    <button
                                        onClick={onClose}
                                        className={`${darkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-400 hover:text-gray-500'} focus:outline-none`}
                                    >
                                        <XMarkIcon className="h-6 w-6" />
                                    </button>
                                </div>

                                <div className="space-y-8">
                                    <OrderDetails order={order} formatToCOP={formatToCOP} darkMode={darkMode} />
                                    <PaymentSection
                                        order={order}
                                        onAddAbono={onAddAbono}
                                        formatToCOP={formatToCOP}
                                        darkMode={darkMode}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}