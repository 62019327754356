import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseConfig";
import { Toaster, toast } from 'react-hot-toast';
import Layout from "./dashboard/Layout";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  CheckCircleIcon,
  XCircleIcon,
  CalendarIcon,
  UserCircleIcon,
  ArrowPathIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

import RenewalModal from './RenewalModal';
import { useTheme } from "../context/ThemeContext";

const TABS = [
  { id: "all", label: "Todos los usuarios", icon: UserCircleIcon },
  { id: "active", label: "Activas", icon: CheckCircleIcon },
  { id: "cancelled", label: "Canceladas", icon: XCircleIcon },
  { id: "expired", label: "Vencidas", icon: CalendarIcon },
];

const PLANS = {
  basic: {
    name: "Basic",
    monthly: 5000,
    sixMonth: 27000,
    annual: 48000,
  },
  standard: {
    name: "Standard",
    monthly: 10000,
    sixMonth: 54000,
    annual: 96000,
  },
};

const checkAndUpdateExpiredSubscriptions = (subscriptions) => {
  const now = new Date();
  return subscriptions.map(sub => {
    if (sub.subscription?.endDate) {
      const endDate = new Date(sub.subscription.endDate);
      if (now > endDate && sub.subscription.status === "activo") {
        return {
          ...sub,
          subscription: {
            ...sub.subscription,
            status: "vencido"
          }
        };
      }
    }
    return sub;
  });
};

const SubscriptionRow = ({
  subscription,
  onVerify,
  onReject,
  onCancel,
  onRenew,
  onReminder,
  darkMode,
}) => {
  const [showRenewalModal, setShowRenewalModal] = useState(false);

  const getRemainingDays = () => {
    if (!subscription?.subscription?.endDate) return 0;
    const end = new Date(subscription.subscription.endDate);
    const now = new Date();
    const days = Math.ceil((end - now) / (1000 * 60 * 60 * 24));
    return Math.max(0, days);
  };
  const remainingDays = getRemainingDays();
  const isNearExpiration = remainingDays <= 3 && remainingDays > 0;

  return (
    <motion.tr
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-50'} transition-colors`}
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <div className="h-10 w-10 rounded-full bg-gradient-to-r from-orange-400 to-orange-600 flex items-center justify-center">
              <UserCircleIcon className="h-6 w-6 text-white" />
            </div>
          </div>
          <div className="ml-4">
            <div className={`text-sm font-medium ${darkMode ? "text-white" : "text-gray-900"}`}>
              {subscription.email}
            </div>
            <div className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-500"}`}>{subscription.name}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        {subscription?.subscription?.paymentId ? (
          <>
            <div className={`text-sm ${darkMode ? "text-white" : "text-gray-900"}`}>
              ID: {subscription.subscription.paymentId}
            </div>
            <div className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-500"}`}>
              Inicio: {new Date(subscription.subscription.startDate).toLocaleDateString()}
            </div>
            <div className={`text-xs ${darkMode ? "text-gray-400" : "text-gray-500"}`}>
              Fin: {new Date(subscription.subscription.endDate).toLocaleDateString()}
            </div>
            <div
              className={`text-xs font-medium ${
                remainingDays <= 3 ? "text-red-600" : "text-green-600"
              }`}
            >
              {remainingDays} días restantes
            </div>
          </>
        ) : (
          <div className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-500"}`}>Sin suscripción activa</div>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span
          className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
            !subscription.subscription?.status
              ? "bg-blue-100 text-blue-800"
              : subscription.subscription.status === "activo"
              ? "bg-emerald-100 text-emerald-800"
              : subscription.subscription.status === "cancelado"
              ? "bg-gray-100 text-gray-800"
              : subscription.subscription.status === "vencido"
              ? "bg-orange-100 text-orange-800"
              : "bg-blue-100 text-blue-800"
          }`}
        >
          {!subscription.subscription?.status
            ? "Nuevo"
            : subscription.subscription.status.charAt(0).toUpperCase() +
              subscription.subscription.status.slice(1)}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className="flex flex-col items-end space-y-2">
          <div className="flex justify-end space-x-2">
            {!subscription.subscription?.status ? (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowRenewalModal(true)}
                className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
              >
                <ArrowPathIcon className="h-4 w-4 mr-1" />
                Aplicar Suscripción
              </motion.button>
            ) : (
              <>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => onCancel(subscription.id)}
                  className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                  Cancelar
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowRenewalModal(true)}
                  className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
                >
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Renovar
                </motion.button>
              </>
            )}
          </div>

          {subscription?.subscription?.status === "activo" &&
            isNearExpiration && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onReminder(subscription)}
                className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                <PhoneIcon className="h-4 w-4 mr-1" />
                Recordar
              </motion.button>
            )}

          <RenewalModal
            isOpen={showRenewalModal}
            onClose={() => setShowRenewalModal(false)}
            onRenew={(plan, cycle) => {
              onRenew(subscription.id, plan, cycle);
              setShowRenewalModal(false);
            }}
            darkMode={darkMode}
          />
        </div>
      </td>
    </motion.tr>
  );
};

const TabButton = ({ tab, isActive, count, onClick }) => {
  const Icon = tab.icon;

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`relative inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium ${
        isActive
          ? "bg-orange-100 text-orange-700"
          : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300"
      }`}
    >
      <Icon className="h-5 w-5 mr-2" />
      <span>{tab.label}</span>
      {count > 0 && (
        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-200 text-orange-800">
          {count}
        </span>
      )}
    </motion.button>
  );
};

export default function AdminSubscriptions() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [loading, setLoading] = useState(true);
  const { darkMode } = useTheme();

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Administrador",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
        if (session.user.email !== "tamayodaza9@gmail.com") {
          navigate("/inicio");
        }
      } else {
        navigate("/login");
      }
    };

    getSession();
  }, [navigate]);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const handleCancelSubscription = async (userId) => {
    try {
      const { error } = await supabase
        .from("users")
        .update({
          subscription: {
            status: "cancelado",
          },
        })
        .eq("id", userId);

      if (error) throw error;
      toast.success("Suscripción cancelada exitosamente");
      loadSubscriptions();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error("Error al cancelar la suscripción");
    }
  };

  const handleRenewSubscription = async (userId, plan, cycle) => {
    try {
      const startDate = new Date().toISOString();
      let endDate;

      // Calculate end date based on cycle
      switch (cycle) {
        case "sixMonth":
          endDate = new Date(
            Date.now() + 180 * 24 * 60 * 60 * 1000
          ).toISOString();
          break;
        case "annual":
          endDate = new Date(
            Date.now() + 365 * 24 * 60 * 60 * 1000
          ).toISOString();
          break;
        default: // monthly
          endDate = new Date(
            Date.now() + 30 * 24 * 60 * 60 * 1000
          ).toISOString();
      }

      const subscriptionData = {
        price: PLANS[plan][cycle],
        planId: plan,
        status: "activo",
        endDate: endDate,
        planName: PLANS[plan].name,
        verified: true,
        paymentId: "admin_renovado",
        startDate: startDate,
        billingCycle: cycle,
      };

      const { error } = await supabase
        .from("users")
        .update({
          subscription: subscriptionData,
        })
        .eq("id", userId);

      if (error) throw error;
      toast.success("Suscripción renovada exitosamente");
      loadSubscriptions();
    } catch (error) {
      console.error("Error renewing subscription:", error);
      toast.error("Error al renovar la suscripción");
    }
  };

  const handleSendReminder = (subscription) => {
    const message = `¡Hola! Tu suscripción en YanbalPro vencerá en ${getRemainingDays(
      subscription
    )} días. Renueva ahora para mantener el acceso a todas las funciones.`;
    const whatsappUrl = `https://wa.me/${
      subscription.phone
    }?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  const getRemainingDays = (subscription) => {
    if (!subscription.subscription.endDate) return 0;
    const end = new Date(subscription.subscription.endDate);
    const now = new Date();
    return Math.max(0, Math.ceil((end - now) / (1000 * 60 * 60 * 24)));
  };

  const loadSubscriptions = async () => {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("id, email, name, subscription, created_at");

      if (error) throw error;
     
      const updatedSubscriptions = checkAndUpdateExpiredSubscriptions(data);
    
      for (const sub of updatedSubscriptions) {
        if (sub.subscription?.status === "vencido" && 
            data.find(d => d.id === sub.id)?.subscription?.status === "activo") {
          await supabase
            .from("users")
            .update({
              subscription: sub.subscription
            })
            .eq("id", sub.id);
        }
      }
  
      setSubscriptions(updatedSubscriptions);
      setLoading(false);
    } catch (error) {
      console.error("Error loading subscriptions:", error);
      toast.error("Error al cargar los usuarios");
      setLoading(false);
    }
  };

  const handleVerifyPayment = async (userId, paymentId) => {
    try {
      const { data: userData, error: fetchError } = await supabase
        .from("users")
        .select("subscription")
        .eq("id", userId)
        .single();

      if (fetchError) throw fetchError;

      const currentSubscription = userData.subscription;
      const startDate = new Date().toISOString();
      let endDate;

      switch (currentSubscription.billingCycle) {
        case "sixMonth":
          endDate = new Date(
            Date.now() + 180 * 24 * 60 * 60 * 1000
          ).toISOString();
          break;
        case "annual":
          endDate = new Date(
            Date.now() + 365 * 24 * 60 * 60 * 1000
          ).toISOString();
          break;
        default:
          endDate = new Date(
            Date.now() + 30 * 24 * 60 * 60 * 1000
          ).toISOString();
      }

      const { error } = await supabase
        .from("users")
        .update({
          subscription: {
            ...currentSubscription,
            verified: true,
            startDate,
            endDate,
            paymentId,
            status: "activo",
          },
        })
        .eq("id", userId);

      if (error) throw error;

      toast.success("Suscripción verificada exitosamente");
      loadSubscriptions();
    } catch (error) {
      console.error("Error verifying payment:", error);
      toast.error("Error al verificar el pago");
    }
  };

  const handleRejectPayment = async (userId) => {
    try {
      const { error } = await supabase
        .from("users")
        .update({
          subscription: {
            status: "rechazado",
            paymentId: null,
          },
        })
        .eq("id", userId);

      if (error) throw error;

      toast.success("Suscripción rechazada");
      loadSubscriptions();
    } catch (error) {
      console.error("Error rejecting payment:", error);
      toast.error("Error al rechazar el pago");
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    { name: "Productos", href: "/productos", current: false },
    {
      name: "Gestionar suscripciones",
      href: "/admin/subscriptions",
      current: true,
    },
  ];

  const filteredSubscriptions = subscriptions.filter((sub) => {
    const now = new Date();
    switch (activeTab) {
      case "active":
        return sub.subscription?.status === "activo" && 
               new Date(sub.subscription.endDate) > now;
      case "cancelled":
        return sub.subscription?.status === "cancelado";
      case "expired":
        return sub.subscription?.status === "vencido" || 
               (sub.subscription?.status === "activo" && 
                new Date(sub.subscription.endDate) < now);
      case "all":
      default:
        return true;
    }
  });

  const getSubscriptionCount = (status) => {
    if (status === "all") return subscriptions.length;
    return subscriptions.filter((sub) => sub.subscription?.status === status)
      .length;
  };

  if (loading) {
    return 
  }

  return (
    <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
      <div className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8"
          >
            <h1 className={`text-3xl font-bold ${darkMode ? "text-white" : "text-gray-900"}`}>
              Gestión de suscripciones
            </h1>
            <p className={`mt-2 text-sm ${darkMode ? "text-gray-400" : "text-gray-600"}`}>
              Administra y verifica las suscripciones de los usuarios
            </p>
          </motion.div>

          <div className={`rounded-lg shadow overflow-hidden ${darkMode ? "bg-gray-800" : "bg-white"}`}>
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex flex-wrap gap-4">
                {TABS.map((tab) => (
                  <TabButton
                    key={tab.id}
                    tab={tab}
                    isActive={activeTab === tab.id}
                    count={getSubscriptionCount(
                      tab.id === "active"
                        ? "activo"
                        : tab.id === "cancelled"
                        ? "cancelado"
                        : tab.id === "expired"
                        ? "vencido"
                        : "all"
                    )}
                    onClick={() => setActiveTab(tab.id)}
                  />
                ))}
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className={`bg-gray-50 dark:bg-gray-700`}>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Usuario
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Detalles de Pago
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className={`divide-y divide-gray-200 dark:divide-gray-700`}>
                  <AnimatePresence>
                    {filteredSubscriptions.map((subscription) => (
                      <SubscriptionRow
                        key={subscription.id}
                        subscription={subscription}
                        onVerify={handleVerifyPayment}
                        onReject={handleRejectPayment}
                        onCancel={handleCancelSubscription}
                        onRenew={handleRenewSubscription}
                        onReminder={handleSendReminder}
                        darkMode={darkMode}
                      />
                    ))}
                  </AnimatePresence>
                  {filteredSubscriptions.length === 0 && (
                    <motion.tr
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <td
                        colSpan="4"
                        className={`px-6 py-12 text-center ${darkMode ? "text-gray-400" : "text-gray-500"}`}
                      >
                        No hay suscripciones en esta categoría
                      </td>
                    </motion.tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </Layout>
  );
}