import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ModalPortal from './ModalPortal';

const PLANS = {
  basic: {
    name: "Basic",
    monthly: 5000,
    sixMonth: 27000,
    annual: 48000,
  },
  standard: {
    name: "Standard",
    monthly: 10000,
    sixMonth: 54000,
    annual: 96000,
  },
};

function RenewalModal({ isOpen, onClose, onRenew, darkMode }) {
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [selectedCycle, setSelectedCycle] = useState("monthly");

  return (
    <ModalPortal>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 9998
            }}
            onClick={onClose}
          />
          <div className="fixed inset-0 flex items-center justify-center p-4" style={{ zIndex: 9999 }}>
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={(e) => e.stopPropagation()}
              className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-2xl shadow-2xl w-full max-w-md p-8 relative`}
            >
              <h3 className={`text-lg font-medium mb-4 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                Renovar suscripción
              </h3>

              <div className="space-y-4">
                <div>
                  <label className={`block text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-700'} mb-2`}>
                    Plan
                  </label>
                  <select
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                    className={`w-full rounded-xl px-4 py-2 ${
                      darkMode 
                        ? 'bg-gray-700 border-gray-600 text-gray-200' 
                        : 'border-gray-300 text-gray-900'
                    } focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
                  >
                    <option value="basic">Basic</option>
                    <option value="standard">Standard</option>
                  </select>
                </div>

                <div>
                  <label className={`block text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-700'} mb-2`}>
                    Ciclo de facturación
                  </label>
                  <select
                    value={selectedCycle}
                    onChange={(e) => setSelectedCycle(e.target.value)}
                    className={`w-full rounded-xl px-4 py-2 ${
                      darkMode 
                        ? 'bg-gray-700 border-gray-600 text-gray-200' 
                        : 'border-gray-300 text-gray-900'
                    } focus:ring-2 focus:ring-orange-500 focus:border-transparent`}
                  >
                    <option value="monthly">Mensual</option>
                    <option value="sixMonth">Semestral</option>
                    <option value="annual">Anual</option>
                  </select>
                </div>

                <div className={`${darkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-xl`}>
                  <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    Precio total:
                  </p>
                  <p className={`text-lg font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    ${PLANS[selectedPlan][selectedCycle].toLocaleString()} COP
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={onClose}
                  className={`px-4 py-2 text-sm font-medium rounded-xl ${
                    darkMode 
                      ? 'bg-gray-700 text-gray-200 hover:bg-gray-600' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Cancelar
                </button>
                <button
                  onClick={() => onRenew(selectedPlan, selectedCycle)}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-xl ${
                    darkMode 
                      ? 'bg-orange-500 hover:bg-orange-600' 
                      : 'bg-orange-600 hover:bg-orange-700'
                  }`}
                >
                  Confirmar renovación
                </button>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </ModalPortal>
  );
}

export default RenewalModal;