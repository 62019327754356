import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../supabaseConfig';
import { Toaster, toast } from 'react-hot-toast';

export default function SubscriptionCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const status = searchParams.get('status');
  const paymentId = searchParams.get('payment_id');

  useEffect(() => {
    const handlePaymentResult = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) {
          toast.error('No se encontró una sesión activa');
          navigate('/login');
          return;
        }

        // Get current subscription details
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('subscription')
          .eq('id', session.user.id)
          .single();

        if (userError) throw userError;

        const currentSubscription = userData.subscription;
        const startDate = new Date().toISOString();
        let endDate;

        // Calculate end date based on billing cycle
        switch (currentSubscription.billingCycle) {
          case 'sixMonth':
            endDate = new Date(Date.now() + 180 * 24 * 60 * 60 * 1000).toISOString();
            break;
          case 'annual':
            endDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString();
            break;
          default: // monthly
            endDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString();
        }

        if (status === 'approved') {
          const { error } = await supabase
            .from('users')
            .update({
              subscription: {
                ...currentSubscription, // Preserve all existing subscription details
                status: 'activo',
                verified: true,
                startDate: startDate,
                endDate: endDate,
                paymentId: paymentId,
                planName: currentSubscription.planName,
                billingCycle: currentSubscription.billingCycle,
                price: currentSubscription.price
              }
            })
            .eq('id', session.user.id);

          if (error) throw error;

          toast.success('¡Suscripción activada con éxito!');
          navigate('/inicio');
        } else {
          toast.error('El pago no fue aprobado');
          navigate('/subscription-plans');
        }
      } catch (error) {
        console.error('Error processing payment result:', error);
        toast.error('Error al procesar el resultado del pago');
        navigate('/subscription-plans');
      }
    };

    handlePaymentResult();
  }, [status, paymentId, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500 mx-auto"></div>
        <p className="mt-4 text-xl text-gray-600">Procesando tu pago...</p>
      </div>
    </div>
  );
}