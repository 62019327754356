import React, { useState } from "react";
import { motion } from "framer-motion";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { addOrder } from "../../services/supabaseService";
import { supabase } from "../../supabaseConfig";

import {
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import OrderModalPedidos from "./OrderModalPedidos";
import { FaFileExcel } from "react-icons/fa";
import { useTheme } from "../../context/ThemeContext";
import { useSubscription } from "../../hooks/useSubscription";
import { toast } from "react-hot-toast";

export default function OrdersTable({
  orders,
  onOrderClick,
  formatToCOP,
  onOrderCreated,
  userId,
}) {
  const { darkMode } = useTheme();
  const { subscriptionStatus, planId } = useSubscription();
  const isStandardPlan = planId === "standard";
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const [showNewOrderModal, setShowNewOrderModal] = useState(false);

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const calculateTotal = (productos) => {
    return productos.reduce(
      (acc, p) => acc + parseFloat(p.precio) * parseFloat(p.cantidad),
      0
    );
  };

  const calculateTotalProfit = (productos) => {
    return productos.reduce(
      (acc, p) => acc + parseFloat(p.profit) * parseFloat(p.cantidad),
      0
    );
  };

  const handleCreateOrder = async (orderData) => {
    try {
      console.log("Creando pedido con userId:", userId, "y datos:", orderData);
      await addOrder(userId, orderData);
      if (onOrderCreated) {
        onOrderCreated();
      }
      toast.success("Pedido creado exitosamente");
    } catch (error) {
      console.error("Error al crear el pedido:", error);
      toast.error("Error al crear el pedido");
    }
  };

  const handleExportToExcel = async () => {
    if (!isStandardPlan) return;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reporte Avanzado");

    // Configurar columnas
    worksheet.columns = [
      { header: "ID Pedido", key: "idPedido", width: 15 },
      { header: "Fecha", key: "fecha", width: 15 },
      { header: "Cliente", key: "cliente", width: 25 },
      { header: "Teléfono", key: "telefono", width: 15 },
      { header: "Dirección", key: "direccion", width: 30 },
      { header: "Estado", key: "estado", width: 15 },
      { header: "ID Producto", key: "idProducto", width: 15 },
      { header: "Producto", key: "producto", width: 25 },
      { header: "Cantidad", key: "cantidad", width: 10 },
      { header: "Precio Unitario", key: "precioUnitario", width: 15 },
      { header: "Ganancia Unitaria", key: "gananciaUnitaria", width: 18 },
      { header: "Total Producto", key: "totalProducto", width: 15 },
      { header: "Ganancia Producto", key: "gananciaProducto", width: 20 },
      { header: "Total Pedido", key: "totalPedido", width: 15 },
      { header: "Ganancia Pedido", key: "gananciaPedido", width: 18 },
      { header: "Abonado", key: "abonado", width: 12 },
      { header: "Saldo Pendiente", key: "saldoPendiente", width: 18 },
    ];

    // Estilos para encabezados
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" }, size: 12 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF4F46E5" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let totalVentas = 0;
    let totalGanancias = 0;
    let totalPedidos = orders.length;
    let pedidosPagados = 0;
    let pedidosPendientes = 0;

    // Datos de pedidos
    orders.forEach((order) => {
      const totalAmount = calculateTotal(order.productos);
      const totalProfit = calculateTotalProfit(order.productos);

      totalVentas += totalAmount;
      totalGanancias += totalProfit;

      if (order.totalAbonado >= totalAmount) {
        pedidosPagados++;
      } else {
        pedidosPendientes++;
      }

      order.productos.forEach((producto) => {
        worksheet.addRow({
          idPedido: order.id,
          fecha: format(new Date(order.fecha), "dd/MM/yyyy"),
          cliente: order.clienteNombre,
          telefono: order.cliente?.telefono || "N/A",
          direccion: order.cliente?.direccion || "N/A",
          estado: getStatusText(order),
          idProducto: producto.id,
          producto: producto.nombre,
          cantidad: producto.cantidad,
          precioUnitario: parseFloat(producto.precio),
          gananciaUnitaria: parseFloat(producto.profit),
          totalProducto: parseFloat(producto.precio) * producto.cantidad,
          gananciaProducto: parseFloat(producto.profit) * producto.cantidad,
          totalPedido: totalAmount,
          gananciaPedido: totalProfit,
          abonado: order.totalAbonado || 0,
          saldoPendiente: totalAmount - (order.totalAbonado || 0),
        });
      });
    });

    // Estilos para filas de datos
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber === 1) return; // Saltar encabezados

      row.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { horizontal: "center" };

        // Formato de moneda
        if ([10, 11, 12, 13, 14, 15, 16, 17].includes(colNumber)) {
          cell.numFmt = "$#,##0.00";
        }
      });
    });

    // Sección de resumen analítico
    worksheet.addRow([]);
    worksheet.addRow(["Resumen Analítico"]);
    const resumenRow = worksheet.getRow(worksheet.lastRow.number);
    resumenRow.font = { bold: true, size: 14 };
    resumenRow.alignment = { horizontal: "left" };

    worksheet.addRow(["Métricas Generales"]);
    const metricsHeader = worksheet.getRow(worksheet.lastRow.number);
    metricsHeader.font = { bold: true };

    const metrics = [
      ["Total Pedidos", totalPedidos],
      ["Pedidos Pagados", pedidosPagados],
      ["Pedidos Pendientes", pedidosPendientes],
      ["Total Ventas", totalVentas],
      ["Total Ganancias", totalGanancias],
      [
        "Promedio Venta",
        totalPedidos > 0 ? (totalVentas / totalPedidos).toFixed(2) : 0,
      ],
      [
        "Promedio Ganancia",
        totalPedidos > 0 ? (totalGanancias / totalPedidos).toFixed(2) : 0,
      ],
    ];

    metrics.forEach((metric) => {
      const metricRow = worksheet.addRow(metric);
      metricRow.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        if (colNumber === 1) {
          cell.font = { bold: true };
          cell.alignment = { horizontal: "left" };
        } else {
          cell.numFmt = "$#,##0.00";
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Reporte_Avanzado_Pedidos_${format(new Date(), "dd-MM-yyyy")}.xlsx`
    );
  };

  // Funciones de estado
  const getStatusIcon = (order) => {
    if (order.cancelado)
      return <XCircleIcon className="h-5 w-5 text-red-500" />;
    if (order.entregado)
      return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
    if (order.totalAbonado >= calculateTotal(order.productos)) {
      return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
    }
    return <ClockIcon className="h-5 w-5 text-orange-500" />;
  };

  const getStatusText = (order) => {
    if (order.cancelado) return "Cancelado";
    if (order.totalAbonado >= calculateTotal(order.productos)) {
      return "Pagado";
    }
    if (order.entregado) return "Entregado";
    return "Pendiente";
  };

  const getStatusClass = (order) => {
    if (order.cancelado) return "bg-red-100 text-red-800";
    if (order.entregado) return "bg-green-100 text-green-800";
    if (order.totalAbonado >= calculateTotal(order.productos)) {
      return "bg-green-100 text-green-800";
    }
    return "bg-orange-100 text-orange-800";
  };

  const handleDeliveryChange = async (orderId, isDelivered) => {
    try {
      const order = orders.find((o) => o.id === orderId);
      if (!order) return;

      const totalAmount = calculateTotal(order.productos);
      const isPaid = order.totalAbonado >= totalAmount;

      // Only update fields that exist in the schema
      const { error } = await supabase
        .from("orders")
        .update({
          entregado: isDelivered,
          fechaEntrega: isDelivered ? new Date().toISOString() : null,
        })
        .eq("id", orderId);

      if (error) throw error;

      if (typeof onOrderCreated === "function") {
        await onOrderCreated();
      }

      toast.success(
        isPaid
          ? "Pedido pagado completamente"
          : isDelivered
          ? "Pedido marcado como entregado"
          : "Estado de entrega actualizado"
      );
    } catch (error) {
      console.error("Error updating delivery status:", error);
      toast.error("Error al actualizar el estado de entrega");
    }
  };

  // Componentes de Paginación y Tarjeta de Orden

  const Pagination = () => (
    <div className="flex items-center justify-center px-4 py-3 sm:px-6">
      {/* Previous and Next Buttons for Mobile */}
      <div className="flex justify-center space-x-4 sm:hidden">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md ${
            darkMode
              ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
              : "bg-white text-gray-700 hover:bg-gray-50"
          } ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          Anterior
        </button>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md ${
            darkMode
              ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
              : "bg-white text-gray-700 hover:bg-gray-50"
          } ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Siguiente
        </button>
      </div>

      {/* Desktop Pagination */}
      <div className="hidden sm:flex sm:flex-col sm:items-center sm:justify-center space-y-4">
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border text-sm font-medium ${
                darkMode
                  ? "bg-gray-700 border-gray-600 text-gray-200 hover:bg-gray-600"
                  : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
              } ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (number) => (
                <button
                  key={number}
                  onClick={() => setCurrentPage(number)}
                  className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                    currentPage === number
                      ? darkMode
                        ? "bg-gray-600 text-white"
                        : "bg-orange-50 text-orange-600 border-orange-500"
                      : darkMode
                      ? "bg-gray-700 border-gray-600 text-gray-200 hover:bg-gray-600"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                  }`}
                >
                  {number}
                </button>
              )
            )}
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border text-sm font-medium ${
                darkMode
                  ? "bg-gray-700 border-gray-600 text-gray-200 hover:bg-gray-600"
                  : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
              } ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </nav>
        </div>
        <div>
          <p
            className={`text-sm ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Mostrando{" "}
            <span className="font-medium">{indexOfFirstOrder + 1}</span> a{" "}
            <span className="font-medium">
              {Math.min(indexOfLastOrder, orders.length)}
            </span>{" "}
            de <span className="font-medium">{orders.length}</span> resultados
          </p>
        </div>
      </div>
    </div>
  );

  // Mobile card component
  const OrderCard = ({ order }) => (
    <motion.div
      onClick={() => onOrderClick(order)}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.98 }}
      className={`p-4 rounded-xl mb-4 cursor-pointer ${
        darkMode ? "bg-gray-700" : "bg-white"
      } shadow-lg border ${darkMode ? "border-gray-600" : "border-gray-200"}`}
    >
      {/* Header - Cliente */}
      <div className="mb-4">
        <h3
          className={`text-lg font-semibold mb-1 ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          {order.clienteNombre}
        </h3>
        <p
          className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-500"}`}
        >
          {format(new Date(order.fecha), "d 'de' MMMM 'de' yyyy", {
            locale: es,
          })}
        </p>
      </div>
  
      {/* Estado y Checkbox */}
      <div className="flex items-center justify-between mb-4">
        <div
          className={`py-2 px-3 rounded-lg inline-flex items-center gap-2 ${
            darkMode ? "bg-gray-600/50" : "bg-gray-50"
          }`}
        >
          {getStatusIcon(order)}
          <span
            className={`px-2.5 py-1 rounded-full text-xs font-medium ${getStatusClass(
              order
            )}`}
          >
            {getStatusText(order)}
          </span>
        </div>
  
        {/* Checkbox de entrega */}
        <div onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            checked={order.entregado}
            disabled={order.totalAbonado >= calculateTotal(order.productos)}
            onChange={(e) => handleDeliveryChange(order.id, e.target.checked)}
            className={`h-4 w-4 rounded border-gray-300 
              ${darkMode 
                ? "text-orange-500 focus:ring-orange-600 bg-gray-700" 
                : "text-orange-600 focus:ring-orange-500"
              }
              ${order.totalAbonado >= calculateTotal(order.productos) 
                ? "opacity-50 cursor-not-allowed" 
                : ""
              }
            `}
          />
        </div>
      </div>

      {/* Productos Preview */}
      <div className="mb-4">
        <div className="flex items-center gap-3">
          <div className="flex -space-x-3">
            {order.productos.slice(0, 3).map((producto, index) => (
              <img
                key={index}
                src={producto.imagen}
                alt={producto.nombre}
                className={`h-10 w-10 rounded-lg object-cover ring-2 ${
                  darkMode ? "ring-gray-700" : "ring-white"
                }`}
              />
            ))}
          </div>
          <span
            className={`text-sm ${
              darkMode ? "text-gray-400" : "text-gray-500"
            }`}
          >
            {order.productos.length} producto
            {order.productos.length !== 1 ? "s" : ""}
          </span>
        </div>
      </div>

      {/* Total */}
      <div className={`flex justify-between items-center pt-3 border-t ${
      darkMode ? "border-gray-600" : "border-gray-200"
    }`}>
        <span
          className={`text-sm font-medium ${
            darkMode ? "text-gray-400" : "text-gray-500"
          }`}
        >
          Total del Pedido
        </span>
        <span
          className={`text-lg font-bold ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          {formatToCOP(calculateTotal(order.productos))}
        </span>
      </div>
    </motion.div>
  );

  return (
    <div>
      {/* Botones de encabezado responsivos */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6 space-y-4 md:space-y-0">
        {/* Botón Exportar a Excel */}
        {isStandardPlan ? (
          <button
            onClick={handleExportToExcel}
            className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <FaFileExcel className="h-5 w-5 mr-2" />
            Exportar a Excel
          </button>
        ) : (
          <div className="flex flex-col items-center md:flex-row md:items-center">
            <button
              disabled
              className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md opacity-50 cursor-not-allowed"
            >
              <FaFileExcel className="h-5 w-5 mr-2" />
              Exportar a Excel
            </button>
            <div className="flex items-center mt-2 md:mt-0 md:ml-4 text-sm text-red-500">
              <span>
                Esta funcionalidad está disponible solo para usuarios con el
                plan <strong>Standard</strong>. ¡Actualiza tu plan para
                habilitarla!
              </span>
            </div>
          </div>
        )}

        {/* Botón Nuevo Pedido */}
        <motion.button
          type="button"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => setShowNewOrderModal(true)}
          className={`flex items-center justify-center px-4 py-2 rounded-lg ${
            darkMode
              ? "bg-orange-500 hover:bg-orange-600"
              : "bg-orange-600 hover:bg-orange-700"
          } text-white shadow-sm`}
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Nuevo Pedido
        </motion.button>
      </div>

      {/* Modal para nuevo pedido */}
      <OrderModalPedidos
        isOpen={showNewOrderModal}
        onClose={() => setShowNewOrderModal(false)}
        onSubmit={handleCreateOrder}
      />

      {/* Vista Móvil */}
      <div className="md:hidden">
        <div className="space-y-3">
          {currentOrders.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
        </div>
        <Pagination />
      </div>

      {/* Vista de Escritorio */}
      <div className="hidden md:block">
        <div
          className={`shadow-sm rounded-lg overflow-hidden ${
            darkMode ? "bg-gray-800" : "bg-white"
          }`}
        >
          <div className="overflow-x-auto">
            <table
              className={`min-w-full divide-y ${
                darkMode
                  ? "divide-gray-700"
                  : "divide-gray-200"
              }`}
            >
              <thead className={darkMode ? "bg-gray-900" : "bg-gray-50"}>
                <tr>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Cliente
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Fecha
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Total
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Productos
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Entregado
                  </th>
                </tr>
              </thead>
              <tbody
                className={`divide-y ${
                  darkMode
                    ? "divide-gray-700 bg-gray-800"
                    : "divide-gray-200 bg-white"
                }`}
              >
                {currentOrders.map((order) => (
                  <motion.tr
                    key={order.id}
                    onClick={() => onOrderClick(order)}
                    whileHover={{
                      backgroundColor: darkMode
                        ? "rgba(255, 255, 255, 0.05)"
                        : "rgba(0, 0, 0, 0.02)",
                    }}
                    className="cursor-pointer transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {order.clienteNombre}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {format(
                          new Date(order.fecha),
                          "d 'de' MMMM 'de' yyyy",
                          { locale: es }
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {formatToCOP(calculateTotal(order.productos))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {getStatusIcon(order)}
                        <span
                          className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(
                            order
                          )}`}
                        >
                          {getStatusText(order)}
                        </span>
                      </div>
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex -space-x-2 overflow-hidden">
                        {order.productos.slice(0, 3).map((producto, index) => (
                          <img
                            key={index}
                            className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                            src={producto.imagen}
                            alt={producto.nombre}
                          />
                        ))}
                        {order.productos.length > 3 && (
                          <div
                            className={`flex items-center justify-center h-8 w-8 rounded-full ring-2 ${
                              darkMode
                                ? "bg-gray-600 ring-gray-700"
                                : "bg-gray-100 ring-white"
                            }`}
                          >
                            <span
                              className={`text-xs font-medium ${
                                darkMode ? "text-gray-300" : "text-gray-500"
                              }`}
                            >
                              +{order.productos.length - 3}
                            </span>
                          </div>
                        )}
                      </div>
                    </td>

                    {/* Asegúrate de usar <td> en lugar de <div> */}
                    <td className="px-6 py-4">
                      <div 
                        className={`mb-4  ${
                          darkMode ? "border-gray-600" : "border-gray-200"
                        } pt-4`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={order.entregado}
                            disabled={order.totalAbonado >= calculateTotal(order.productos)}
                            onChange={(e) => handleDeliveryChange(order.id, e.target.checked)}
                            className={`h-4 w-4 rounded border-gray-300 
                              ${darkMode 
                                ? "text-orange-500 focus:ring-orange-600 bg-gray-700" 
                                : "text-orange-600 focus:ring-orange-500"
                              }
                              ${order.totalAbonado >= calculateTotal(order.productos) 
                                ? "opacity-50 cursor-not-allowed" 
                                : ""
                              }
                            `}
                          />
                          <span className={`ml-2 text-sm ${
                            order.totalAbonado >= calculateTotal(order.productos)
                              ? "text-gray-400"
                              : darkMode ? "text-gray-300" : "text-gray-500"
                          }`}>
                            {order.fechaEntrega
                              ? format(
                                  new Date(order.fechaEntrega),
                                  "d 'de' MMMM 'de' yyyy 'a las' h:mm aa",
                                  { locale: es }
                                )
                              : "No entregado"}
                          </span>
                        </div>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </div>
    </div>
  );
}