import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import { fetchOrders, updateOrder } from "../services/supabaseService";
import { Toaster, toast } from 'react-hot-toast';
import Layout from "./dashboard/Layout";
import OrdersTable from "./orders/OrdersTable";
import OrderModal from "./orders/OrderModal";
import { useTheme } from '../context/ThemeContext';

function Pedidos() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { darkMode } = useTheme();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Administrador",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        navigate("/login");
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Administrador",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
      } else {
        setUser(null);
        setIsAdmin(false);
        navigate("/login");
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    if (user) {
      loadOrders();
    }
  }, [user]);

  const loadOrders = async () => {
    try {
      if (user) {
        const ordersData = await fetchOrders(user.id);
        setOrders(ordersData || []);
      }
    } catch (error) {
      console.error("Error loading orders:", error);
      toast.error("Error al cargar los pedidos");
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); 
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Pedidos", href: "/pedidos", current: true },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: false },
          {
            name: "Gestionar Suscripciones",
            href: "/admin/subscriptions",
            current: false,
          },
        ]
      : []),
  ];

  const formatToCOP = (number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(number)
      .replace("COP", "")
      .trim();
  };

  const handleAddAbono = async (orderId, monto) => {
    try {
      const order = orders.find((o) => o.id === orderId);
      if (order) {
        const montoAbono = parseInt(monto.replace(/\D/g, ""), 10);
        const nuevoAbono = {
          fecha: new Date().toISOString(),
          monto: montoAbono,
        };
        const nuevosAbonos = [...(order.abonos || []), nuevoAbono];
        const totalAbonado = nuevosAbonos.reduce(
          (total, abono) => total + parseFloat(abono.monto),
          0
        );

        await updateOrder(orderId, {
          ...order,
          abonos: nuevosAbonos,
          totalAbonado,
        });

        await loadOrders();
        toast.success("Abono agregado exitosamente");
      }
    } catch (error) {
      console.error("Error al agregar abono:", error);
      toast.error("Error al agregar el abono");
    }
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
        <main className="py-10">
          <div className="">
            <div className="mb-8">
              <h1 className={`text-3xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                Pedidos
              </h1>
              <p className={`mt-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Gestiona y da seguimiento a todos tus pedidos
              </p>
            </div>

            <OrdersTable
      orders={orders}
      onOrderClick={(order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
      }}
      formatToCOP={formatToCOP}
      onOrderCreated={loadOrders} 
      userId={user?.id}
    />

            <OrderModal
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setSelectedOrder(null);
              }}
              order={selectedOrder}
              onAddAbono={handleAddAbono}
              formatToCOP={formatToCOP}
            />
          </div>
        </main>
      </Layout>
      <Toaster position="top-right" />
    </div>
  );
}

export default Pedidos;