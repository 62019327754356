import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo.svg'; // Asegúrate de que la ruta es correcta

function SharedFooter() {
  const NEQUI_NUMBER = '3112900929';

  return (
    <footer className="bg-gray-900 text-white py-12">
    <div className="container mx-auto px-6">
      {/* Grid Layout */}
      <div className="grid md:grid-cols-4 gap-8 items-start">
        {/* Logo Section */}
        <div className="flex flex-col items-center">
        <Link to="/">
  <img src={Logo} alt="Mi aliada" className="h-56 mb-4" />
</Link>

          <p className="text-gray-400 text-center max-w-xs">
            Transformando la gestión de consultoras de belleza
          </p>
        </div>

        {/* Contact Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Contacto</h4>
          <p className="text-gray-400">
            WhatsApp: {NEQUI_NUMBER}
            <br />
            Email: soporte@mialiada.com
          </p>
        </div>

        {/* Links Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Enlaces</h4>
          <ul className="space-y-2 text-gray-400">
            <li>
              <Link
                to="/about"
                className="hover:text-orange-500 transition-colors duration-200"
              >
                Sobre Nosotros
              </Link>
            </li>
            <li>
              <Link
                to="/privacy"
                className="hover:text-orange-500 transition-colors duration-200"
              >
                Política de Privacidad
              </Link>
            </li>
            <li>
              <Link
                to="/terms"
                className="hover:text-orange-500 transition-colors duration-200"
              >
                Términos de Servicio
              </Link>
            </li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Síguenos</h4>
          <div className="flex space-x-4">
            {/* Redes sociales con estilo circular */}
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
              aria-label="Facebook"
            >
              <svg
                className="w-5 h-5 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 12c0-5.522-4.478-10-10-10S2 6.478 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987H7.898v-2.89h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562v1.875h2.773l-.443 2.89h-2.33v6.987C18.343 21.128 22 16.991 22 12z" />
              </svg>
            </a>

            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
              aria-label="Twitter"
            >
              <svg
                className="w-5 h-5 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.46 6c-.77.35-1.6.58-2.46.69a4.3 4.3 0 001.88-2.39 8.59 8.59 0 01-2.72 1.04 4.28 4.28 0 00-7.3 3.9A12.15 12.15 0 013 4.89a4.28 4.28 0 001.33 5.72 4.26 4.26 0 01-1.94-.54v.05a4.28 4.28 0 003.44 4.19 4.3 4.3 0 01-1.93.07 4.28 4.28 0 004 2.98A8.6 8.6 0 012 19.54a12.14 12.14 0 006.56 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.19 0-.39-.01-.58A8.72 8.72 0 0024 5.59a8.47 8.47 0 01-2.54.7z" />
              </svg>
            </a>

            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
              aria-label="Instagram"
            >
              <svg
                className="fill-current w-20 h-20"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.445 5h-8.891A6.559 6.559 0 0 0 5 11.554v8.891A6.559 6.559 0 0 0 11.554 27h8.891a6.56 6.56 0 0 0 6.554-6.555v-8.891A6.557 6.557 0 0 0 20.445 5zm4.342 15.445a4.343 4.343 0 0 1-4.342 4.342h-8.891a4.341 4.341 0 0 1-4.341-4.342v-8.891a4.34 4.34 0 0 1 4.341-4.341h8.891a4.342 4.342 0 0 1 4.341 4.341l.001 8.891z" />
                <path d="M16 10.312c-3.138 0-5.688 2.551-5.688 5.688s2.551 5.688 5.688 5.688 5.688-2.551 5.688-5.688-2.55-5.688-5.688-5.688zm0 9.163a3.475 3.475 0 1 1-.001-6.95 3.475 3.475 0 0 1 .001 6.95zM21.7 8.991a1.363 1.363 0 1 1-1.364 1.364c0-.752.51-1.364 1.364-1.364z" />
              </svg>
            </a>
            {/* LinkedIn */}
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="w-10 h-10 bg-gray-800 flex items-center justify-center rounded-full text-gray-400 hover:bg-orange-500 hover:text-white transition-colors duration-200"
              aria-label="LinkedIn"
            >
              <svg
                className="w-5 h-5 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.98 3.5C4.98 4.88 3.88 6 2.5 6S0.02 4.88 0.02 3.5 1.12 1 2.5 1 4.98 2.12 4.98 3.5zM0 24V7h5.01v17H0zM7.5 7h4.8v2.418h.07c.67-1.27 2.32-2.6 4.78-2.6 5.13 0 6.08 3.38 6.08 7.77V24h-5.01v-7.883c0-2.23-.04-5.09-3.1-5.09-3.1 0-3.58 2.42-3.58 4.91V24H7.5z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400 flex flex-col items-center">
        <p>&copy; 2024 Mi aliada. Todos los derechos reservados.</p>
        <a
          href="#top"
          className="mt-4 flex items-center text-orange-500 hover:text-orange-600 transition-colors duration-300"
        >
          <svg
            className="w-5 h-5 mr-2 transform hover:rotate-180 transition-transform duration-300"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l5 5a1 1 0 11-1.414 1.414L10 5.414 5.707 9.707a1 1 0 11-1.414-1.414l5-5A1 1 0 0110 3z"
              clipRule="evenodd"
            />
          </svg>
          Volver arriba
        </a>
      </div>
    </div>
  </footer>
  );
}

export default SharedFooter;