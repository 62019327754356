import { supabase } from './supabaseClient'; 


export const fetchClients = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('clients')
      .select('*')
      .eq('user_id', userId);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};

export const fetchOrders = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .eq('user_id', userId);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

export const fetchProducts = async () => {
  try {
    const { data, error } = await supabase
      .from('products')
      .select('*');
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const addClient = async (userId, clientInfo) => { // Renamed parameter to clientInfo
  try {
    // Check user subscription and limits
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('subscription, monthly_client_count')
      .eq('id', userId)
      .single();

    if (userError) throw userError;

    const subscription = userData?.subscription;
    const monthlyClientCount = userData?.monthly_client_count || 0;

    // Check admin status
    const { data: { session } } = await supabase.auth.getSession();
    const isAdmin = session?.user?.email === 'tamayodaza9@gmail.com';

    if (!isAdmin) {
      // Verify subscription status
      const isActive = subscription?.verified && 
                      subscription?.endDate && 
                      new Date(subscription.endDate) > new Date();

      if (!isActive) {
        throw new Error('Suscripción no activa');
      }

      // Check client limit for basic plan
      if (subscription.planId === 'basic' && monthlyClientCount >= 10) {
        throw new Error('Has alcanzado el límite mensual de clientes para el plan básico');
      }
    }

    // Insert client
    const { data: newClient, error: clientError } = await supabase
      .from('clients')
      .insert([{ ...clientInfo, user_id: userId }]);

    if (clientError) throw clientError;

    // Update monthly client count for basic plan
    if (!isAdmin && subscription?.planId === 'basic') {
      const { error: updateError } = await supabase
        .from('users')
        .update({ monthly_client_count: monthlyClientCount + 1 })
        .eq('id', userId);

      if (updateError) throw updateError;
    }

    return newClient;
  } catch (error) {
    console.error("Error adding client: ", error);
    throw error;
  }
};

export const updateClient = async (clientId, clientData) => {
  try {
    
    const { data: client, error: clientError } = await supabase
      .from('clients')
      .select('user_id')
      .eq('id', clientId)
      .single();

    if (clientError) throw clientError;

    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('subscription')
      .eq('id', client.user_id)
      .single();

    if (userError) throw userError;

    
    const { data: { session } } = await supabase.auth.getSession();
    const isAdmin = session?.user?.email === 'tamayodaza9@gmail.com';

    if (!isAdmin) {
      const subscription = userData?.subscription;
      
      
      const isActive = subscription?.verified && 
                      subscription?.endDate && 
                      new Date(subscription.endDate) > new Date();

      if (!isActive || subscription?.planId === 'basic') {
        throw new Error('Tu plan no permite editar clientes');
      }
    }

    const { data, error } = await supabase
      .from('clients')
      .update(clientData)
      .eq('id', clientId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating client: ", error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .update(orderData)
      .eq('id', orderId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating order: ", error);
    throw error;
  }
};

export const addOrder = async (userId, orderData) => {
  try {
   
    const total = orderData.productos.reduce(
      (sum, producto) => sum + (producto.precio * producto.cantidad), 
      0
    );
    
   
    const totalProfit = orderData.productos.reduce(
      (sum, producto) => sum + (producto.profit * producto.cantidad),
      0
    );

    const { data, error } = await supabase
      .from('orders')
      .insert([{ ...orderData, user_id: userId }]);


    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error adding order:", error);
    throw error;
  }
};


export const deleteClient = async (clientId) => {
  try {
    
    const { data: client, error: clientError } = await supabase
      .from('clients')
      .select('user_id')
      .eq('id', clientId)
      .single();

    if (clientError) throw clientError;

    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('subscription')
      .eq('id', client.user_id)
      .single();

    if (userError) throw userError;


    const { data: { session } } = await supabase.auth.getSession();
    const isAdmin = session?.user?.email === 'tamayodaza9@gmail.com';

    if (!isAdmin) {
      const subscription = userData?.subscription;
      
      
      const isActive = subscription?.verified && 
                      subscription?.endDate && 
                      new Date(subscription.endDate) > new Date();

      if (!isActive || subscription?.planId === 'basic') {
        throw new Error('Tu plan no permite eliminar clientes');
      }
    }

    const { data, error } = await supabase
      .from('clients')
      .delete()
      .eq('id', clientId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting client: ", error);
    throw error;
  }
};

export const deleteOrders = async (orderIds) => {
  try {
    const { data, error } = await supabase
      .from('orders')
      .delete()
      .in('id', orderIds);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting orders: ", error);
    throw error;
  }
};

export const addProduct = async (id, name, imageUrl) => {
  try {
    // Verificar si el producto ya existe
    const { data: existingProduct, error: fetchError } = await supabase
      .from('products')
      .select('*')
      .eq('id', id)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      throw fetchError;
    }

    if (existingProduct) {
      // Si el producto ya existe, actualizarlo
      const { data, error } = await supabase
        .from('products')
        .update({ name, imageUrl })
        .eq('id', id);
      
      if (error) throw error;
      return data;
    } else {
      // Si el producto no existe, insertarlo
      const { data, error } = await supabase
        .from('products')
        .insert([{ id, name, imageUrl }]);
      
      if (error) throw error;
      return data;
    }
  } catch (error) {
    console.error("Error adding product: ", error);
    throw error;
  }
};

export const updateProduct = async (productId, productData) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .update(productData)
      .eq('id', productId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating product: ", error);
    throw error;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .delete()
      .eq('id', productId);
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error deleting product: ", error);
    throw error;
  }
};