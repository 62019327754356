import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

export function OrderHistoryModal({ isOpen, onClose, client, orders, darkMode }) {
    if (!isOpen || !client) return null;

    // Filtrar los pedidos que corresponden al cliente actual
    const clientOrders = orders.filter(order => order.clienteId === client.id);

    const calculateTotal = (productos) => {
        return productos.reduce((total, producto) =>
            total + (producto.precio * producto.cantidad), 0
        );
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`w-full max-w-4xl transform overflow-hidden rounded-2xl p-6 shadow-xl transition-all ${darkMode ? 'bg-gray-800' : 'bg-white'
                                    }`}
                            >
                                <div className="flex items-center justify-between mb-6">
                                    <div>
                                        <Dialog.Title className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                            Historial de pedidos
                                        </Dialog.Title>
                                        <p className={`mt-1 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                            Cliente: {client?.nombre}
                                        </p>
                                    </div>
                                    <button
                                        onClick={onClose}
                                        className={`rounded-full p-2 transition-colors ${darkMode
                                            ? 'hover:bg-gray-700 text-gray-400'
                                            : 'hover:bg-gray-100 text-gray-500'
                                            }`}
                                    >
                                        <XMarkIcon className="h-6 w-6" />
                                    </button>
                                </div>

                                <div className="mt-4 space-y-6">
                                    {clientOrders?.length > 0 ? (
                                        clientOrders.map((order) => (
                                            <div
                                                key={order.id}
                                                className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-orange-50'
                                                    }`}
                                            >
                                                <div className="flex justify-between items-start mb-4">
                                                    <div>
                                                        <p className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                                            Pedido #{order.id}
                                                        </p>
                                                        <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                                            {new Date(order.fecha).toLocaleDateString('es-ES', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric'
                                                            })}
                                                        </p>
                                                    </div>
                                                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${order.entregado
                                                        ? 'bg-green-100 text-green-800'
                                                        : order.totalAbonado >= calculateTotal(order.productos)
                                                            ? 'bg-blue-100 text-blue-800'
                                                            : 'bg-yellow-100 text-yellow-800'
                                                        }`}>
                                                        {order.entregado ? 'Entregado' :
                                                            order.totalAbonado >= calculateTotal(order.productos) ? 'Pagado' : 'Pendiente'}
                                                    </span>
                                                </div>

                                                <div className="overflow-x-auto">
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr className={`border-b ${darkMode ? 'border-gray-600' : 'border-gray-200'}`}>
                                                                <th className={`py-2 text-left text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'
                                                                    }`}>Producto</th>
                                                                <th className={`py-2 text-right text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'
                                                                    }`}>Cantidad</th>
                                                                <th className={`py-2 text-right text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'
                                                                    }`}>Precio</th>
                                                                <th className={`py-2 text-right text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'
                                                                    }`}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {order.productos.map((producto) => (
                                                                <tr key={producto.id}>
                                                                    <td className={`py-3 ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                                                        <div className="flex items-center space-x-3">
                                                                            <img
                                                                                src={producto.imagen || '/catalogo.jpg'}
                                                                                alt={producto.nombre}
                                                                                className="h-10 w-10 rounded-lg object-cover"
                                                                            />
                                                                            <span>{producto.nombre}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className={`py-3 text-right ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                                                        {producto.cantidad}
                                                                    </td>
                                                                    <td className={`py-3 text-right ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                                                        {new Intl.NumberFormat('es-CO', {
                                                                            style: 'currency',
                                                                            currency: 'COP',
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 0
                                                                        }).format(producto.precio)}
                                                                    </td>
                                                                    <td className={`py-3 text-right ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                                                        {new Intl.NumberFormat('es-CO', {
                                                                            style: 'currency',
                                                                            currency: 'COP',
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 0
                                                                        }).format(producto.precio * producto.cantidad)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr className={`border-t ${darkMode ? 'border-gray-600' : 'border-gray-200'}`}>
                                                                <td colSpan="3" className={`py-3 text-right font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'
                                                                    }`}>Total del pedido:</td>
                                                                <td className={`py-3 text-right font-bold ${darkMode ? 'text-white' : 'text-gray-900'
                                                                    }`}>
                                                                    {new Intl.NumberFormat('es-CO', {
                                                                        style: 'currency',
                                                                        currency: 'COP',
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 0
                                                                    }).format(calculateTotal(order.productos))}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={`text-center py-8 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                                            No hay pedidos para mostrar
                                        </div>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}