import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Wallet } from "@mercadopago/sdk-react";
import { createPreference } from "../services/mercadoPagoService";
import { supabase } from "../supabaseConfig";
import { Toaster, toast } from 'react-hot-toast';
import { motion } from "framer-motion";
import { addMonths, addYears } from "date-fns";
import Layout from "./dashboard/Layout";
import { useTheme } from '../context/ThemeContext';

export const plans = {
  basic: {
    id: "basic",
    name: "Basic",
    monthly: 5000,
    sixMonth: 27000, 
    annual: 48000, 
    features: [
      "Límite de 10 registros de clientes",
      "Acceso básico al dashboard",
      "Soporte por correo electrónico",
      "Actualizaciones básicas",
    ],
    limitations: [
      "Sin exportación a Excel", 
      "Sin edición de clientes",
      "Sin eliminación de clientes",
      "Módulos del dashboard limitados",
    ],
  },
  standard: {
    id: "standard",
    name: "Standard", 
    monthly: 10000,
    sixMonth: 54000,
    annual: 96000,
    features: [
      "Registros ilimitados de clientes",
      "Exportación a Excel",
      "Gestión completa de clientes",
      "Acceso completo al dashboard", 
      "Soporte prioritario 24/7",
      "Actualizaciones premium",
      "Análisis avanzado de datos",
      "Reportes personalizados",
    ],
    recommended: true,
  },
};

export default function SubscriptionPlans() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [preferenceId, setPreferenceId] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { darkMode } = useTheme();

  React.useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        setUser({
          id: session.user.id,
          name: session.user.user_metadata?.name || "Usuario",
          email: session.user.email,
          imageUrl: session.user.user_metadata?.avatar_url || "/avatar.svg",
        });
      } else {
        navigate("/login");
      }
    };

    getSession();
  }, [navigate]);

  const getPriceWithCycle = (plan) => {
    switch (billingCycle) {
      case "sixMonth":
        return plan.sixMonth;
      case "annual":
        return plan.annual;
      default:
        return plan.monthly;
    }
  };

  const getSavingsPercentage = () => {
    switch (billingCycle) {
      case "sixMonth":
        return "10%";
      case "annual":
        return "20%";
      default:
        return "0%";
    }
  };

  const handlePlanSelect = async (plan) => {
    try {
      const selectedPlanDetails = {
        ...plan,
        price: getPriceWithCycle(plan),
        billingCycle,
      };
      setSelectedPlan(selectedPlanDetails);
      const preference = await createPreference(selectedPlanDetails);
      setPreferenceId(preference.id);

      const startDate = new Date();
      let endDate;

      // Calculate end date based on billing cycle
      switch (billingCycle) {
        case "sixMonth":
          endDate = addMonths(startDate, 6);
          break;
        case "annual":
          endDate = addYears(startDate, 1);
          break;
        default: // monthly
          endDate = addMonths(startDate, 1);
      }

      // Update user's subscription details
      const { error } = await supabase
        .from("users")
        .update({
          subscription: {
            planId: plan.id,
            planName: plan.name,
            billingCycle,
            price: getPriceWithCycle(plan),
            status: "pagando",
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        })
        .eq("id", user.id);

      if (error) throw error;
    } catch (error) {
      console.error("Error selecting plan:", error);
      toast.error("Error al seleccionar el plan");
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Suscripción", href: "/subscription-plans", current: true },
  ];

  const userNavigation = [
    { name: "Tu perfil", to: "/perfil" },
    { name: "Configuraciones", href: "#" },
    { name: "Cerrar sesión", onClick: handleLogout },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const planVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
      },
    },
  };

  const cycleButtonVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <div className={`min-h-screen ${darkMode 
      ? 'bg-gray-900 text-gray-100' 
      : 'bg-gradient-to-br from-orange-50 to-orange-100 text-gray-900'}`}>
      <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
        <motion.div
          className="py-8 px-4 sm:py-16 sm:px-6 lg:px-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="">
            <motion.div
              className="text-center mb-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h2 className={`text-3xl sm:text-4xl font-extrabold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                Planes de Suscripción
              </h2>
              <p className={`mt-4 text-lg ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Elige el plan perfecto para hacer crecer tu negocio
              </p>
            </motion.div>
  
            {/* Billing Cycle Selector */}
            <motion.div className="flex justify-center mb-12">
              <div className={`
                ${darkMode 
                  ? 'bg-gray-800 border border-gray-700' 
                  : 'bg-white'} 
                p-1.5 rounded-xl shadow-lg max-w-full overflow-x-auto`}>
                <div className="flex space-x-2">
                  {["monthly", "sixMonth", "annual"].map((cycle) => (
                    <motion.button
                      key={cycle}
                      variants={cycleButtonVariants}
                      whileHover="hover"
                      whileTap="tap"
                      onClick={() => setBillingCycle(cycle)}
                      className={`
                        ${billingCycle === cycle
                          ? "bg-orange-500 text-white shadow-md"
                          : darkMode
                            ? "text-gray-300 hover:bg-gray-700 hover:text-gray-100"
                            : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                        } px-4 py-2 rounded-lg text-sm font-medium transition-all min-w-[100px]
                      `}
                    >
                      <span className="whitespace-nowrap">
                        {cycle === "monthly"
                          ? "Mensual"
                          : cycle === "sixMonth"
                          ? "Semestral"
                          : "Anual"}
                      </span>
                      {cycle !== "monthly" && (
                        <motion.span
                          className="ml-2 inline-block bg-green-500 text-white text-xs px-2 py-0.5 rounded-full"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                        >
                          -{getSavingsPercentage()}
                        </motion.span>
                      )}
                    </motion.button>
                  ))}
                </div>
              </div>
            </motion.div>
  
            {/* Plans Grid */}
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {Object.entries(plans).map(([key, plan]) => (
                <motion.div
                  key={key}
                  variants={planVariants}
                  className={`relative 
                    ${darkMode 
                      ? 'bg-gray-800 border border-gray-700 text-gray-100' 
                      : 'bg-white text-gray-900'}
                    rounded-2xl shadow-xl overflow-hidden
                    ${plan.recommended ? "ring-2 ring-orange-500" : ""}`}
                >
                  {plan.recommended && (
                    <div className={`
                      absolute top-0 left-0 right-0 
                      ${darkMode ? 'bg-orange-600' : 'bg-orange-500'} 
                      text-white text-center py-2 text-sm font-semibold`}>
                      Plan Recomendado
                    </div>
                  )}
  
                  <div className={`px-6 py-8 ${plan.recommended ? "pt-14" : ""}`}>
                    <div className="text-center mb-8">
                      <h3 className={`text-2xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'} mb-4`}>
                        {plan.name}
                      </h3>
                      <div className="flex items-baseline justify-center">
                        <span className={`text-4xl font-extrabold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                          ${getPriceWithCycle(plan).toLocaleString("es-CO")}
                        </span>
                        <span className={`ml-2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          /
                          {billingCycle === "monthly"
                            ? "mes"
                            : billingCycle === "sixMonth"
                            ? "6 meses"
                            : "año"}
                        </span>
                      </div>
                      {billingCycle !== "monthly" && (
                        <p className={`mt-2 text-sm font-medium ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                          Ahorras {getSavingsPercentage()}
                        </p>
                      )}
                    </div>
  
                    <ul className="space-y-4 mb-8">
                      {plan.features.map((feature, index) => (
                        <motion.li
                          key={index}
                          className="flex items-start"
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: index * 0.1 }}
                        >
                          <svg
                            className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          <span className={`ml-3 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>{feature}</span>
                        </motion.li>
                      ))}
  
                      {plan.limitations?.map((limitation, index) => (
                        <motion.li
                          key={`limitation-${index}`}
                          className={`flex items-start ${darkMode ? 'text-gray-500' : 'text-gray-400'}`}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{
                            delay: (plan.features.length + index) * 0.1,
                          }}
                        >
                          <svg
                            className="h-5 w-5 text-red-500 mt-0.5 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="ml-3">{limitation}</span>
                        </motion.li>
                      ))}
                    </ul>
  
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => handlePlanSelect(plan)}
                      className={`
                        w-full py-4 px-6 rounded-xl 
                        font-medium transition-all shadow-lg
                        focus:outline-none focus:ring-2 
                        focus:ring-orange-500 focus:ring-offset-2
                        ${darkMode
                          ? 'bg-orange-600 text-white hover:bg-orange-700'
                          : 'bg-orange-600 text-white hover:bg-orange-700'}
                      `}
                    >
                      Seleccionar plan
                    </motion.button>
                  </div>
                </motion.div>
              ))}
            </motion.div>
  
            {preferenceId && (
              <motion.div
                className="mt-12 flex justify-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <Wallet
                  initialization={{ preferenceId }}
                  onReady={() => console.log("Wallet ready")}
                  onSubmit={() => console.log("Payment submitted")}
                  onError={(error) => console.error("Wallet error:", error)}
                />
              </motion.div>
            )}
          </div>
        </motion.div>
        <Toaster position="top-right" />
      </Layout>
    </div>
  );
}