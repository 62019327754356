import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTheme } from './../../context/ThemeContext';

export default function OrderDetails({ order, formatToCOP }) {
    const { darkMode } = useTheme();
    
    const calculateTotal = (productos) => {
      return productos.reduce(
        (acc, p) => acc + parseFloat(p.precio) * parseFloat(p.cantidad),
        0
      );
    };
  
    const calculateTotalProfit = (productos) => {
      return productos.reduce(
        (acc, p) => acc + parseFloat(p.profit) * parseFloat(p.cantidad),
        0
      );
    };
  
    // Mobile card view component
    const ProductCard = ({ producto }) => (
      <div className={`p-4 rounded-lg mb-4 ${darkMode ? 'bg-gray-700' : 'bg-white'} shadow-sm`}>
        <div className="flex items-center mb-3">
          <img
            src={producto.imagen}
            alt={producto.nombre}
            className="h-12 w-12 rounded-lg object-cover"
          />
          <div className="ml-3">
            <div className={`font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              {producto.nombre}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 text-sm">
          <div>
            <span className={`block ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Cantidad:</span>
            <span className={darkMode ? 'text-gray-300' : 'text-gray-700'}>{producto.cantidad}</span>
          </div>
          <div>
            <span className={`block ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Precio Unit:</span>
            <span className={darkMode ? 'text-gray-300' : 'text-gray-700'}>{formatToCOP(producto.precio)}</span>
          </div>
          <div>
            <span className={`block ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total:</span>
            <span className={darkMode ? 'text-gray-200' : 'text-gray-900'}>
              {formatToCOP(producto.precio * producto.cantidad)}
            </span>
          </div>
          <div>
            <span className={`block ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Ganancia:</span>
            <span className={darkMode ? 'text-green-400' : 'text-green-600'}>
              {formatToCOP(producto.profit * producto.cantidad)}
            </span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="space-y-6">
        {/* Header Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
            <h3 className={`text-sm font-medium mb-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Cliente</h3>
            <p className={`text-lg font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              {order.clienteNombre}
            </p>
          </div>
          <div className={`rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
            <h3 className={`text-sm font-medium mb-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Fecha del Pedido</h3>
            <p className={`text-lg font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              {format(new Date(order.fecha), "d 'de' MMMM 'de' yyyy", { locale: es })}
            </p>
          </div>
        </div>

        {/* Productos Section */}
        <div>
          <h3 className={`text-sm font-medium mb-4 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            Productos
          </h3>

          {/* Mobile View */}
          <div className="md:hidden">
            {order.productos.map((producto, index) => (
              <ProductCard key={index} producto={producto} />
            ))}
            <div className={`mt-6 rounded-lg p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className={`block text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total Pedido:</span>
                  <span className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                    {formatToCOP(calculateTotal(order.productos))}
                  </span>
                </div>
                <div>
                  <span className={`block text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Ganancia Total:</span>
                  <span className={`text-lg font-bold ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                    {formatToCOP(calculateTotalProfit(order.productos))}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Desktop View */}
          <div className="hidden md:block">
            <div className={`rounded-lg overflow-x-auto ${darkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
              <table className="w-full min-w-[800px]">
                <colgroup>
                  <col className="w-1/4" />
                  <col className="w-[10%]" />
                  <col className="w-[15%]" />
                  <col className="w-[15%]" />
                  <col className="w-[15%]" />
                  <col className="w-[15%]" />
                </colgroup>
                <thead className={darkMode ? 'bg-gray-700' : 'bg-gray-100'}>
                  <tr>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Producto
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Cantidad
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Precio Unit.
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Ganancia Unit.
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Total
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Ganancia Total
                    </th>
                  </tr>
                </thead>
                <tbody className={`divide-y ${darkMode ? 'divide-gray-700 bg-gray-800' : 'divide-gray-200 bg-white'}`}>
                  {order.productos.map((producto, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <img
                            src={producto.imagen}
                            alt={producto.nombre}
                            className="h-10 w-10 rounded-lg object-cover"
                          />
                          <div className="ml-4">
                            <div className={`text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                              {producto.nombre}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className={`px-6 py-4 text-sm ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        {producto.cantidad}
                      </td>
                      <td className={`px-6 py-4 text-sm ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        {formatToCOP(producto.precio)}
                      </td>
                      <td className={`px-6 py-4 text-sm ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                        {formatToCOP(producto.profit)}
                      </td>
                      <td className={`px-6 py-4 text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                        {formatToCOP(producto.precio * producto.cantidad)}
                      </td>
                      <td className={`px-6 py-4 text-sm font-medium ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                        {formatToCOP(producto.profit * producto.cantidad)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className={darkMode ? 'bg-gray-700' : 'bg-gray-50'}>
                  <tr>
                    <td colSpan="4" className={`px-6 py-4 text-right text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                      Totales:
                    </td>
                    <td className={`px-6 py-4 text-sm font-bold ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                      {formatToCOP(calculateTotal(order.productos))}
                    </td>
                    <td className={`px-6 py-4 text-sm font-bold ${darkMode ? 'text-green-400' : 'text-green-600'}`}>
                      {formatToCOP(calculateTotalProfit(order.productos))}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
}