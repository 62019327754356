import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import { Toaster, toast } from 'react-hot-toast';
import { useAuth } from "../context/AuthContext";
import {
  UserCircleIcon,
  KeyIcon,
  TrashIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Layout from "./dashboard/Layout";
import { useTheme } from '../context/ThemeContext';

function Perfil() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState("");
  const [newAvatarUrl, setNewAvatarUrl] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const { darkMode} = useTheme();

  useEffect(() => {
    if (currentUser) {
      const userData = {
        id: currentUser.id,
        name: currentUser.user_metadata?.name || "Administrador",
        email: currentUser.email,
        imageUrl: currentUser.user_metadata?.avatar_url || "/avatar.svg",
        phone: currentUser.user_metadata?.phone || "",
      };
      setUser(userData);
      setNewName(userData.name);
      setNewAvatarUrl(userData.imageUrl);
      setNewPhone(userData.phone);
      setIsAdmin(currentUser.email === "tamayodaza9@gmail.com");
    } else {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchPhone = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.user) {
          const { data, error } = await supabase
            .from('users')
            .select('phone')
            .eq('id', session.user.id)
            .single();

          if (error) throw error;
          setNewPhone(data.phone || '');
        }
      } catch (error) {
        console.error('Error fetching phone:', error);
      }
    };

    fetchPhone();
  }, []);

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer."
      )
    ) {
      const password = prompt(
        "Por favor, ingresa tu contraseña actual para confirmar la eliminación de la cuenta:"
      );
      if (!password) {
        toast.error("Se requiere la contraseña para eliminar la cuenta.");
        return;
      }
      setLoading(true);
      try {
        const { error: authError } = await supabase.auth.signInWithPassword({
          email: user.email,
          password: password,
        });
        if (authError) throw authError;

        const { error: deleteError } = await supabase.auth.admin.deleteUser(
          user.id
        );
        if (deleteError) throw deleteError;

        toast.success("Cuenta eliminada con éxito");
        navigate("/login");
      } catch (error) {
        console.error("Error al eliminar la cuenta:", error);
        toast.error("Error al eliminar la cuenta: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const handleSendPasswordResetEmail = async () => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(user.email, {
        redirectTo: "https://yanbal.vercel.app/reset-password",
      });
      if (error) throw error;
      toast.success(
        "Correo de restablecimiento de contraseña enviado con éxito."
      );
    } catch (error) {
      console.error(
        "Error al enviar el correo de restablecimiento de contraseña:",
        error
      );
      toast.error(
        "Error al enviar el correo de restablecimiento de contraseña: " +
          error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      if (!/^\d{10}$/.test(newPhone)) {
        toast.error("El número de celular debe tener 10 dígitos");
        return;
      }

      const updates = {
        data: {
          name: newName,
          avatar_url: newAvatarUrl,
          phone: newPhone,
        },
      };
      const { error } = await supabase.auth.updateUser(updates);
      if (error) throw error;
      toast.success("Perfil actualizado con éxito");
      setUser({
        ...user,
        name: newName,
        imageUrl: newAvatarUrl,
        phone: newPhone,
      });
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);
      toast.error("Error al actualizar el perfil: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: false },
          {
            name: "Gestionar Suscripciones",
            href: "/admin/subscriptions",
            current: false,
          },
        ]
      : []),
  ];

  const userNavigation = [
    { name: "Tu perfil", to: "/perfil" },
    {
      name: "Cerrar sesión",
      onClick: handleLogout 
    },
  ];

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

   return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
        <main className="py-10">
          <div className="">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl rounded-2xl overflow-hidden`}
            >
              {/* Header */}
              <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-orange-500 to-orange-600">
                <h3 className="text-2xl leading-6 font-bold text-white">
                  Perfil de Usuario
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-orange-100">
                  Gestiona tu información personal y configuraciones de cuenta
                </p>
              </div>
  
              <div className={`border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'} px-4 py-5 sm:p-6`}>
                {/* Profile Image and Basic Info */}
                <div className="flex flex-col sm:flex-row items-center mb-8 space-y-4 sm:space-y-0 sm:space-x-8">
                  <div className="relative group">
                    <img
                      src={user.imageUrl}
                      alt="Profile"
                      className="h-32 w-32 rounded-full object-cover border-4 border-white shadow-lg"
                    />
                    <div className="absolute inset-0 rounded-full bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                      <UserCircleIcon className="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <div className="text-center sm:text-left">
                    <h2 className={`text-3xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                      {user.name}
                    </h2>
                    <p className={`${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{user.email}</p>
                    {isAdmin && (
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-orange-100 text-orange-800 mt-2">
                        Administrador
                      </span>
                    )}
                  </div>
                </div>
  
                {/* Update Profile Form */}
                <div className={`${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-xl p-6 mb-8`}>
                  <h4 className={`text-xl font-semibold ${darkMode ? 'text-gray-100' : 'text-gray-900'} mb-4`}>
                    Actualizar Perfil
                  </h4>
                  <div className="grid grid-cols-1 gap-6">
                    <div>
                      <label className={`block text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                        Nombre
                      </label>
                      <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        className={`mt-1 block w-full rounded-md shadow-sm
                          ${darkMode 
                            ? 'bg-gray-600 border-gray-500 text-gray-100 focus:border-orange-500 focus:ring-orange-500' 
                            : 'border-gray-300 focus:border-orange-500 focus:ring-orange-500'
                          }`}
                      />
                    </div>
  
                    <div>
                      <label className={`block text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                        Celular
                      </label>
                      <input
                        type="tel"
                        pattern="[0-9]{10}"
                        value={newPhone}
                        onChange={(e) => setNewPhone(e.target.value)}
                        placeholder="3001234567"
                        className={`mt-1 block w-full rounded-md shadow-sm
                          ${darkMode 
                            ? 'bg-gray-600 border-gray-500 text-gray-100 focus:border-orange-500 focus:ring-orange-500' 
                            : 'border-gray-300 focus:border-orange-500 focus:ring-orange-500'
                          }`}
                      />
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleUpdateProfile}
                      disabled={loading}
                      className={`inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                        ${darkMode 
                          ? 'bg-orange-500 hover:bg-orange-600' 
                          : 'bg-orange-600 hover:bg-orange-700'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50`}
                    >
                      <ArrowPathIcon className="h-5 w-5 mr-2" />
                      {loading ? "Actualizando..." : "Actualizar Perfil"}
                    </motion.button>
                  </div>
                </div>
  
                {/* Security Section */}
                <div className={`${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-xl p-6 mb-8`}>
                  <h4 className={`text-xl font-semibold ${darkMode ? 'text-gray-100' : 'text-gray-900'} mb-4`}>
                    Seguridad
                  </h4>
                  <motion.button
                    whileHover={{ scale: loading ? 1 : 1.02 }}
                    whileTap={{ scale: loading ? 1 : 0.98 }}
                    onClick={handleSendPasswordResetEmail}
                    disabled={true} 
                    className={`
                      inline-flex items-center px-4 py-2 
                      border border-transparent rounded-md shadow-sm 
                      text-sm font-medium text-white
                      ${darkMode 
                        ? 'bg-blue-500 disabled:bg-blue-400' 
                        : 'bg-blue-600 disabled:bg-blue-400'
                      }
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                      disabled:cursor-not-allowed 
                      disabled:opacity-50
                      pointer-events-none
                      transition-all duration-200
                    `}
                  >
                    <KeyIcon className="h-5 w-5 mr-2 opacity-50" />
                    Cambiar Contraseña
                  </motion.button>
                </div>
  
                {/* Danger Zone */}
                <div className={`${darkMode ? 'bg-red-900/20' : 'bg-red-50'} rounded-xl p-6`}>
                  <h4 className={`text-xl font-semibold ${darkMode ? 'text-red-100' : 'text-red-900'} mb-4`}>
                    Zona de Peligro
                  </h4>
                  <p className={`text-sm ${darkMode ? 'text-red-300' : 'text-red-600'} mb-4`}>
                    Una vez que elimines tu cuenta, no hay vuelta atrás. Por favor, estás seguro.
                  </p>
                  <motion.button
                    whileHover={{ scale: 1 }} 
                    whileTap={{ scale: 1 }}    
                    onClick={handleDeleteAccount}
                    disabled={true}            
                    className={`
                      inline-flex items-center px-4 py-2 
                      border border-transparent rounded-md shadow-sm 
                      text-sm font-medium text-white
                      ${darkMode 
                        ? 'bg-red-500 disabled:bg-red-400' 
                        : 'bg-red-600 disabled:bg-red-400'
                      }
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                      disabled:cursor-not-allowed 
                      disabled:opacity-50
                      pointer-events-none
                      transition-all duration-200
                    `}
                  >
                    <TrashIcon className="h-5 w-5 mr-2 opacity-50" />
                    Eliminar Cuenta
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
        </main>
      </Layout>
      <Toaster position="top-right" />
    </div>
  );
}

export default Perfil;