import { useState, useEffect, useRef, useCallback } from 'react';
import { supabase } from '../supabaseConfig';
import { toast } from 'react-toastify';
import { addMonths, addYears } from 'date-fns';

export function useSubscription(props = {}) {
  const { onClose = () => {} } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [paymentReference, setPaymentReference] = useState('');
  const [counter, setCounter] = useState(10);
  const [expandedStep, setExpandedStep] = useState(null);
  const [planId, setPlanId] = useState(null); // Añadido para almacenar el plan

  const countdownId = useRef(null);
  const subscriptionListener = useRef(null);

  const clearTimers = useCallback(() => {
    if (countdownId.current) {
      clearInterval(countdownId.current);
      countdownId.current = null;
    }
  }, []);

  const getSubscriptionEndDate = (startDate, billingCycle) => {
    const start = new Date(startDate);
    switch (billingCycle) {
      case 'sixMonth':
        return addMonths(start, 6);
      case 'annual':
        return addYears(start, 1);
      default: // monthly
        return addMonths(start, 1);
    }
  };

  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const user = session?.user;
      if (!user) return;

      if (user.email === 'tamayodaza9@gmail.com') {
        setSubscriptionStatus('admin');
        setPlanId('admin'); // Asignar planId para administrador
        return;
      }

      const { data: userDoc, error } = await supabase
        .from('users')
        .select('subscription')
        .eq('id', user.id)
        .single();

      if (error) throw error;

      if (userDoc?.subscription) {
        const { status, verified, endDate, planId } = userDoc.subscription; // Asegúrate de que 'planId' exista

        setPlanId(planId); // Establecer el planId

        if (verified && endDate && new Date(endDate) > new Date()) {
          setSubscriptionStatus('activo');
          clearTimers();
        } else if (status === 'pendiente') {
          setSubscriptionStatus('pendiente');
        } else if (status === 'rechazado') {
          setSubscriptionStatus('rechazado');
          clearTimers();
        } else if (status === 'cancelado') {
          setSubscriptionStatus('cancelado');
          clearTimers();
        } else {
          setSubscriptionStatus('no_suscripcion');
          clearTimers();
        }
      } else {
        setSubscriptionStatus('no_suscripcion');
        clearTimers();
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setSubscriptionStatus('error');
      clearTimers();
    }
  }, [clearTimers]);

  const startTimers = useCallback(() => {
    clearTimers();

    if (subscriptionStatus === 'pendiente') {
      countdownId.current = setInterval(() => {
        setCounter((prev) => {
          if (prev <= 1) {
            fetchSubscriptionStatus();
            return 10;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [subscriptionStatus, clearTimers, fetchSubscriptionStatus]);

  useEffect(() => {
    fetchSubscriptionStatus();

    subscriptionListener.current = supabase
      .channel('subscription-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'users' },
        async (payload) => {
          const { data: { session } } = await supabase.auth.getSession();
          const user = session?.user;

          if (!user || payload.new?.id !== user.id) return;

          const subscription = payload.new?.subscription;

          if (!subscription) {
            setSubscriptionStatus('no_suscripcion');
            clearTimers();
            return;
          }

          const { status, verified, endDate } = subscription;

          if (verified && endDate && new Date(endDate) > new Date()) {
            setSubscriptionStatus('activo');
            clearTimers();
            onClose();
          } else if (status === 'pendiente') {
            setSubscriptionStatus('pendiente');
            startTimers();
          } else if (status === 'rechazado') {
            setSubscriptionStatus('rechazado');
            clearTimers();
          } else if (status === 'cancelado') {
            setSubscriptionStatus('cancelado');
            clearTimers();
          } else {
            setSubscriptionStatus('no_suscripcion');
            clearTimers();
          }
        }
      )
      .subscribe();

    return () => {
      clearTimers();
      if (subscriptionListener.current) {
        subscriptionListener.current.unsubscribe();
      }
    };
  }, [clearTimers, startTimers, onClose, fetchSubscriptionStatus]);

  useEffect(() => {
    if (subscriptionStatus === 'pendiente') {
      startTimers();
    }
  }, [subscriptionStatus, startTimers]);

  const handlePayment = async () => {
    if (!paymentReference) {
      toast.error('Por favor ingresa la referencia del pago');
      return;
    }

    try {
      setIsProcessing(true);
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.user) {
        toast.error('No se encontró una sesión activa');
        return;
      }

      // Get current subscription details
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('subscription')
        .eq('id', session.user.id)
        .single();

      if (userError) throw userError;

      const currentSubscription = userData?.subscription || {};
      const startDate = new Date().toISOString();
      const endDate = getSubscriptionEndDate(
        startDate,
        currentSubscription.billingCycle || 'monthly'
      ).toISOString();

      const { error } = await supabase
        .from('users')
        .update({
          subscription: {
            ...currentSubscription,
            paymentId: paymentReference,
            verified: false,
            requestDate: startDate,
            status: 'pendiente',
            startDate: startDate,
            endDate: endDate,
          },
        })
        .eq('id', session.user.id);

      if (error) throw error;

      toast.success('Referencia de pago enviada. El administrador verificará tu pago pronto.');
      setSubscriptionStatus('pendiente');
      startTimers();
    } catch (error) {
      console.error('Error updating subscription:', error);
      toast.error('Error al procesar la suscripción');
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    subscriptionStatus,
    paymentReference,
    setPaymentReference,
    counter,
    expandedStep,
    setExpandedStep,
    handlePayment,
    planId,
  };
}
