import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { supabase } from "../supabaseConfig";
import Layout from "./dashboard/Layout";
import PremiumPaywall from "./PremiumPaywall";
import UpgradeModal from "./UpgradeModal";
import { toast } from "react-hot-toast";
import {
  CreditCardIcon,
  ClockIcon,
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  ArrowPathIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  KeyIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useTheme } from "../context/ThemeContext";

function SubscriptionStatus() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [showPaywall, setShowPaywall] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // State to control UpgradeModal visibility
  const [monthlyClientCount, setMonthlyClientCount] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const navigate = useNavigate();
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const { darkMode } = useTheme();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error al cerrar sesión");
    }
  };

  const handleRenew = () => {
    setShowPaywall(true);
  };

  useEffect(() => {
    const fetchSubscriptionAndClients = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        if (session?.user) {
          // Fetch user data including subscription and monthly_client_count
          const { data: userData, error: userError } = await supabase
            .from("users")
            .select("subscription, monthly_client_count")
            .eq("id", session.user.id)
            .single();

          if (userError) throw userError;

          const { data: historyData, error: historyError } = await supabase
            .from("subscription_history")
            .select("*")
            .eq("user_id", session.user.id)
            .order("created_at", { ascending: false });

          if (!historyError) {
            setSubscriptionHistory(historyData || []);
          }

          // Fetch total number of clients
          const { data: clients, error: clientsError } = await supabase
            .from("clients")
            .select("id")
            .eq("user_id", session.user.id);

          if (clientsError) throw clientsError;

          setSubscription(userData?.subscription || null);
          setMonthlyClientCount(userData?.monthly_client_count || 0);
          setTotalClients(clients?.length || 0);
          setUser({
            id: session.user.id,
            name: session.user.user_metadata?.name || "Usuario",
            email: session.user.email,
            imageUrl: session.user.user_metadata?.avatar_url || "/avatar.svg",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionAndClients();
  }, []);

  const calculateRemainingDays = () => {
    if (!subscription?.endDate) return 0;
    const endDate = new Date(subscription.endDate);
    const today = new Date();
    return Math.max(0, Math.ceil((endDate - today) / (1000 * 60 * 60 * 24)));
  };

  const getStatusBadge = () => {
    const days = calculateRemainingDays();
    if (days > 10) {
      return {
        color: "bg-green-100 text-green-800",
        icon: CheckCircleIcon,
        text: "Activa",
      };
    }
    if (days > 5) {
      return {
        color: "bg-yellow-100 text-yellow-800",
        icon: ExclamationCircleIcon,
        text: "Por vencer",
      };
    }
    return {
      color: "bg-red-100 text-red-800",
      icon: XCircleIcon,
      text: "Vencida",
    };
  };

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: false },
    { name: "Pedidos", href: "/pedidos", current: false },
    { name: "Suscripción", href: "/subscription-status", current: true },
  ];

  if (loading) {
  }

  const StatusBadge = getStatusBadge();
  const isBasicPlan = subscription?.planId === "basic";
  const remainingClients = isBasicPlan
    ? Math.max(0, 10 - monthlyClientCount)
    : Infinity;

  return (
    <Layout user={user} navigation={navigation} handleLogout={handleLogout}>
      <main className={`py-10 ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}>
        <div className="">
          <div className="mb-8">
            <h1
              className={`text-3xl font-bold ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Estado de suscripción
            </h1>
            <p
              className={`mt-2 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
            >
              Gestiona y monitorea tu suscripción premium
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className={`${
                darkMode ? "bg-gray-800" : "bg-white"
              } rounded-xl shadow-lg overflow-hidden transition-colors duration-300`}
            >
              <div className="p-6">
                <h2
                  className={`text-xl font-semibold mb-4 ${
                    darkMode ? "text-gray-100" : "text-gray-900"
                  }`}
                >
                  Plan Actual
                </h2>
                <div className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <p
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Estado
                      </p>
                      <div className="flex items-center space-x-2">
                        <StatusBadge.icon
                          className={`h-5 w-5 ${
                            darkMode ? "text-gray-300" : ""
                          }`}
                        />
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${StatusBadge.color}`}
                        >
                          {StatusBadge.text}
                        </span>
                      </div>
                    </div>
                    <div className="text-right">
                      <p
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Días Restantes
                      </p>
                      <p
                        className={`text-2xl font-bold ${
                          darkMode ? "text-gray-100" : "text-gray-900"
                        }`}
                      >
                        {calculateRemainingDays()}
                      </p>
                    </div>
                  </div>

                  <div
                    className={`pt-6 border-t ${
                      darkMode ? "border-gray-700" : "border-gray-200"
                    }`}
                  >
                    {/* Plan details */}
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <CreditCardIcon
                          className={`h-5 w-5 mr-2 ${
                            darkMode ? "text-gray-500" : "text-gray-400"
                          }`}
                        />
                        <span
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-500"
                          }`}
                        >
                          Plan
                        </span>
                      </div>
                      <span
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {subscription?.planName || "No activo"}
                      </span>
                    </div>

                    {/* Billing cycle */}
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <ClockIcon
                          className={`h-5 w-5 mr-2 ${
                            darkMode ? "text-gray-500" : "text-gray-400"
                          }`}
                        />
                        <span
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-500"
                          }`}
                        >
                          Ciclo
                        </span>
                      </div>
                      <span
                        className={`text-sm font-medium ${
                          darkMode ? "text-gray-200" : "text-gray-900"
                        }`}
                      >
                        {subscription?.billingCycle === "sixMonth"
                          ? "Semestral"
                          : subscription?.billingCycle === "annual"
                          ? "Anual"
                          : "Mensual"}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <CalendarIcon
                        className={`h-5 w-5 mr-2 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      />
                      <span
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Fecha de inicio
                      </span>
                    </div>
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      {subscription?.startDate
                        ? new Date(subscription.startDate).toLocaleDateString(
                            "es-CO",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : "No disponible"}
                    </span>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <CalendarIcon
                        className={`h-5 w-5 mr-2 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      />
                      <span
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Fecha de vencimiento
                      </span>
                    </div>
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      {subscription?.endDate
                        ? new Date(subscription.endDate).toLocaleDateString(
                            "es-CO",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : "No disponible"}
                    </span>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <CurrencyDollarIcon
                        className={`h-5 w-5 mr-2 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      />
                      <span
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        Precio
                      </span>
                    </div>
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      ${subscription?.price?.toLocaleString("es-CO") || 0} COP
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <KeyIcon
                        className={`h-5 w-5 mr-2 ${
                          darkMode ? "text-gray-500" : "text-gray-400"
                        }`}
                      />
                      <span
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        ID Suscripción
                      </span>
                    </div>
                    <span
                      className={`text-sm font-medium ${
                        darkMode ? "text-gray-200" : "text-gray-900"
                      }`}
                    >
                      {subscription?.paymentId || "No disponible"}
                    </span>
                  </div>

                  {/* Plan usage stats */}
                  <div
                    className={`mt-6 pt-6 border-t ${
                      darkMode ? "border-gray-700" : "border-gray-200"
                    }`}
                  >
                    <h3
                      className={`text-sm font-medium mb-4 ${
                        darkMode ? "text-gray-100" : "text-gray-900"
                      }`}
                    >
                      Uso del plan
                    </h3>
                    <div className="space-y-4">
                      <div>
                        <div className="flex justify-between mb-1">
                          <div className="flex items-center">
                            <UserGroupIcon
                              className={`h-5 w-5 mr-2 ${
                                darkMode ? "text-gray-500" : "text-gray-400"
                              }`}
                            />
                            <span
                              className={`text-sm ${
                                darkMode ? "text-gray-400" : "text-gray-500"
                              }`}
                            >
                              Clientes registrados este mes
                            </span>
                          </div>
                          <span
                            className={`text-sm font-medium ${
                              darkMode ? "text-gray-200" : "text-gray-900"
                            }`}
                          >
                            {isBasicPlan
                              ? `${monthlyClientCount}/10`
                              : `${monthlyClientCount} (Ilimitado)`}
                          </span>
                        </div>
                        {isBasicPlan && (
                          <div
                            className={`w-full rounded-full h-2 ${
                              darkMode ? "bg-gray-700" : "bg-gray-200"
                            }`}
                          >
                            <motion.div
                              initial={{ width: 0 }}
                              animate={{
                                width: `${(monthlyClientCount / 10) * 100}%`,
                              }}
                              className={`h-2 rounded-full ${
                                darkMode ? "bg-orange-500" : "bg-orange-600"
                              }`}
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex justify-between mb-1">
                        <span
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-500"
                          }`}
                        >
                          Clientes restantes este mes
                        </span>
                        <span
                          className={`text-sm font-medium ${
                            darkMode ? "text-gray-200" : "text-gray-900"
                          }`}
                        >
                          {isBasicPlan ? remainingClients : "Ilimitado"}
                        </span>
                      </div>

                      <div className="flex justify-between mb-1">
                        <span
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-500"
                          }`}
                        >
                          Total de clientes registrados
                        </span>
                        <span
                          className={`text-sm font-medium ${
                            darkMode ? "text-gray-200" : "text-gray-900"
                          }`}
                        >
                          {totalClients}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  {calculateRemainingDays() <= 5 && (
                    <div className="mt-6">
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleRenew}
                        className={`w-full inline-flex items-center justify-center px-4 py-2 border border-transparent 
                            rounded-md shadow-sm text-sm font-medium text-white
                            ${
                              darkMode
                                ? "bg-orange-500 hover:bg-orange-600"
                                : "bg-orange-600 hover:bg-orange-700"
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500
                            ${darkMode && "focus:ring-offset-gray-800"}`}
                      >
                        <ArrowPathIcon className="h-5 w-5 mr-2" />
                        Renovar Suscripción
                      </motion.button>
                    </div>
                  )}

                  <div className="mt-6">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => setShowUpgradeModal(true)}
                      className={`w-full inline-flex items-center justify-center px-4 py-2 border border-transparent 
                          rounded-md shadow-sm text-sm font-medium text-white
                          ${
                            darkMode
                              ? "bg-orange-500 hover:bg-orange-600"
                              : "bg-orange-600 hover:bg-orange-700"
                          } transition-colors duration-200`}
                    >
                      <ArrowPathIcon className="h-5 w-5 mr-2" />
                      Actualizar a plan premium
                    </motion.button>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Features Card */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className={`${
                darkMode ? "bg-gray-800" : "bg-white"
              } rounded-xl shadow-lg overflow-hidden flex justify-center items-center transition-colors duration-300`}
            >
              <div className="p-6 w-full max-w-2xl">
                <h2
                  className={`text-2xl font-semibold mb-4 text-center ${
                    darkMode ? "text-gray-100" : "text-gray-900"
                  }`}
                >
                  Características del Plan {subscription?.planName || ""}
                </h2>

                {!subscription ? (
                  <div
                    className={`text-center py-4 ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    No tienes una suscripción activa
                  </div>
                ) : (
                  <ul className="space-y-6">
                    {subscription?.planId === "basic" ? (
                      <>
                        {[
                          "10 nuevos clientes por mes",
                          "Acceso básico al dashboard",
                          "Soporte por correo electrónico",
                          "Actualizaciones básicas",
                        ].map((feature, index) => (
                          <motion.li
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="flex items-center space-x-3 text-lg"
                          >
                            <CheckCircleIcon
                              className={`h-6 w-6 ${
                                darkMode ? "text-green-400" : "text-green-500"
                              }`}
                            />
                            <span
                              className={`${
                                darkMode ? "text-gray-300" : "text-gray-600"
                              }`}
                            >
                              {feature}
                            </span>
                          </motion.li>
                        ))}
                        {[
                          "Sin exportación a Excel",
                          "Sin edición de clientes",
                          "Sin eliminación de clientes",
                          "Módulos del dashboard limitados",
                        ].map((limitation, index) => (
                          <motion.li
                            key={`limitation-${index}`}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: (index + 4) * 0.1 }}
                            className="flex items-center space-x-3 text-lg"
                          >
                            <XCircleIcon
                              className={`h-6 w-6 ${
                                darkMode ? "text-red-400" : "text-red-500"
                              }`}
                            />
                            <span
                              className={`${
                                darkMode ? "text-gray-400" : "text-gray-500"
                              }`}
                            >
                              {limitation}
                            </span>
                          </motion.li>
                        ))}
                      </>
                    ) : (
                      <>
                        {[
                          "Registros ilimitados de clientes",
                          "Exportación a Excel",
                          "Gestión completa de clientes",
                          "Acceso completo al dashboard",
                          "Soporte prioritario 24/7",
                          "Actualizaciones premium",
                          "Análisis avanzado de datos",
                          "Reportes personalizados",
                        ].map((feature, index) => (
                          <motion.li
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="flex items-center space-x-3 text-lg"
                          >
                            <CheckCircleIcon
                              className={`h-6 w-6 ${
                                darkMode ? "text-green-400" : "text-green-500"
                              }`}
                            />
                            <span
                              className={`${
                                darkMode ? "text-gray-300" : "text-gray-600"
                              }`}
                            >
                              {feature}
                            </span>
                          </motion.li>
                        ))}
                      </>
                    )}
                  </ul>
                )}
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-8 lg:col-span-2"
            >
              <div
                className={`${
                  darkMode ? "bg-gray-800" : "bg-white"
                } rounded-xl shadow-lg overflow-hidden transition-colors duration-300`}
              >
                <div className="p-6">
                  <h2
                    className={`text-xl font-semibold mb-6 ${
                      darkMode ? "text-gray-100" : "text-gray-900"
                    }`}
                  >
                    Historial de suscripciones
                  </h2>

                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead
                        className={darkMode ? "bg-gray-700" : "bg-gray-50"}
                      >
                        <tr>
                          <th
                            scope="col"
                            className={`px-6 py-3 text-left text-xs font-medium ${
                              darkMode ? "text-gray-300" : "text-gray-500"
                            } uppercase tracking-wider`}
                          >
                            Fecha
                          </th>
                          <th
                            scope="col"
                            className={`px-6 py-3 text-left text-xs font-medium ${
                              darkMode ? "text-gray-300" : "text-gray-500"
                            } uppercase tracking-wider`}
                          >
                            Plan
                          </th>
                          <th
                            scope="col"
                            className={`px-6 py-3 text-left text-xs font-medium ${
                              darkMode ? "text-gray-300" : "text-gray-500"
                            } uppercase tracking-wider`}
                          >
                            Precio
                          </th>
                          <th
                            scope="col"
                            className={`px-6 py-3 text-left text-xs font-medium ${
                              darkMode ? "text-gray-300" : "text-gray-500"
                            } uppercase tracking-wider`}
                          >
                            Estado
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className={`divide-y ${
                          darkMode ? "divide-gray-700" : "divide-gray-200"
                        }`}
                      >
                        {[
                          {
                            date: new Date().toLocaleDateString(),
                            plan: subscription?.planName || "Plan Básico",
                            price:
                              subscription?.price?.toLocaleString("es-CO") ||
                              "0",
                            status: "Activo",
                          },
                          // Add more history items here
                        ].map((item, index) => (
                          <motion.tr
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className={darkMode ? "bg-gray-800" : "bg-white"}
                          >
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-sm ${
                                darkMode ? "text-gray-300" : "text-gray-900"
                              }`}
                            >
                              {item.date}
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-sm ${
                                darkMode ? "text-gray-300" : "text-gray-900"
                              }`}
                            >
                              {item.plan}
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-sm ${
                                darkMode ? "text-gray-300" : "text-gray-900"
                              }`}
                            >
                              ${item.price} COP
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span
                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                    ${
                      item.status === "Activo"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                              >
                                {item.status}
                              </span>
                            </td>
                          </motion.tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </main>

      {showPaywall && <PremiumPaywall onClose={() => setShowPaywall(false)} />}
      {showUpgradeModal && (
        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
          userId={user?.id}
        />
      )}
    </Layout>
  );
}

export default SubscriptionStatus;
