import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ChartBarIcon, UserGroupIcon, ClockIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { motion } from 'framer-motion';

export function ProductDetailsModal({ isOpen, onClose, product, orders = [], darkMode }) {
  if (!isOpen || !product) return null;

  // Filtrar órdenes que contienen este producto
  const productOrders = orders.filter(order => 
    order.productos.some(p => p.id === product.id)
  );

  // Calcular clientes únicos y recurrentes
  const clientStats = productOrders.reduce((acc, order) => {
    if (!acc[order.clienteId]) {
      acc[order.clienteId] = {
        clientName: order.clienteNombre,
        purchases: 1
      };
    } else {
      acc[order.clienteId].purchases++;
    }
    return acc;
  }, {});

  const getRecurringClientsList = () => {
    return Object.entries(clientStats)
      .filter(([_, client]) => client.purchases > 1)
      .map(([clientId, client]) => {
        // Encontrar la última compra del cliente
        const lastOrder = productOrders
          .filter(order => order.clienteId === clientId)
          .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))[0];
  
        return {
          id: clientId,
          name: client.clientName,
          purchases: client.purchases,
          lastPurchase: lastOrder.fecha
        };
      });
  };

  const totalClients = Object.keys(clientStats).length;
  const recurringClients = Object.values(clientStats).filter(client => client.purchases > 1).length;
  const recurringPercentage = totalClients > 0 ? Math.round((recurringClients / totalClients) * 100) : 0;
  // Calcular distribución de ventas por hora
  const salesByTime = productOrders.reduce((acc, order) => {
    const hour = new Date(order.fecha).getHours();
    let timeOfDay;
    
    if (hour >= 0 && hour < 12) {
      timeOfDay = 'Mañana';
    } else if (hour >= 12 && hour < 18) {
      timeOfDay = 'Tarde';
    } else {
      timeOfDay = 'Noche';
    }

    acc[timeOfDay] = (acc[timeOfDay] || 0) + order.productos
      .filter(p => p.id === product.id)
      .reduce((sum, p) => sum + p.cantidad, 0);
    
    return acc;
  }, { Mañana: 0, Tarde: 0, Noche: 0 });

  const totalSales = Object.values(salesByTime).reduce((a, b) => a + b, 0);
  const timePercentages = Object.entries(salesByTime).reduce((acc, [key, value]) => {
    acc[key] = totalSales ? Math.round((value / totalSales) * 100) : 0;
    return acc;
  }, {});

  const StatCard = ({ title, value, icon: Icon, trend }) => (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <Icon className={`h-5 w-5 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
          <h3 className={`text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {title}
          </h3>
        </div>
        {trend && (
          <span className={`flex items-center text-xs font-medium ${
            trend > 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            <ArrowTrendingUpIcon 
              className={`h-3 w-3 mr-1 ${trend < 0 ? 'transform rotate-180' : ''}`}
            />
            {trend > 0 ? '+' : ''}{trend}%
          </span>
        )}
      </div>
      <p className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
        {value}
      </p>
    </div>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        </Transition.Child>
  
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel 
                className={`w-full max-w-2xl transform overflow-hidden rounded-2xl p-4 sm:p-6 shadow-xl transition-all ${
                  darkMode ? 'bg-gray-800' : 'bg-white'
                }`}
              >
                {/* Header */}
                <div className="flex flex-col sm:flex-row items-start justify-between mb-6 gap-4">
                  <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                    <img
                      src={product.imagen || '/catalogo.jpg'}
                      alt={product.nombre}
                      className="w-full sm:w-24 h-32 sm:h-24 rounded-lg object-cover shadow-lg"
                    />
                    <div>
                      <Dialog.Title className={`text-xl sm:text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {product.nombre}
                      </Dialog.Title>
                      <p className={`mt-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        ID: {product.id}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={onClose}
                    className={`absolute top-2 right-2 rounded-full p-2 transition-colors ${
                      darkMode 
                        ? 'hover:bg-gray-700 text-gray-400' 
                        : 'hover:bg-gray-100 text-gray-500'
                    }`}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
  
                {/* Stats Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <StatCard
                    title="Ventas Totales"
                    value={product.sales}
                    icon={ChartBarIcon}
                    trend={product.growthPercentage}
                  />
                  <StatCard
                    title="Clientes Recurrentes"
                    value={`${recurringClients} (${recurringPercentage}%)`}
                    icon={UserGroupIcon}
                    trend={null}
                  />
                </div>
  
                {/* Clientes Recurrentes */}
                <div className={`rounded-lg p-4 sm:p-6 mt-6 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                      <UserGroupIcon className={`h-5 w-5 ${darkMode ? 'text-orange-400' : 'text-orange-500'}`} />
                      <h3 className={`font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                        Clientes Recurrentes ({getRecurringClientsList().length})
                      </h3>
                    </div>
                  </div>
  
                  {getRecurringClientsList().length > 0 ? (
                    <div className="space-y-4">
                      {getRecurringClientsList().map(client => (
                        <motion.div 
                          key={client.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          className={`p-4 rounded-xl transition-all duration-200 hover:shadow-lg ${
                            darkMode ? 'bg-gray-600 hover:bg-gray-500/50' : 'bg-white hover:bg-gray-50'
                          }`}
                        >
                          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                            <div className="flex items-center gap-4">
                              <div className="relative">
                                <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-orange-400 to-orange-600 flex items-center justify-center text-white text-lg font-bold shadow-lg">
                                  {client.name.charAt(0).toUpperCase()}
                                </div>
                                <div className="absolute -bottom-1 -right-1 bg-green-500 text-white text-xs font-medium px-2 py-0.5 rounded-full">
                                  {client.purchases}x
                                </div>
                              </div>
                              <div>
                                <h4 className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                  {client.name}
                                </h4>
                                <div className="flex items-center gap-2 mt-1">
                                  <ClockIcon className={`h-4 w-4 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                                  <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                                    Última: {new Date(client.lastPurchase).toLocaleDateString('es-ES', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric'
                                    })}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={`text-sm font-medium px-3 py-1 rounded-full w-fit ${
                              darkMode ? 'bg-orange-400/10 text-orange-400' : 'bg-orange-50 text-orange-600'
                            }`}>
                              Cliente Frecuente
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  ) : (
                    <div className={`text-center py-8 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      <UserGroupIcon className="h-12 w-12 mx-auto mb-3 opacity-50" />
                      <p className="font-medium">No hay clientes recurrentes aún</p>
                      <p className="text-sm mt-1">Los clientes aparecerán aquí cuando realicen más de una compra</p>
                    </div>
                  )}
                </div>
  
                {/* Distribución de Ventas */}
                <div className={`rounded-lg p-4 mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                  <h3 className={`text-sm font-medium mb-4 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    Distribución de Ventas
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {Object.entries(timePercentages).map(([time, percentage]) => (
                      <div key={time} 
                        className={`p-3 rounded-lg flex sm:flex-col items-center justify-between sm:text-center ${
                          darkMode ? 'bg-gray-600' : 'bg-white'
                        }`}
                      >
                        <p className={`text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                          {time}
                        </p>
                        <p className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                          {percentage}%
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}