// src/constants/landingData.js

export const testimonials = [
  {
    name: "María González",
    role: "Consultora independiente",
    avatar: "/avatars/maria.jpg",
    content: "Mi aliada ha transformado completamente la manera en que manejo mi negocio. Ahora puedo dar seguimiento a mis clientes y pedidos de forma profesional."
  },
  {
    name: "Ana Martínez", 
    role: "Líder de ventas",
    avatar: "/avatars/ana.jpg",
    content: "La facilidad para gestionar mi inventario y dar seguimiento a mis pedidos es increíble. Mis ventas han aumentado un 40% desde que empecé a usar Mi aliada."  
  },
  {
    name: "Carolina Pérez",
    role: "Emprendedora", 
    avatar: "/avatars/carolina.jpg",
    content: "El soporte al cliente es excepcional y las características de la plataforma son justo lo que necesitaba para hacer crecer mi negocio."
  }
];

export const faqs = [
  {
    question: "¿Cómo funciona el período de prueba gratuito?",
    answer: "Ofrecemos 14 días de prueba gratuita con acceso completo a todas las funcionalidades. No requiere tarjeta de crédito para comenzar."
  },
  {
    question: "¿Puedo cambiar de plan en cualquier momento?", 
    answer: "Sí, puedes actualizar o cambiar tu plan en cualquier momento. Los cambios se reflejarán en tu próximo ciclo de facturación."
  },
  {
    question: "¿Qué métodos de pago aceptan?",
    answer: "Aceptamos pagos con tarjetas de crédito/débito, transferencias bancarias y Nequi."
  },
  {
    question: "¿Ofrecen capacitación para usar la plataforma?",
    answer: "Sí, ofrecemos sesiones de capacitación gratuitas, videos tutoriales y documentación detallada para ayudarte a aprovechar al máximo la plataforma."
  },
  {
    question: "¿Puedo cancelar mi suscripción en cualquier momento?",
    answer: "Sí, puedes cancelar tu suscripción en cualquier momento sin cargos adicionales. Podrás seguir usando el servicio hasta el final del período facturado."
  }
];

export const features = [
  {
    step: '01',
    title: 'Gestión Inteligente de Clientes',
    description: 'Organiza y gestiona tu cartera de clientes de manera eficiente',
    details: [
      'Perfiles detallados de clientes',
      'Historial de compras y preferencias',
      'Recordatorios automáticos',
      'Segmentación de clientes'
    ],
    image: '/features/clients-dashboard.svg'
  },
  {
    step: '02',
    title: 'Dashboard Analítico Avanzado',
    description: 'Visualiza y analiza el rendimiento de tu negocio en tiempo real',
    details: [
      'Métricas clave en tiempo real',
      'Gráficos interactivos',
      'Tendencias de ventas',
      'Análisis predictivo'
    ],
    image: '/features/analytics-dashboard.svg'
  },
  {
    step: '03',
    title: 'Gestión de Pedidos y Catálogo',
    description: 'Administra tus pedidos y catálogo de productos sin complicaciones',
    details: [
      'Procesamiento de pedidos',
      'Control de inventario',
      'Catálogo digital',
      'Seguimiento de envíos'
    ],
    image: '/features/orders-dashboard.svg'
  }
];