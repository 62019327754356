import React, { useState, useEffect } from "react";
import {
  addClient,
  deleteClient,
  fetchClients,
  updateClient,
} from "../services/supabaseService";
import { usePlanFeatures } from "../hooks/usePlanFeatures";
import { Disclosure } from "@headlessui/react";
import {
  UserIcon,
  PhoneIcon,
  MapPinIcon,
  PencilIcon,
  TrashIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseConfig";
import { Toaster, toast } from 'react-hot-toast';
import Layout from "./dashboard/Layout";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from '../context/ThemeContext';


function ClientForm() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    telefono: "",
    direccion: "",
  });
  const [errors, setErrors] = useState({});
  const [clientes, setClientes] = useState([]);
  const [editingClientId, setEditingClientId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [showTooltip, setShowTooltip] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { darkMode } = useTheme();
  const {
    canAddClients,
    canEditClients,
    canDeleteClients,
    monthlyClientLimit,
    remainingClients,
  } = usePlanFeatures();

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const userData = {
          id: session.user.id,
          name: session.user.user_metadata.name || "Administrador",
          email: session.user.email,
          imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
        };
        setUser(userData);
        setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
        loadClients(userData.id);
      } else {
        navigate("/login");
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          const userData = {
            id: session.user.id,
            name: session.user.user_metadata.name || "Administrador",
            email: session.user.email,
            imageUrl: session.user.user_metadata.avatar_url || "/avatar.svg",
          };
          setUser(userData);
          setIsAdmin(session.user.email === "tamayodaza9@gmail.com");
          loadClients(userData.id);
        } else {
          setUser(null);
          setIsAdmin(false);
          navigate("/login");
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const navigation = [
    { name: "Inicio", href: "/inicio", current: false },
    { name: "Clientes", href: "/clientes", current: true },
    { name: "Pedidos", href: "/pedidos", current: false },
    ...(!isAdmin
      ? [{ name: "Suscripción", href: "/subscription-status", current: false }]
      : []),
    ...(isAdmin
      ? [
          { name: "Productos", href: "/productos", current: false },
          {
            name: "Gestionar Suscripciones",
            href: "/admin/subscriptions",
            current: false,
          },
        ]
      : []),
  ];
  
  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/'); 
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const userNavigation = [
    { name: "Tu perfil", to: "/perfil" },
    { name: "Configuraciones", href: "#" },
    { name: "Cerrar sesión", onClick: handleLogout },
  ];

  const loadClients = async (userId) => {
    if (userId) {
      const fetchedClients = await fetchClients(userId);
      setClientes(fetchedClients);
      setFilteredClientes(fetchedClients);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nombre.trim()) {
      newErrors.nombre = "El nombre es requerido";
    }
    if (!formData.telefono.trim()) {
      newErrors.telefono = "El teléfono es requerido";
    } else if (!/^\d{10}$/.test(formData.telefono.trim())) {
      newErrors.telefono = "El teléfono debe tener 10 dígitos";
    }
    if (!formData.direccion.trim()) {
      newErrors.direccion = "La dirección es requerida";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    const filtered = clientes.filter(
      (cliente) =>
        cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.telefono.includes(searchTerm) ||
        cliente.direccion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClientes(filtered);
  }, [searchTerm, clientes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!canAddClients) {
      toast.error("Tu plan no permite agregar clientes");
      return;
    }

    if (monthlyClientLimit !== Infinity && remainingClients <= 0) {
      toast.error("Has alcanzado el límite mensual de clientes para tu plan");
      return;
    }

    setIsSubmitting(true);
    try {
      if (editingClientId) {
        if (!canEditClients) {
          toast.error("Tu plan no permite editar clientes");
          return;
        }
        await updateClient(editingClientId, {
          ...formData,
          userId: user.id,
        });
        toast.success("Cliente actualizado exitosamente");
      } else {
        await addClient(user.id, formData);
        toast.success("Cliente agregado exitosamente");
      }

      const updatedClients = await fetchClients(user.id);
      setClientes(updatedClients);
      resetForm();
    } catch (error) {
      console.error("Error al procesar el cliente:", error);
      toast.error("Error al procesar el cliente: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (clientId) => {
    if (!canDeleteClients) {
      toast.error("Tu plan no permite eliminar clientes");
      return;
    }

    if (window.confirm("¿Estás seguro de que deseas eliminar este cliente?")) {
      try {
        await deleteClient(clientId);
        const updatedClients = await fetchClients(user.id);
        setClientes(updatedClients);
        toast.success("Cliente eliminado exitosamente");
      } catch (error) {
        toast.error("Error al eliminar el cliente: " + error.message);
      }
    }
  };

  const handleEdit = (client) => {
    setEditingClientId(client.id);
    setFormData({
      nombre: client.nombre,
      telefono: client.telefono,
      direccion: client.direccion || "",
    });
  };

  const resetForm = () => {
    setFormData({
      nombre: "",
      telefono: "",
      direccion: "",
    });
    setEditingClientId(null);
    setErrors({});
  };

  const tooltips = {
    nombre: "Ingresa el nombre completo del cliente",
    telefono: "Ingresa un número de teléfono de 10 dígitos",
    direccion:
      "Ingresa la dirección completa incluyendo ciudad y código postal",
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <Layout user={user} navigation={navigation} handleLogout={handleLogout}>


        <main className="py-10">
        <div className="">
            <div className="mb-8">
              <h1 className={`text-3xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                Clientes
              </h1>
              <p className={`mt-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Gestiona y administra tu cartera de clientes
              </p>
            </div>
            </div>
        <div className="">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-8 mb-8`}
          >
            <h2 className={`text-2xl font-semibold ${darkMode ? 'text-gray-100' : 'text-gray-900'} mb-6`}>
              {editingClientId ? "Actualizar cliente" : "Agregar nuevo cliente"}
            </h2>
              {monthlyClientLimit !== Infinity && (
                <div className="mb-4 p-4 bg-orange-100 rounded-lg">
                  <p className="text-orange-800">
                    Clientes restantes este mes: {remainingClients} de{" "}
                    {monthlyClientLimit}
                  </p>
                </div>
              )}
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                  {/* Nombre Field */}
                  <div className="relative">
                    <label
                      htmlFor="nombre"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Nombre completo *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserIcon className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleInputChange}
                        className={`block w-full pl-10 pr-10 py-2 rounded-md 
                ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'}
                ${errors.nombre
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : darkMode
                    ? 'text-gray-100 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                    : 'text-gray-900 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                }`}
                        placeholder="Ej: María González"
                        onFocus={() => setShowTooltip("nombre")}
                        onBlur={() => setShowTooltip("")}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-gray-400 cursor-help"
                          onMouseEnter={() => setShowTooltip("nombre")}
                          onMouseLeave={() => setShowTooltip("")}
                        />
                      </div>
                    </div>
                    <AnimatePresence>
                      {(showTooltip === "nombre" || errors.nombre) && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className={`mt-1 text-sm ${
                            errors.nombre ? "text-red-600" : "text-gray-500"
                          }`}
                        >
                          {errors.nombre || tooltips.nombre}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Teléfono Field */}
                  <div className="relative">
                    <label
                      htmlFor="telefono"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Teléfono *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PhoneIcon className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="tel"
                        id="telefono"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleInputChange}
                        className={`block w-full pl-10 pr-10 py-2 rounded-md 
                ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'}
                ${errors.nombre
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : darkMode
                    ? 'text-gray-100 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                    : 'text-gray-900 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                }`}
                        placeholder="Ej: 3001234567"
                        onFocus={() => setShowTooltip("telefono")}
                        onBlur={() => setShowTooltip("")}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-gray-400 cursor-help"
                          onMouseEnter={() => setShowTooltip("telefono")}
                          onMouseLeave={() => setShowTooltip("")}
                        />
                      </div>
                    </div>
                    <AnimatePresence>
                      {(showTooltip === "telefono" || errors.telefono) && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className={`mt-1 text-sm ${
                            errors.telefono ? "text-red-600" : "text-gray-500"
                          }`}
                        >
                          {errors.telefono || tooltips.telefono}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Dirección Field */}
                  <div className="relative md:col-span-2">
                    <label
                      htmlFor="direccion"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Dirección *
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MapPinIcon className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        id="direccion"
                        name="direccion"
                        value={formData.direccion}
                        onChange={handleInputChange}
                        className={`block w-full pl-10 pr-10 py-2 rounded-md 
                ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'}
                ${errors.nombre
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : darkMode
                    ? 'text-gray-100 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                    : 'text-gray-900 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                }`}
                        placeholder="Ej: Calle 123 #45-67, Barrio, Ciudad"
                        onFocus={() => setShowTooltip("direccion")}
                        onBlur={() => setShowTooltip("")}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-gray-400 cursor-help"
                          onMouseEnter={() => setShowTooltip("direccion")}
                          onMouseLeave={() => setShowTooltip("")}
                        />
                      </div>
                    </div>
                    <AnimatePresence>
                      {(showTooltip === "direccion" || errors.direccion) && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className={`mt-1 text-sm ${
                            errors.direccion ? "text-red-600" : "text-gray-500"
                          }`}
                        >
                          {errors.direccion || tooltips.direccion}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-4 pt-4">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={isSubmitting}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Procesando...
                      </>
                    ) : editingClientId ? (
                      "Actualizar cliente"
                    ) : (
                      "Agregar cliente"
                    )}
                  </motion.button>
                  {editingClientId && (
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                      onClick={resetForm}
                      className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      Cancelar edición
                    </motion.button>
                  )}
                </div>
              </form>
            </motion.div>

            {/* Lista de clientes */}
            <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow overflow-hidden sm:rounded-lg`}>
              <div className={`px-4 py-5 sm:px-6 flex flex-col sm:flex-row justify-between items-start sm:items-center ${
                darkMode ? 'border-gray-700' : 'border-gray-200'
              }`}>
                <h2 className={`text-lg leading-6 font-medium ${darkMode ? 'text-gray-100' : 'text-gray-900'} mb-2 sm:mb-0`}>
                  Lista de clientes
                </h2>
                <div className="w-full sm:w-auto relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Buscar clientes..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`block w-full pl-10 pr-10 py-2 rounded-md 
                ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'}
                ${errors.nombre
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : darkMode
                    ? 'text-gray-100 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                    : 'text-gray-900 placeholder-gray-400 focus:ring-orange-500 focus:border-orange-500'
                }`}
                  />
                </div>
              </div>

              <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className={darkMode ? 'bg-gray-700' : 'bg-gray-50'}>
                    <tr>
                    <th className={`px-6 py-3 text-left text-xs font-medium ${
                      darkMode ? 'text-gray-300' : 'text-gray-500'
                    } uppercase tracking-wider`}>
                      Nombre
                    </th>
                    <th className={`px-6 py-3 text-left text-xs font-medium ${
                      darkMode ? 'text-gray-300' : 'text-gray-500'
                    } uppercase tracking-wider`}>
                        Teléfono
                      </th>
                      <th className={`px-6 py-3 text-left text-xs font-medium ${
                      darkMode ? 'text-gray-300' : 'text-gray-500'
                    } uppercase tracking-wider`}>
                        Dirección
                      </th>
                      <th className={`px-6 py-3 text-left text-xs font-medium ${
                      darkMode ? 'text-gray-300' : 'text-gray-500'
                    } uppercase tracking-wider`}>
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className={`${darkMode ? 'bg-gray-800' : 'bg-white'} divide-y ${
                  darkMode ? 'divide-gray-700' : 'divide-gray-200'
                }`}>
                    {filteredClientes.map((cliente) => (
                      <motion.tr
                        key={cliente.id}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}
                      >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <div className={`h-10 w-10 rounded-full ${
                              darkMode ? 'bg-orange-900' : 'bg-orange-100'
                            } flex items-center justify-center`}>
                              <span className={`${
                                darkMode ? 'text-orange-400' : 'text-orange-600'
                              } font-medium text-lg`}>
                                {cliente.nombre.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          </div>
                          <div className="ml-4">
                            <div className={`text-sm font-medium ${
                              darkMode ? 'text-gray-100' : 'text-gray-900'
                            }`}>
                              {cliente.nombre}
                            </div>
                          </div>
                        </div>
                      </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                        <div className={`text-sm font-medium ${
                              darkMode ? 'text-gray-100' : 'text-gray-900'
                            }`}>
                            {cliente.telefono}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                        <div className={`text-sm font-medium ${
                              darkMode ? 'text-gray-100' : 'text-gray-900'
                            }`}>
                            {cliente.direccion}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                          <div className="flex justify-center space-x-2">
                            <motion.button
                              whileHover={{ scale: canEditClients ? 1.1 : 1 }}
                              whileTap={{ scale: canEditClients ? 0.9 : 1 }}
                              onClick={() => handleEdit(cliente)}
                              disabled={!canEditClients}
                              className={`text-orange-600 hover:text-orange-900 ${!canEditClients ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                              <PencilIcon className="h-5 w-5" />
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: canDeleteClients ? 1.1 : 1 }}
                              whileTap={{ scale: canDeleteClients ? 0.9 : 1 }}
                              onClick={() => handleDelete(cliente.id)}
                              disabled={!canDeleteClients}
                              className={`text-red-600 hover:text-red-900 ${!canDeleteClients ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                              <TrashIcon className="h-5 w-5" />
                            </motion.button>
                          </div>
                        </td>
                      </motion.tr>
                    ))}
                    {filteredClientes.length === 0 && (
                      <tr>
                        <td
                          colSpan="4"
                          className="px-6 py-4 text-center text-gray-500"
                        >
                          No se encontraron clientes
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </Layout>
      <Toaster position="top-right" />
    </div>
  );
}

export default ClientForm;
