import { useState, useEffect } from 'react';
import { supabase } from '../supabaseConfig';

export function usePlanFeatures() {
  const [planFeatures, setPlanFeatures] = useState({
    canAddClients: false,
    canEditClients: false,
    canDeleteClients: false,
    canExportExcel: false,
    hasFullDashboard: false,
    hasAdvancedAnalytics: false,
    monthlyClientLimit: 0,
    remainingClients: 0
  });

  useEffect(() => {
    const checkPlanFeatures = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) return;

        
        if (session.user.email === 'tamayodaza9@gmail.com') {
          setPlanFeatures({
            canAddClients: true,
            canEditClients: true,
            canDeleteClients: true,
            canExportExcel: true,
            hasFullDashboard: true,
            hasAdvancedAnalytics: true,
            monthlyClientLimit: Infinity,
            remainingClients: Infinity
          });
          return;
        }

        const { data: userData, error } = await supabase
          .from('users')
          .select('subscription, monthly_client_count')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;

        const subscription = userData?.subscription;
        const monthlyClientCount = userData?.monthly_client_count || 0;

        if (subscription?.verified && 
            subscription?.endDate && 
            new Date(subscription.endDate) > new Date()) {
          
          if (subscription.planId === 'basic') {
            setPlanFeatures({
              canAddClients: true,
              canEditClients: false,
              canDeleteClients: false,
              canExportExcel: false,
              hasFullDashboard: false,
              hasAdvancedAnalytics: false,
              monthlyClientLimit: 10,
              remainingClients: Math.max(0, 10 - monthlyClientCount)
            });
          } else if (subscription.planId === 'standard') {
            setPlanFeatures({
              canAddClients: true,
              canEditClients: true,
              canDeleteClients: true,
              canExportExcel: true,
              hasFullDashboard: true,
              hasAdvancedAnalytics: true,
              monthlyClientLimit: Infinity,
              remainingClients: Infinity
            });
          }
        }
      } catch (error) {
        console.error('Error checking plan features:', error);
      }
    };

    checkPlanFeatures();

    // Set up subscription to user changes
    const channel = supabase
      .channel('plan-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'users' },
        () => {
          checkPlanFeatures();
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  return planFeatures;
}