import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HomeIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  ShoppingCartIcon,
  CreditCardIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  CubeIcon,
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import { useTheme } from "../../context/ThemeContext";
import Logo from "../../logo.svg";
import Logo2 from "../../logo2.svg";

export default function Sidebar({
  user,
  navigation,
  handleLogout,
  onCollapseChange,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) {
  const { darkMode, toggleDarkMode } = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem("sidebarCollapsed");
    return saved ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
    onCollapseChange?.(isCollapsed);
  }, [isCollapsed, onCollapseChange]);

  const location = useLocation();

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  return (
    <div
      className={`
      h-screen border-r flex flex-col
      ${isCollapsed ? "w-16" : "w-64"}
      transition-all duration-300
      ${darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"}
    `}
    >
      {/* Sidebar Header */}
      <div
        className={`
  flex items-center justify-between h-16 px-3 border-b flex-shrink-0
  ${darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"}
`}
      >
        {!isCollapsed && (
          <>
<img
  src={darkMode ? Logo : Logo2}
  alt="Mi aliada"
  className="h-24"
/>


            {/* Theme Toggle - Only show when not collapsed */}
            <button
              onClick={toggleDarkMode}
              className={`
          p-2 rounded-lg transition-colors duration-200
          ${
            darkMode
              ? "text-gray-400 hover:text-gray-200 hover:bg-gray-700"
              : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
          }
        `}
              aria-label="Cambiar tema"
            >
              {darkMode ? (
                <SunIcon className="h-5 w-5" />
              ) : (
                <MoonIcon className="h-5 w-5" />
              )}
            </button>
          </>
        )}

        <div className="flex items-center space-x-2">
          {/* Collapse Button */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={`
        hidden lg:block p-2 rounded-lg transition-colors duration-200
        ${
          darkMode
            ? "text-gray-400 hover:text-gray-200 hover:bg-gray-700"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
        }
      `}
            aria-label={isCollapsed ? "Expandir" : "Colapsar"}
          >
            {isCollapsed ? (
              <Bars3Icon className="h-5 w-5" />
            ) : (
              <XMarkIcon className="h-5 w-5" />
            )}
          </button>

          {/* Mobile Close Button */}
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className={`
        lg:hidden p-2 rounded-lg transition-colors duration-200
        ${
          darkMode
            ? "text-gray-400 hover:text-gray-200 hover:bg-gray-700"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
        }
      `}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Main Content Area */}
      <div
        className="flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide"
        style={{
          msOverflowStyle: "none" /* IE and Edge */,
          scrollbarWidth: "none" /* Firefox */,
        }}
      >
        <style>{`
    .scrollbar-hide::-webkit-scrollbar {
      display: none;  /* Chrome, Safari and Opera */
    }
  `}</style>

        {/* User Profile */}
        {!isCollapsed && (
          <div
            className={`p-4 border-b ${
              darkMode ? "border-gray-700" : "border-gray-200"
            }`}
          >
            <div className="flex items-center space-x-3">
              <img
                src={user?.imageUrl || "/avatar.svg"}
                alt={user?.name}
                className="h-8 w-8 rounded-full"
              />
              <div className="min-w-0">
                <p
                  className={`text-sm font-medium truncate ${
                    darkMode ? "text-gray-200" : "text-gray-700"
                  }`}
                >
                  {user?.name}
                </p>
                <p
                  className={`text-xs truncate ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  {user?.email}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Navigation */}
        <nav className="p-2">
          {!isCollapsed && (
            <div className="px-3 py-2">
              <p
                className={`text-xs font-semibold uppercase tracking-wider ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                Menu
              </p>
            </div>
          )}

          <ul className="space-y-1">
            {navigation.map((item) => {
              const Icon = getIconComponent(item.name);
              return (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={`
                      flex items-center px-3 py-2 rounded-lg
                      transition-colors duration-200
                      ${
                        darkMode
                          ? "text-gray-300 hover:text-white hover:bg-gray-700"
                          : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                      }
                      ${
                        isCurrentPath(item.href) &&
                        (darkMode
                          ? "bg-gray-700 text-white border-l-4 border-orange-400"
                          : "bg-orange-50 text-orange-600 border-l-4 border-orange-500")
                      }
                    `}
                    title={isCollapsed ? item.name : ""}
                  >
                    <Icon className="h-5 w-5 flex-shrink-0" />
                    {!isCollapsed && (
                      <span className="ml-3 text-sm">{item.name}</span>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>

          {/* Settings Section */}
          {!isCollapsed && (
            <div className="px-3 py-4 mt-4">
              <p
                className={`text-xs font-semibold uppercase tracking-wider ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                Configuración
              </p>
            </div>
          )}

          {/* Profile and Logout */}
          <ul className="space-y-1">
            <li>
              <Link
                to="/perfil"
                className={`
                  flex items-center px-3 py-2 rounded-lg
                  transition-colors duration-200
                  ${
                    darkMode
                      ? "text-gray-300 hover:text-white hover:bg-gray-700"
                      : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                  }
                `}
                title={isCollapsed ? "Perfil" : ""}
              >
                <UserCircleIcon className="h-5 w-5 flex-shrink-0" />
                {!isCollapsed && <span className="ml-3 text-sm">Perfil</span>}
              </Link>
            </li>

            <li>
              <button
                onClick={handleLogout}
                className={`
                  w-full flex items-center px-3 py-2 rounded-lg
                  transition-colors duration-200
                  ${
                    darkMode
                      ? "text-gray-300 hover:text-white hover:bg-gray-700"
                      : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                  }
                `}
                title={isCollapsed ? "Cerrar Sesión" : ""}
              >
                <ArrowLeftOnRectangleIcon className="h-5 w-5 flex-shrink-0" />
                {!isCollapsed && (
                  <span className="ml-3 text-sm">Cerrar Sesión</span>
                )}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

function getIconComponent(name) {
  switch (name) {
    case "Inicio":
      return HomeIcon;
    case "Clientes":
      return UserGroupIcon;
    case "Ordenar":
      return ClipboardDocumentListIcon;
    case "Pedidos":
      return ShoppingCartIcon;
    case "Suscripción":
      return CreditCardIcon;
    case "Productos":
      return CubeIcon;
    case "Gestionar Suscripciones":
      return Cog6ToothIcon;
    default:
      return HomeIcon;
  }
}
